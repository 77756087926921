import { BsFillInfoCircleFill, BsCheckLg } from "react-icons/bs";
import { FaTooth, FaExternalLinkAlt } from "react-icons/fa";
import { Button, UncontrolledTooltip, Card, Col, Row } from "reactstrap";
import AnimateContent from "../../../components/Animation/BlurAnimateContent";
import PositionAnimateContent from "../../../components/Animation/AnimateContent";
import { MAX, OCP, PRO } from "../../../constant/PlanName";

const PlanCard = ({
  AvailableOCP,
  AvailablePRO,
  AvailableMAX,
  toggle,
  toggleOffCanvas,
  handleFindPlan,
  handleSubmit,
}) => {
  return (
    <Row className="mt-5">
      <Col lg="11" className="m-auto">
        <Card className="bg-transparent pricing pricing-simple text-center">
          <Row className="pricing-card-row justify-content-center">
            {/* -------------------------------Card 1------------------------------ */}
            {AvailableOCP && (
              <Col lg="4" md="12" sm="12" className="single-item ocp">
                <div
                  className="pricing-item "
                  // style={
                  //   AvailablePRO || AvailableMAX
                  //     ? { borderRadius: "8px 0 0 8px" }
                  //     : {}
                  // }
                  style={{ borderRadius: "8px" }}
                >
                  <PositionAnimateContent scale={0} animateDelay={0.6} animateDuration={0.7}>
                    <div className="pricing-header">
                      <h3 className="text-white">
                        {handleFindPlan(OCP)?.plan_details?.description}
                        <small className="text-white">Dental Savings Plan</small>
                      </h3>
                      
                      <h4 className="text-white">
                        Aetna Dental Access<sup>®</sup>
                      </h4>
                      <h2 className="text-white">
                        {/* <sub>Starting at </sub> */}
                        {handleFindPlan(OCP)?.rate}
                        <sub>/ Mo</sub>
                      </h2>
                      <div className="text-white mt-2 fst-italic">$25 One-time Enrollment</div>
                      <Button
                        color="onboarding2"
                        size="lg"
                        className="btn my-4 w-100"
                        onClick={() => handleSubmit(OCP)}
                        title="Enroll now for OCP"
                      >
                        ENROLL NOW
                      </Button>
                      {/* <h5 className="text-white">$25 One-Time Enrollment</h5> */}
                    </div>
                    <ul>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Save 15-50% on Dental Care
                        <i className="text-white ml-icon">
                          <BsFillInfoCircleFill id="dentalCare" className="outline-0" />
                          <UncontrolledTooltip placement="right" target="dentalCare">
                            Per visit, in most instances, on services. Actual costs and savings vary
                            by provider, service, and geographical area.
                          </UncontrolledTooltip>
                        </i>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Aetna Dental Access® Network
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        SLEEK Electronic Toothbrush Kit
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Quarterly Oral Care Refills
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Discount Rx Benefits
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Byte<sup className="me-1">®</sup>Impression Kit Discount
                        <sup>1</sup>
                      </li>
                    </ul>
                  </PositionAnimateContent>
                  <div
                    // className={
                    //   AvailablePRO || AvailableMAX
                    //     ? "pricing-footer"
                    //     : "pricing-footer rounded-bottom"
                    // }
                    className="pricing-footer rounded-bottom"
                  >
                    <Button
                      className="text-uppercase text-black w-100 border-0 bg-transparent p-3"
                      // style={{borderRadius:"0 8px"}}
                      onClick={() => toggle(OCP)}
                      title="Provider information for OCP"
                    >
                      <b>Provider Information</b>
                      <i className="ml-icon">
                        <FaTooth />
                      </i>
                    </Button>
                    <Button
                      className="text-uppercase text-black w-100 border-0 pricing-plan-btn p-3"
                      title="Plan details for OCP"
                      onClick={() => toggleOffCanvas(OCP)}
                    >
                      <b>Plan details</b>
                      <i className="ml-icon">
                        <FaExternalLinkAlt className="ml-external-icon" />
                      </i>
                    </Button>
                  </div>
                </div>
              </Col>
            )}
            {/* ------------------------Card 2/ Popular Card----------------------- */}
            {AvailableMAX && (
              <Col lg="4" md="12" sm="12" className="single-item max">
                <div
                  className="pricing-item"
                  // style={
                  //   AvailableOCP && AvailableMAX && !AvailablePRO
                  //     ? { borderRadius: "0 8px 8px 0" }
                  //     : !AvailableOCP && AvailableMAX && !AvailablePRO
                  //     ? { borderRadius: "8px" }
                  //     : !AvailableOCP && AvailableMAX && AvailablePRO
                  //     ? { borderRadius: "8px 0 0 8px" }
                  //     : { borderRadius: 0 }
                  // }
                  style={{ borderRadius: "8px" }}
                >
                  <AnimateContent animateDuration={1} animateDelay={0.5}>
                    <kbd className="popular-tag">Most Popular</kbd>
                    <div className="pricing-header">
                      <h3 className="">
                        {handleFindPlan(MAX)?.plan_details?.description}
                        <small className="">Dental Insurance</small>
                      </h3>
                      <h4 className="">Underwritten by MetLife</h4>
                      <h2 className="">
                        {/* <sub>Starting at </sub>  */}
                        {handleFindPlan(MAX)?.rate}
                        <sub>/ Mo</sub>
                      </h2>
                      <div className="text-info-green mt-2 fst-italic">$25 One-time Enrollment</div>
                      <Button
                        color="onboarding2"
                        size="lg"
                        className="btn my-4 w-100"
                        onClick={() => handleSubmit(MAX)}
                        title="Enroll now for MAX"
                      >
                        ENROLL NOW
                      </Button>
                      {/* <h5>$25 One-Time Enrollment</h5> */}
                    </div>
                    <ul>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        100 / 80 / 50 Co-Insurance
                      </li>
                      <li className="d-none d-lg-flex">
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Increasing Annual Max per Year (Through Year 3)
                      </li>
                      <li className="d-flex d-lg-none">
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Increasing Annual Max per Year <span className="mx-1 mbl-year-list">(Through Year 3)</span>
                      </li>
                      {/* <li>
                      <i className="mr-icon">
                        <BsCheckLg />
                      </i>
                      Underwritten by MetLife
                    </li> */}
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        MetLife PDP Plus Network
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        SLEEK Electronic Toothbrush Kit
                        <sup>1</sup>
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Quarterly Oral Care Refills
                        <sup>1</sup>
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Teledentistry
                        <sup>1</sup>
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Discount Rx Benefits
                        <sup>1</sup>
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Association Benefits
                        <sup>1</sup>
                      </li>
                      <li>
                        <i className="mr-icon">
                          <BsCheckLg />
                        </i>
                        Byte<sup className="me-1">®</sup> Impression Kit Discount
                        <sup>1</sup>
                      </li>
                    </ul>
                  </AnimateContent>
                  <div
                    // className="pricing-footer"
                    // style={
                    //   AvailableOCP && AvailableMAX && !AvailablePRO
                    //     ? { borderRadius: "0 0 8px 0" }
                    //     : !AvailableOCP && AvailableMAX && !AvailablePRO
                    //     ? { borderRadius: "0 0 8px 8px" }
                    //     : !AvailableOCP && AvailableMAX && AvailablePRO
                    //     ? { borderRadius: "0 0 0 8px" }
                    //     : {}
                    // }
                    className="pricing-footer rounded-bottom"
                  >
                    <Button
                      className="text-uppercase text-black w-100 border-0 bg-transparent p-3"
                      onClick={() => toggle(MAX)}
                      title="Provider information for MAX"
                    >
                      <b>Provider Information</b>
                      <i className="ml-icon">
                        <FaTooth />
                      </i>
                    </Button>
                    <Button
                      className="text-uppercase text-black w-100 border-0 pricing-plan-btn p-3"
                      title="Plan details for MAX"
                      onClick={() => toggleOffCanvas(MAX)}
                    >
                      <b>Plan details</b>
                      <i className="ml-icon">
                        <FaExternalLinkAlt className="ml-external-icon" />
                      </i>
                    </Button>
                  </div>
                </div>
              </Col>
            )}
            {/* --------------------------------Card 3----------------------------- */}
            {AvailablePRO && (
              <Col lg="4" md="12" sm="12" className="single-item pro">
                <div
                  className="pricing-item"
                  // style={
                  //   AvailableOCP || AvailableMAX
                  //     ? { borderRadius: "0 8px 8px 0px" }
                  //     : {}
                  // }
                  style={{ borderRadius: "8px" }}
                >
                  <PositionAnimateContent scale={0} animateDelay={0.6} animateDuration={0.7}>
                    <div className="pricing-header">
                      <h3 className="text-white">
                        {handleFindPlan(PRO)?.plan_details?.description}
                        <small className="text-white">Dental Insurance</small>
                      </h3>
                      <h4 className="text-white">Underwritten by MetLife</h4>
                      <h2 className="text-white">
                        {/* <sub>Starting at </sub>  */}
                        {handleFindPlan(PRO)?.rate}
                        <sub>/ Mo</sub>
                      </h2>
                      <div className="text-white mt-2 fst-italic">$25 One-time Enrollment</div>
                      <Button
                        color="onboarding2"
                        size="lg"
                        className="btn my-4 w-100"
                        onClick={() => handleSubmit(PRO)}
                        title="Enroll now for PRO"
                      >
                        ENROLL NOW
                      </Button>
                      {/* <h5 className="text-white">$25 One-Time Enrollment</h5> */}
                    </div>
                    <ul>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        80 / 60 / 50 Co-Insurance
                      </li>
                      <li className="d-none d-lg-flex text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Increasing Annual Max per Year (Through Year 3)
                      </li>
                      <li className="d-flex d-lg-none text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Increasing Annual Max per Year <span className="mx-1 mbl-year-list">(Through Year 3)</span>
                      </li>

                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        MetLife PDP Plus Network
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        SLEEK Electronic Toothbrush Kit
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Quarterly Oral Care Refills
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Discount Rx Benefits
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Association Benefits
                        <sup>1</sup>
                      </li>
                      <li className="text-white">
                        <i className="mr-icon text-white">
                          <BsCheckLg />
                        </i>
                        Byte<sup className="me-1">®</sup> Impression Kit Discount
                        <sup>1</sup>
                      </li>
                    </ul>
                  </PositionAnimateContent>
                  <div
                    // className="pricing-footer"
                    // style={
                    //   AvailableOCP || AvailableMAX
                    //     ? { borderRadius: "0 0 8px 0" }
                    //     : { borderRadius: "0 0 8px 8px" }
                    // }
                    className="pricing-footer rounded-bottom"
                  >
                    <Button
                      className="text-uppercase text-black w-100 border-0 bg-transparent p-3"
                      onClick={() => toggle(PRO)}
                      title="Provider information for PRO"
                    >
                      <b>Provider Information</b>
                      <i className="ml-icon">
                        <FaTooth />
                      </i>
                    </Button>
                    <Button
                      className="text-uppercase text-black w-100 border-0 pricing-plan-btn p-3"
                      title="Plan details for PRO"
                      onClick={() => toggleOffCanvas(PRO)}
                    >
                      <b>Plan details</b>
                      <i className="ml-icon">
                        <FaExternalLinkAlt className="ml-external-icon" />
                      </i>
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default PlanCard;
