// ==========================Api Configuration===========================
// Third party Api Base url
export const API_BASE_URL_REMOTE = "https://api.1administration.com";

// local api base url
export const BASE_URL = "https://api-dev.sleekdentalclub.com"; //dev
// export const BASE_URL = "https://sdc-api.sleekdentalclub.com";//prod
// =======================================================================

const API_BASE_URL_local = `${BASE_URL}/api`;
export default API_BASE_URL_local;

// Signature web link
export const signatureWebLink = (id) => `https://aobgenrollment.com/sig/index.cfm?a=${id}`;
