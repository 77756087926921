export const getCheckoutData = (name) => {
  try {
    let auth = JSON.parse(localStorage.getItem("checkoutData"));
    return auth;
  } catch (err) {
    return null;
  }
};

export const setCheckoutData = (data) => {
  try {
    localStorage.setItem("checkoutData", JSON.stringify(data));
  } catch (err) {
    return null;
  }
};
