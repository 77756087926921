import API_BASE_URL_LOCAL, { API_BASE_URL_REMOTE } from "./connection";

const apis = {
  apiDetails: `${API_BASE_URL_LOCAL}/e123api-details`, //post
  checkout: {
    // step 2 page
    levelList: `${API_BASE_URL_LOCAL}/level-list`, //get

    // step 2 page
    zipCodeList: `${API_BASE_URL_LOCAL}/zip-code`, //get
    searchByZipCode: `${API_BASE_URL_LOCAL}/search_by_zip`, //post
    zipAgainstAreaFind: `${API_BASE_URL_LOCAL}/zip-against-area`, //post,  first 3 digit zip against area find

    // step 3/plan page
    // LicenseList: `${API_BASE_URL_REMOTE}/v2/agents/652053/licenses`, //get
    LicenseList: `${API_BASE_URL_LOCAL}/list-sales-license`, //post
    getAvailableProducts: `${API_BASE_URL_LOCAL}/get-available-products`, //post
    planRate: `${API_BASE_URL_LOCAL}/fetch-rate`, //post,
    // planDetails: `${API_BASE_URL_REMOTE}/v2/products/652053`, //get,

    // product details page
    clubProductDetails: `${API_BASE_URL_LOCAL}/club-kit-details`, //post

    // step 4/checkout page
    checkoutDetails: `${API_BASE_URL_LOCAL}/checkout-details`, //post
    stateList: `${API_BASE_URL_LOCAL}/states`, //post
    memberCreate: `${API_BASE_URL_LOCAL}/member-api`, // post
    signatureStatus: `${API_BASE_URL_LOCAL}/signatureexists`, //post local api
    orderDetailsStore: `${API_BASE_URL_LOCAL}/member-data-store`, //post local api
    policyDetails: (productID) => `${API_BASE_URL_LOCAL}/policy-details/${productID}`, //get local api
  },
  agent: {
    list: (agent_id) => `${API_BASE_URL_REMOTE}/v2/agents/${agent_id}/downline`, //get
  },
};

export default apis;

// ------Third party api username & password-----
//const API_USERNAME = "ArcT2023";
//const API_PASSWORD = "=NDnL2!-d98`t%{egvy@$Q[z";
// --------------other static id-----------------
//const CORP_ID = 1341;
//const AGENT_ID = 652053;
//const MEMBER_ID = 680999166;
