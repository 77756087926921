import { BsFillInfoCircleFill } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";

export const TableLabel = ({ name, id, tooltipData }) => {
  return (
    <tr>
      <th scope="row" style={{ backgroundColor: "#f7f7f7bf" }}>
        {name}
        {tooltipData ? (
          <>
            <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id={id} />
            <UncontrolledTooltip placement="right" target={id}>
              {tooltipData}
            </UncontrolledTooltip>
          </>
        ) : null}
      </th>
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
      {/* <td style={{ backgroundColor: "#f7f7f7bf" }} />
      <td style={{ backgroundColor: "#f7f7f7bf" }} /> */}
    </tr>
  );
};
