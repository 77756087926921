import { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Progress, Row } from "reactstrap";
import BlurAnimateContent from "../../../components/Animation/BlurAnimateContent";
import WrapContent from "../../../components/WrapContent";
import { getCheckoutData, setCheckoutData } from "../../../utils/checkoutHelpers";
import axiosInstance from "../../../apis/axiosInstance";
import apis from "../../../apis";
import FormUI from "./FormUI";

const Step2 = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const [progressValue, setProgressValue] = useState(35);
  const [inputFeedback, setInputFeedback] = useState();
  // const [fieldData, setFieldData] = useState(getCheckoutData()?.zipCode);
  const [fieldData, setFieldData] = useState("");
  const [checkZip, setCheckZip] = useState();
  const [checkArea, setCheckArea] = useState();
  const [checkMessage, setCheckMessage] = useState(false);

  useEffect(() => {
    if (!getCheckoutData()?.levelValue) {
      navigate(agentId ? `/${agentId}/onboarding` : "/onboarding");
    }
    setTimeout(() => {
      setProgressValue(70);
    }, 200);
  }, [navigate, agentId]);

  // ------------------------Handle Input-----------------------
  const handleZipInput = (event) => {
    setCheckZip();
    setCheckArea();
    setCheckMessage();
    if (event.target.value.length <= 5) {
      setFieldData(event.target.value);
      setInputFeedback(event.target.value.length === 5 && "valid");
    }
  };

  // ------------------------Handle Check zip Code-----------------------
  const handleCheckZipCose = () => {
    axiosInstance.post(apis.checkout.searchByZipCode, { zip_code: fieldData }).then((resp) => {
      if (resp?.zip === fieldData) {
        setCheckZip("done");
        setCheckoutData({ ...getCheckoutData(), state: resp?.state });
        handleCheckArea();
      } else {
        setCheckZip("failed");
        // setTimeout(() => {
        setCheckArea("failed");
        // }, 1000);
        setCheckMessage("Zip code is Invalid! Please try another zip code.");
      }
    });
  };

  // ------------------------Handle Check Area-----------------------
  const handleCheckArea = () => {
    const firstThreeDigit = fieldData.slice(0, 3);
    axiosInstance
      .post(apis.checkout.zipAgainstAreaFind, { zip_prefix: firstThreeDigit })
      .then((resp) => {
        if (resp?.zip_prefix === firstThreeDigit) {
          setCheckoutData({ ...getCheckoutData(), zipCode: fieldData, zipAgainstArea: resp });
          setCheckArea("done");
          // setTimeout(() => {
          //   navigate("/pricing");
          // }, 1000);
          window.location.href = agentId ? `/${agentId}/pricing` : "/pricing";
        } else {
          setCheckZip("failed");
          setCheckMessage("No plan found in this zip code! Please try another zip code.");
        }
      });
  };

  // ------------------------Handle Submit-----------------------
  const handleSubmit = () => {
    if (fieldData.length === 5) {
      if (fieldData === getCheckoutData()?.zipCode) {
        // window.location.reload()
        // navigate("/pricing");
        window.location.href = agentId ? `/${agentId}/pricing` : "/pricing";
      } else {
        setCheckArea("loading");
        handleCheckZipCose();
      }
    } else {
      setInputFeedback("invalid");
    }
  };

  return (
    <WrapContent>
      <BlurAnimateContent>
        <Row>
          <Col lg="2" md="2" sm="2" xs="2">
            <Button
              color="link"
              onClick={() => navigate(agentId ? `/${agentId}/onboarding` : "/onboarding")}
              className="mt-3 shadow-none back-lg-link-btn"
              title="Back"
            >
              <BsChevronLeft size="40" />
            </Button>
          </Col>

          <Col lg="8" md="8" sm="8" xs="8">
            <div className="onboarding-progress-content">
              <Progress animated value={progressValue} barAriaLabelledBy="Progress for step2" />
            </div>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2"></Col>
        </Row>
        <FormUI
          inputFeedback={inputFeedback}
          fieldData={fieldData}
          checkZip={checkZip}
          checkArea={checkArea}
          checkMessage={checkMessage}
          handleZipInput={handleZipInput}
          handleSubmit={handleSubmit}
        />
      </BlurAnimateContent>
    </WrapContent>
  );
};

export default Step2;
