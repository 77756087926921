import { Suspense, useLayoutEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LoaderSpin from "./components/Loader/LoaderSpin";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";
import AppRoute from "./routes/route";
// Error Page
import Error404 from "./pages/Error404";

// layouts
import AuthLayout from "./layout/AuthLayout";
import PublicLayout from "./layout/PublicLayout";

// Import css
import "./assets/css/App.css";
import ScrollToTop from "./utils/ScrollToTop";
import axiosInstance from "./apis/axiosInstance";
import apis from "./apis";
import { setApiDetails } from "./utils/localStorageHelpers";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const getApiDetails = () => {
    setIsLoading(true);
    axiosInstance
      .post(apis.apiDetails, {  })
      .then((resp) => {
        if (resp?.status) {
          setApiDetails(resp?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };
  useLayoutEffect(getApiDetails, []);

  return (
    <div className="App">
      {isLoading ? (
        <LoaderSpin />
      ) : (
        <BrowserRouter>
          <ScrollToTop>
            <AnimatePresence>
              <Suspense fallback={<LoaderSpin />}>
                <Routes>
                  <Route element={<AppRoute layout={PublicLayout} isAuthProtected={false} />}>
                    {publicRoutes.map(({ path, element: Element }, idx) => (
                      <Route path={path} element={<Element />} key={idx} />
                    ))}
                  </Route>
                  <Route element={<AppRoute layout={AuthLayout} isAuthProtected={true} />}>
                    {authProtectedRoutes.map(({ path, element: Element }, idx) => (
                      <Route path={path} element={<Element />} key={idx} />
                    ))}
                  </Route>
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </ScrollToTop>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
