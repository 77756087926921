import {
  FaBolt,
  FaParachuteBox,
  FaMobileAlt,
  FaHandHoldingHeart,
  FaUserPlus,
} from "react-icons/fa";
import { RiListOrdered } from "react-icons/ri";

export const productBenefitsPoint = [
  "Electric Toothbrush with 5 Cleaning Modes",
  "Built-In Rechargeable USB Charger",
  "Oscillating Brush Head with 2-Minute Timer",
  "Toothbrush Holder & Traveling Case",
];

export const benefitsPoints = [
  {
    icon: <FaBolt />,
    title: "Electric Toothbrush Kit",
    subTitle: "Affordable & Convenient",
  },
  {
    icon: <RiListOrdered />,
    title: "Membership Options",
    subTitle: "Three Club Levels to Choose From",
  },
  {
    icon: <FaParachuteBox />,
    title: "Quarterly Refills",
    subTitle: "Auto-Shipped Brush Head & Floss Picks",
  },
  {
    icon: <FaMobileAlt />,
    title: "Digital Access",
    subTitle: "View Your Benefits 24/7",
  },
  {
    icon: <FaHandHoldingHeart />,
    title: "Additional Benefits",
    subTitle: "Included in OCP, PRO & MAX Levels",
  },
  {
    icon: <FaUserPlus />,
    title: "Coverage Options",
    subTitle: "Available to Individuals & Families",
  },
];

export const membershipData = {
  ocp: {
    title: "OCP",
    subtitle: "Aetna Dental Access®",
    price: "29.95",
    benefitPoints: [
      <span>
        Save 15-50% on Dental Care<sup>*</sup>
      </span>,
      <span>
        Aetna Dental Access<sup>®</sup> Network<sup>1</sup>
      </span>,
      <span>
        SLEEK Electronic Toothbrush Kit<sup>1</sup>
      </span>,
      <span>
        Quarterly Oral Care Refills<sup>1</sup>
      </span>,
      <span>
        Discount Rx Benefits<sup>1</sup>
      </span>,
      <span>
        Teledentistry<sup>1</sup>
      </span>,
      <span>
        Byte Impression Kit Discount<sup>1</sup>
      </span>,
    ],
  },
  pro: {
    title: "PRO",
    subtitle: "Underwritten by MetLife",
    price: "56.95",
    benefitPoints: [
      <span>80 / 60 / 50 Co-Insurance</span>,
      <span>Increasing Annual Max per Year (Through Year 3)</span>,
      <span>MetLife PDP Plus Network</span>,
      <span>
        SLEEK Electronic Toothbrush Kit<sup>1</sup>
      </span>,
      <span>
        Quarterly Oral Care Refills<sup>1</sup>
      </span>,
      <span>
        Teledentistry<sup>1</sup>
      </span>,
      <span>
        Discount Rx Benefits<sup>1</sup>
      </span>,
      <span>
        Association Benefits<sup>1</sup>
      </span>,
      <span>
        Byte Impression Kit Discount<sup>1</sup>
      </span>,
    ],
  },
  max: {
    title: "MAX",
    subtitle: "Underwritten by MetLife",
    price: "64.95",
    benefitPoints: [
      <span>100 / 80 / 50 Co-Insurance</span>,
      <span>Increasing Annual Max per Year (Through Year 3)</span>,
      <span>No Waiting Period on Major Services</span>,
      <span>Orthodontia Benefits (MAX Only)</span>,
      <span>MetLife PDP Plus Network</span>,
      <span>
        SLEEK Electronic Toothbrush Kit<sup>1</sup>
      </span>,
      <span>
        Quarterly Oral Care Refills<sup>1</sup>
      </span>,
      <span>
        Teledentistry<sup>1</sup>
      </span>,
      <span>
        Discount Rx Benefits<sup>1</sup>
      </span>,
      <span>
        Association Benefits<sup>1</sup>
      </span>,
      <span>
        Byte Impression Kit Discount<sup>1</sup>
      </span>,
    ],
  },
};

export const membershipValueAddedBenefitsData = [
  {
    image: "/home-img/Teledentistry-600x450.jpg",
    title: (
      <span>
        TheTeleDentists<sup>®</sup>
      </span>
    ),
    subTitle1: "Save time and money!",
    subTitle2: "Virtual dentists wherever and whenever a dentist is needed 24/7/365.",
    BenefitPoint: [
      "US-Licensed dental professionals",
      "Personalized treatment plans",
      "E-prescriptions of non-narcotic medication",
    ],
    termsLink:
      "https://enrollment123.com/media/1341/Fulfillment/SLEEKDental/SleekOCP-Pro-Max-T%26C-Teledentist.pdf",
  },
  {
    image: "/home-img/Byte-600x450.jpg",
    title: (
      <span>
        byte<sup>®</sup>
      </span>
    ),
    subTitle1: "Discounted Impression Kit",
    subTitle2: `Save $85 off impression kit and $100 off aligners. Professional care. 2x faster results. Guaranteed for life.`,
    BenefitPoint: [
      "No in-office visits.",
      "Half the treatment time of competitors.",
      "Smile Science Advanced Aesthetic treatment.",
      "Byte for Life guarantee™",
    ],
    termsLink: false,
  },
  {
    image: "/home-img/OptumRx.jpg",
    title: (
      <span>
        Optum Rx<sup>®</sup>
      </span>
    ),
    subTitle1: "Prescription Benefit",
    subTitle2: `Savings on generic and name-brand medications at over 65,000 select pharmacies nationwide.`,
    BenefitPoint: [
      "Save 10% to 85% on Generic and Name Brand Prescriptions",
      "Mail Order Service Available",
      "Members Always Receive the Lowest Price at the Pharmacy that day",
    ],
    termsLink:
      "https://enrollment123.com/media/1341/Fulfillment/SLEEKDental/SleekOCP-Pro-Max-T%26C-Pharmacy.pdf",
  },
];

export const membershipBenefitsAccordionData1 = [
  {
    id: "1",
    title: "Chiropractic Savings",
    subTitle: `Save 20% to 40% on chiropractic fees at participating chiropractors across the country.`,
  },
  {
    id: "2",
    title: "Health Services Hub",
    subTitle: "Daily wellness articles, health tips, personalized workout programs.",
  },
  {
    id: "3",
    title: "Hearing Discount",
    subTitle: `TruHearing® offers savings of 30-60% off retail prices on a wide variety of hearing aids in different styles and colors.`,
  },
  {
    id: "4",
    title: "Vision Savings",
    subTitle: `Save 10% to 60% off eyeglasses, contact lenses and other retail eyewear items at participating providers.`,
  },
  {
    id: "5",
    title: "24-Hour Nurseline",
    subTitle: `Members have unlimited access to Registered Nurses via a toll-free telephone number.`,
  },
  {
    id: "6",
    title: "Diabetic & Home Medical Supplies",
    subTitle: `Save 10% on diabetic, health & wellness, and pet health.`,
  },
  {
    id: "7",
    title: "Vitamins & Nutritional Supplements",
    subTitle: `Our members can save an additional 20% on a wide range of vitamins.`,
  },
  {
    id: "8",
    title: "Online Wellness",
    subTitle: `Nutrition, fitness, stress management, and supplementation information.`,
  },
];

export const membershipBenefitsAccordionData2 = [
  {
    id: "9",
    title: "Avis and Budget Car Rentals",
    subTitle: `Up to 25% off your rental when you use the Avis or Budget discount code.`,
  },
  {
    id: "10",
    title: "Choice Hotels",
    subTitle: `Members save up to 20% off the best available rate at participating Choice Hotels.`,
  },
  {
    id: "11",
    title: "Lenovo Discount",
    subTitle: `Save up to 30% off the entire product line of laptops, tablets, desktops, servers, and more!`,
  },
  {
    id: '12',
    title: "My Association Saving Benefits",
    subTitle: `Exclusive perks and over $4,500 in savings on everything!`,
  },
  {
    id: "13",
    title: "Office Supplies",
    subTitle: `Save up to 80% on over 93,000 products at any Office Depot or OfficeMax store.`,
  },
  {
    id: "14",
    title: "Auto Glass Repair",
    subTitle: `Save $10 on rock chip repair, $20 on glass replacement through Safelite® AutoGlass.`,
  },
  {
    id: "15",
    title: "Travel Savings",
    subTitle: `Online booking website comparable to Expedia & Priceline, offering Member Only prices.`,
  },
  {
    id: "16",
    title: "Delivery Services",
    subTitle: `Discounts on UPS delivery services for a variety of shipping options.`,
  },
];

export const blogPostData = [
  {
    imageUrl: "/home-img/Cavity-Blog-600x401.jpg",
    category: "Health",
    title: "Understanding Cavities: What Does a Cavity Look Like?",
    redirectUrl: "https://sleekdentalclub.com/understanding-cavities-what-does-a-cavity-look-like",
  },
  {
    imageUrl: "/home-img/Techniques-for-a-Brighter-Smile-600x401.jpg",
    category: "SLEEK Dental OCP",
    title: "Unlocking Your Smile’s Potential with SLEEK OCP",
    redirectUrl: "https://sleekdentalclub.com/unlocking-your-smiles-potential-with-sleek-ocp",
  },
  {
    imageUrl: "/home-img/Understanding-Gingivitis-600x401.jpg",
    category: "Health",
    title: "Understanding Gingivitis",
    redirectUrl: "https://sleekdentalclub.com/understanding-gingivitis",
  },
];
