import { motion } from "framer-motion";
const AnimateContent = ({
  x,
  y,
  scale,
  rotate,
  opacity = 1,
  animateDelay = 0.1,
  animateDuration = 0.5,
  type,
  children,
  ...props
}) => {
  return (
    <motion.div
      initial={{ x, y, scale, rotate, opacity }}
      animate={{
        x: 0,
        y: 0,
        scale: 1,
        rotate: 0,
        opacity: 1,
        transition: { delay: animateDelay, duration: animateDuration, type, stiffness: 50 },
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
};
export default AnimateContent;
