export const getApiDetails = () => {
  try {
    let auth = JSON.parse(localStorage.getItem("apiDetails"));
    return auth;
  } catch (err) {
    return null;
  }
};

export const setApiDetails = (data) => {
  try {
    localStorage.setItem("apiDetails", JSON.stringify(data));
  } catch (err) {
    return null;
  }
};
