import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Input, Row } from "reactstrap";
import { getCheckoutData } from "../../../utils/checkoutHelpers";
import { getFormatDate } from "../../../utils/DateHelpers";
import { BiMinus, BiPlus } from "react-icons/bi";
// import { FaTooth } from "react-icons/fa";

const OrderSummaryCard = ({ productQty, setProductQty, orderSummaryData, memberPerchesInfo }) => {
  const handleFindProductType = (type) =>
    orderSummaryData?.membership_details?.find((item) => item?.type === type);
  const isClub = () => getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.is_club;
  return (
    <Card className="border-0 onboarding-checkout-order-summary-card">
      <CardBody className="border-0">
        <Row>
          {/* <Col sm="12">
            <div className="h2 text-info-green order-summary-product-name-content">
              <FaTooth className="order-summary-tooth-icon" />
              <b>{getCheckoutData()?.selectProductRate?.plan_details?.description}</b>
            </div>
          </Col> */}

          <Col sm="12">
            <div className="h2 text-info-green my-2 mx-0">
              <b>Order Summary</b>
            </div>
          </Col>
          {memberPerchesInfo?.MEMBER?.ID && (
            <Col sm="12">
              <div className="h5 my-2 mx-0 text-black">
                <b>Member ID: {memberPerchesInfo?.MEMBER?.ID}</b>
              </div>
            </Col>
          )}
          {memberPerchesInfo?.PRODUCT?.[0]?.EFFECTIVE && (
            <Col sm="12">
              <div className="h5 my-2 mx-0 text-black">
                <b>
                  Effective Date:{" "}
                  {getFormatDate({
                    date: memberPerchesInfo?.PRODUCT?.[0]?.EFFECTIVE,
                    format: "LL",
                  })}
                </b>
              </div>
            </Col>
          )}

          <Col sm="12">
            <hr className="border-black" />
            <div className="d-flex justify-content-between align-items-center mx-0">
              <div className="h5 my-2">SLEEK Dental Kits:</div>
              {isClub() ? (
                <ButtonGroup className="order-summary-btn-grp">
                  <Button
                    className="order-summary-btn remove-btn border-0"
                    title="Remove"
                    onClick={() => setProductQty((pre) => (pre > 1 ? pre - 1 : pre))}
                  >
                    <BiMinus />
                  </Button>
                  <Input
                    type="number"
                    value={productQty}
                    // onChange={(e) => {
                    //   e.target.value >= 1 && e.target.value <= 10 && setProductQty(e.target.value);
                    // }}
                    min={1}
                    className="rounded-0 text-center order-summary-count-input"
                  />
                  <Button
                    className="order-summary-btn add-btn border-0"
                    title="Add"
                    onClick={() => setProductQty((pre) => (pre < 10 ? +pre + 1 : pre))}
                  >
                    <BiPlus />
                  </Button>
                </ButtonGroup>
              ) : null}
              <div className="h4 my-2">
                {isClub() ? (
                  <b>{orderSummaryData?.kit_total_price}</b>
                ) : (
                  <b>
                    $
                    {(
                      +handleFindProductType("enrollment")?.price.substr(1) +
                      +handleFindProductType("membership")?.price.substr(1)
                    ).toFixed(2)}
                  </b>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mx-0">
              <div className="h5 my-2">Ground Shipping:</div>
              <div className="h4 my-2">
                <b>FREE</b>
              </div>
            </div>
          </Col>

          <Col sm="12">
            <hr className="border-black" />
            <div className="d-flex justify-content-between align-items-center mx-0">
              <div className="h3 my-2 text-info-green">
                {isClub() ? <b>Billed Quarterly:</b> : <b>Billed Monthly:</b>}
              </div>
              <div className="h4 my-2">
                <b>
                  {isClub()
                    ? orderSummaryData?.billed_quarter
                    : getCheckoutData()?.selectProductRate?.rate}
                </b>
              </div>
            </div>
          </Col>
          <Col sm="12">
            <div className="d-flex justify-content-between align-items-center mx-0">
              <div className="h3 my-2 text-info-green">
                <b>Today’s Total:</b>
              </div>
              <div className="h4 my-2">
                {isClub() ? (
                  <b>
                    $
                    {
                      // +orderSummaryData?.billed_quarter?.substr(1) +
                      (+orderSummaryData?.kit_total_price?.substr(1)).toFixed(2)
                    }
                  </b>
                ) : (
                  <b>
                    $
                    {(
                      +handleFindProductType("enrollment")?.price?.substr(1) +
                      +handleFindProductType("membership")?.price?.substr(1) +
                      +getCheckoutData()?.selectProductRate?.rate?.substr(1)
                    ).toFixed(2)}
                  </b>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="border-0 bg-info-green text-white py-4">
        <Row>
          <Col sm="12">
            <h3 className="assistance-text">
              <b>Need Assistance? We Can Help!</b>
            </h3>
            <a
              className="text-white text-decoration-none"
              href="tel:tel:18447533532"
              title="Cal us at 1-844-753-3532"
            >
              1-844-753-3532
            </a>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};
export default OrderSummaryCard;
