import { BsCheckLg, BsFillInfoCircleFill, BsXLg } from "react-icons/bs";
import { Button, Col, Row, Table, UncontrolledTooltip } from "reactstrap";
import BlurAnimateContent from "../../../components/Animation/BlurAnimateContent";
import { MAX, OCP, PRO } from "../../../constant/PlanName";
import { MEMBER_LABEL } from "../../../constant";
import { getCheckoutData } from "../../../utils/checkoutHelpers";

const TableLabel = ({ name, id, tooltipData }) => {
  return (
    <tr>
      <th scope="row" style={{ backgroundColor: "#f7f7f7bf" }}>
        {name}
        {tooltipData ? (
          <>
            <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id={id} />
            <UncontrolledTooltip placement="right" target={id}>
              {tooltipData}
            </UncontrolledTooltip>
          </>
        ) : null}
      </th>
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
    </tr>
  );
};

const CompareTable = ({ AvailableOCP, AvailablePRO, AvailableMAX, toggle, handleSubmit, handleFindPlan }) => {
  const  levelValue  = getCheckoutData()?.levelValue
  const { MEMBER_SPOUSE, MEMBER_CHILDREN, MEMBER_ONLY } = MEMBER_LABEL;

  return (
    <Row className="onboarding-compare-table-area2 mx-0 onboarding-compare-d-none">
      <BlurAnimateContent animateDelay={0.8}>
        <Col sm="12">
          <Row>
            <Col sm="11" className="m-auto p-0">
              <div className="table-responsive">
                <Table>
                  {/* ----------------------Table Header---------------------- */}
                  <thead className="onboarding-compare-table-thead">
                    <tr>
                      <th><span className="opacity-0">d</span></th>
                      {AvailableOCP && <th className="h2 text-center py-3">OCP</th>}
                      {AvailableMAX && <th className="h2 text-center py-3">MAX</th>}
                      {AvailablePRO && <th className="h2 text-center py-3">PRO</th>}
                    </tr>
                  </thead>
                  {/* -----------------------Table Body---------------------- */}
                  <tbody className="align-middle onboarding-compare-table-tbody">
                    <tr>
                      <th scope="row" className="h2 py-3 left-title">
                        Coverage Type
                      </th>
                      {AvailableOCP && <td className="text-center py-3">N/A</td>}
                      {AvailableMAX && <td className="text-center py-3">In-Network*/Out of Network**</td>}
                      {AvailablePRO && <td className="text-center py-3">In-Network*/Out of Network**</td>}
                    </tr>

                    <TableLabel name="Insurance Reimbursement" />

                    <tr>
                      <td className="py-3">
                        Type A: Preventative
                        <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id="preventative" />
                        <UncontrolledTooltip placement="right" target="preventative">
                          <div className="my-1">(Cleanings, Exams, X-rays)</div>
                          <div className="my-1">No Waiting Period</div>
                        </UncontrolledTooltip>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">100%</td>}
                      {AvailablePRO && <td className="text-center py-3">80%</td>}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Type B: Basic Restorative
                        <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id="basicRestorative" />
                        <UncontrolledTooltip placement="right" target="basicRestorative">
                          <div className="my-1">(Fillings, Extractions) </div>
                          <div className="my-1">No Waiting Period</div>
                        </UncontrolledTooltip>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">80%</td>}
                      {AvailablePRO && <td className="text-center py-3">60%</td>}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Type C: Major Restorative
                        <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id="majorRestorativeLabel" />
                        <UncontrolledTooltip placement="right" target="majorRestorativeLabel">
                          <div>No Waiting Period on MAX</div>
                          <div className="mt-2">
                            PRO Benefit Waiting Period: Benefits are payable after a 12-month waiting period from the
                            start date of an individual’s benefits.
                          </div>
                        </UncontrolledTooltip>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">50%</td>}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          50%
                          <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id="majorRestorative" />
                          <UncontrolledTooltip placement="right" target="majorRestorative">
                            *Benefits are payable after 12 month waiting period from the start date of an individual's
                            benefits
                          </UncontrolledTooltip>
                        </td>
                      )}
                    </tr>

                    <TableLabel
                      name="Type D: Orthodontia"
                      id="valueAddedBenefits"
                      tooltipData={
                        <div>
                          <div className="my-2">(Orthodontic Diagnostics and Orthodontic Treatment)</div>
                          <div className="my-2">No Waiting Period</div>
                          <div className="my-2">Children Only Coverage to Age 19</div>
                        </div>
                      }
                    />

                    <tr>
                      <td className="py-3">
                        Reimbursement
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">50%</td>}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Lifetime Maximum
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">$1,500</td>}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                    </tr>

                    <TableLabel
                      name="Deductible (Calendar Year)"
                      id="insuranceDeductible"
                      tooltipData="Applies to Basic & Major Services"
                    />

                    <tr>
                      <td className="py-3">
                        {levelValue === MEMBER_ONLY
                          ? "Member"
                          : levelValue === MEMBER_SPOUSE
                          ? "Member + Spouse"
                          : levelValue === MEMBER_CHILDREN
                          ? "Member + Children"
                          : "Family"}
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          {levelValue === MEMBER_ONLY
                            ? "$50"
                            : levelValue === MEMBER_SPOUSE
                            ? "$100"
                            : levelValue === MEMBER_CHILDREN
                            ? "$100"
                            : "$150"}
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          {levelValue === MEMBER_ONLY
                            ? "$100"
                            : levelValue === MEMBER_SPOUSE
                            ? "$200"
                            : levelValue === MEMBER_CHILDREN
                            ? "$200"
                            : "$300"}
                        </td>
                      )}
                    </tr>

                    <TableLabel
                      name="Annual Maximum Benefit (Through Year 3)"
                      id="insuranceMaximumPayout"
                      tooltipData="Increasing Annual Max Applies to Preventative, Basic & Major Services."
                    />

                    <tr>
                      <td className="py-3">
                        Year 1
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">$1,500</td>}
                      {AvailablePRO && <td className="text-center py-3">$1,000</td>}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Year 2
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">$1,750</td>}
                      {AvailablePRO && <td className="text-center py-3">$1,250</td>}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Year 3+
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && <td className="text-center py-3">$2,000</td>}
                      {AvailablePRO && <td className="text-center py-3">$1,500</td>}
                    </tr>

                    <TableLabel name="Provider Networks" id="providerNetworks" />

                    <tr>
                      <td className="py-3">
                        Dental Network
                      </td>
                      {AvailableOCP && <td className="text-center py-3">Aetna Dental Access®</td>}
                      {AvailableMAX && <td className="text-center py-3">MetLife PDP Plus</td>}
                      {AvailablePRO && <td className="text-center py-3">MetLife PDP Plus</td>}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Pharmacy Network
                      </td>
                      {AvailableOCP && <td className="text-center py-3">OptumRx®</td>}
                      {AvailableMAX && <td className="text-center py-3">OptumRx®</td>}
                      {AvailablePRO && <td className="text-center py-3">OptumRx®</td>}
                    </tr>

                    <tr>
                      <th><span className="opacity-0">h</span></th>
                      {AvailableOCP && (
                        <td className="text-center py-3 plan-details-btn-container">
                          <Button
                            className="bg-black py-2 px-4 plan-details-btn"
                            onClick={() => toggle("OCP")}
                            title="View plan details for OCP"
                          >
                            VIEW PLAN DETAILS
                          </Button>
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3 plan-details-btn-container">
                          <Button
                            className="bg-black py-2 px-4 plan-details-btn"
                            onClick={() => toggle("MAX")}
                            title="View plan details for MAX"
                          >
                            VIEW PLAN DETAILS
                          </Button>
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3 plan-details-btn-container">
                          <Button
                            className="bg-black py-2 px-4 plan-details-btn"
                            onClick={() => toggle("PRO")}
                            title="View plan details for PRO"
                          >
                            VIEW PLAN DETAILS
                          </Button>
                        </td>
                      )}
                    </tr>

                    <TableLabel name="Additional Membership Benefits" id="additionalMembershipBenefits" />

                    <tr>
                      <td className="py-3">
                        SLEEK Electric Toothbrush Kit<sup>1</sup>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Quarterly Oral Care Refills<sup>1</sup>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Discount Rx Benefits<sup>1</sup>
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Association Vision Care Discounts
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Association Chiropractic Discounts
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Association Hearing Discounts
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="py-3">
                        Association Lifestyle Benefits
                      </td>
                      {AvailableOCP && (
                        <td className="text-center py-3">
                          <BsXLg className="text-secondary" />
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-3">
                          <BsCheckLg className="text-info-green" />
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="border-bottom-0"></td>
                      {AvailableOCP && (
                        <td className="text-center py-4 border-bottom-0 enroll-btn-container">
                          <Button
                            color="info"
                            size="lg"
                            className="text-white w-100 py-3 bg-info-green enroll-btn"
                            onClick={() => handleSubmit(OCP)}
                            title="Enroll now for OCP"
                          >
                            <b>ENROLL NOW</b>
                          </Button>
                        </td>
                      )}
                      {AvailableMAX && (
                        <td className="text-center py-4 border-bottom-0 enroll-btn-container">
                          <Button
                            color="info"
                            size="lg"
                            className="text-white w-100 py-3 bg-info-green enroll-btn"
                            onClick={() => handleSubmit(MAX)}
                            title="Enroll now for MAX"
                          >
                            <b>ENROLL NOW</b>
                          </Button>
                        </td>
                      )}
                      {AvailablePRO && (
                        <td className="text-center py-4 border-bottom-0 enroll-btn-container">
                          <Button
                            color="info"
                            size="lg"
                            className="text-white w-100 py-3 bg-info-green enroll-btn"
                            onClick={() => handleSubmit(PRO)}
                            title="Enroll now for PRO"
                          >
                            <b>ENROLL NOW</b>
                          </Button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </BlurAnimateContent>
    </Row>
  );
};
export default CompareTable;
