import { FaTooth } from "react-icons/fa";
import { Spinner } from "reactstrap";

const LoaderSpin = (props) => {
  return (
    <div className="center-loader-container" {...props}>
      <div className="text-center">
          <Spinner className="loader-ring">Loading...</Spinner>
          <i className="tooth-loader-icon">
            <FaTooth size={35} />
          </i>
          <h2 className="mt-2 mb-0 fw-bold">SLEEK</h2>
          <h5>Dental Club</h5>
      </div>
    </div>
  );
};

export default LoaderSpin;
