import { motion } from "framer-motion";
const BlurAnimateContent = ({ animateDelay = 0.2, animateDuration = 0.2, children, ...props }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: animateDelay, duration: animateDuration } }}
      {...props}
    >
      {children}
    </motion.div>
  );
};
export default BlurAnimateContent;
