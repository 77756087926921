import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { PRO } from "../../../constant/PlanName";
import { PlanInfoProUI } from "../../Checkout/Step3/OffCanvas/OffCanvasPlanDetailsPRO";

const PlanInfoModalPRO = ({ open, toggle }) => {
  return (
    <Modal
      isOpen={open === PRO}
      toggle={toggle}
      centered
      fade={true}
      className="provider-info-modal home-info modal-pro"
      aria-label="PRO plan information"
    >
      <ModalHeader className="border-0" toggle={toggle}><span className="opacity-0">a</span></ModalHeader>
      <ModalBody className="provider-info-body-content overflow-auto">
        <h1 className="text-info-green">
          <b>SLEEK Dental PRO (Underwritten by MetLife)</b>
        </h1>
        <div className="table-responsive w-100">
          <Table className="table-bordered table-responsive table">
            {/* -----------------------Table Body---------------------- */}
            <tbody className="align-middle onboarding-compare-table-tbody">
              <tr>
                <td className="align-middle text-center bg-info-green text-white"><b>Coverage Type</b></td>
                <td className="align-middle text-center bg-info-green text-white"><b>In-Network % of Negotiated Fee*</b></td>
                <td className="align-middle text-center bg-info-green text-white"><b>Out-of-Network % of MAC**</b></td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type A: Preventative</div>
                  <div className="h6 my-2"><small>(cleanings, exams, X-rays)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">80%</td>
                <td className="text-center text-secondary py-3">80%</td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type B: Basic Restorative</div>
                  <div className="h6 my-2"><small>(fillings, extractions)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">60%</td>
                <td className="text-center text-secondary py-3">60%</td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type C: Major Restorative</div>
                  <div className="h6 my-2">
                    <small>
                      Benefit Waiting Period: Benefits are payable after a 12-month waiting period
                      from the start date of an individual’s benefits
                    </small>
                  </div>
                </td>
                <td className="text-center text-secondary py-3">50%</td>
                <td className="text-center text-secondary py-3">50%</td>
              </tr>
              <tr>
                <td className="align-middle bg-info-green text-white" colspan="3"><span>Deductible†</span></td>
              </tr>
              <tr>
                <td className="text-secondary py-3">Individual (per calendar year)</td>
                <td className="text-center text-secondary py-3">$100</td>
                <td className="text-center text-secondary py-3">$100</td>
              </tr>
              <tr>
                <td className="text-secondary py-3">Family (per calendar year)</td>
                <td className="text-center text-secondary py-3">$300</td>
                <td className="text-center text-secondary py-3">$300</td>
              </tr>
              <tr>
                <td className="align-middle bg-info-green text-white" colspan="3"><span>Annual Maximum Benefit (Through Year 3)</span></td>
              </tr>
              <tr>
                <td className="text-secondary py-3">Per Person</td>
                <td className="text-center text-secondary py-3">
                  <div>Year 1–$1,000</div>
                  <div>Year 2–$1,250</div>
                  <div>Year 3+–$1,500</div>
                </td>
                <td className="text-center text-secondary py-3">
                  <div>Year 1–$1,000</div>
                  <div>Year 2–$1,250</div>
                  <div>Year 3+–$1,500</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="plan-details-list">
          <p><b>Child(ren)’s eligibility</b> for dental coverage is from birth up to age 26</p>
          <p>
            *Negotiated Fee refers to the fees that participating dentists have agreed to accept as
            payment in full for covered services, subject to any copayments, deductibles, cost
            sharing and benefits maximums. Negotiated fees are subject to change.
          </p>
          <p>
            **MAC refers to the Maximum Allowed Charge, which is based on the lesser of (1) the
            amount charged by the out-of-network dentist or (2) the out-of-network scheduled amount
            for the state where the dental service is performed.
          </p>
          <p>†Applies only to Type B and C Services.</p>
        </div>
        <PlanInfoProUI />
      </ModalBody>
    </Modal>
  );
};
export default PlanInfoModalPRO;
