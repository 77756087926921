import { Modal, ModalBody, ModalHeader, Col, Row, Form, Button, Input } from "reactstrap";
import AetnaLogo from "../../../../assets/image/product/AetnaLogo.webp";
import { GrLocation } from "react-icons/gr";
import { OCP } from "../../../../constant/PlanName";
import { useState } from "react";

const ProviderInformationModalOCP = ({ open, toggle }) => {
  const [zipCode, setZipCode] = useState();
  const handleSearchForProvider = () => {
    if (zipCode) {
      window.open(`https://providers.bestbenefits.com/?Zip=${zipCode}&Products=AE&Title=Provider%20Results`, "_blank");
    }
  };
  return (
    <Modal isOpen={open === OCP} toggle={toggle} centered fade={true} className="provider-info-modal modal-ocp" aria-label="OCP provider information">
      <ModalHeader className="border-0" toggle={toggle}><span className="opacity-0">a</span></ModalHeader>
      <ModalBody className="provider-info-body-content">
        <Row>
          <Col md="7" className="provider-info-img-content">
            <img src={AetnaLogo} className="provider-info-img" alt="Aetna Logo" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h1 className="text-black">
              <b>Locate A Provider</b>
            </h1>
            <p className="py-2 text-black">
              Members can save 15% to 50%* per visit, on services at any of the many available dental practice locations
              nationwide. Dental services include: cleanings, X-rays, fillings, root canals and crowns. Members can also
              save on specialty care such as orthodontics and periodontics where available.
            </p>
            <p className="text-black">
              Enter a valid zip code, then press the search button. The search engine will return a sampling of the
              participating providers nearest the zip code entered.
            </p>
            <Form>
              <Row className="align-items-center">
                <Col xs="12" sm="12" md="12" lg="6" xl="6" className="my-2">
                  <div className="input-group">
                    <span className="input-group-text bg-transparent border-right-0 grey-icon">
                      <GrLocation />
                    </span>
                    <Input
                      type="zip"
                      name="zip-code"
                      id="zip-code"
                      value={zipCode}
                      onChange={(event) => setZipCode(event.target.value)}
                      placeholder="Zipcode"
                      className="border-left-0"
                      aria-label="Search provider's zipcode"
                    />
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="6" className="my-2">
                  <Button
                    color="modal-submit"
                    className="float-start modal-submit-btn-xs"
                    title="Search for providers"
                    onClick={handleSearchForProvider}
                  >
                    Search for Providers
                  </Button>
                </Col>
              </Row>
            </Form>
            <p className="pt-3">*Actual costs and savings vary by provider, service and geographical area.</p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
export default ProviderInformationModalOCP;
