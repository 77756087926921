import React, { Fragment } from "react";
import { useField } from "formik";
import { Input, FormFeedback, Label } from "reactstrap";
import InputMask from "react-input-mask";

export default function InputPhoneMask({ label, mask, ...props }) {
  const [field, meta] = useField(props);
  return (
    <Fragment>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <InputMask mask={mask} {...field}>
        {(inputProps) => <Input type="tel" {...inputProps} {...props} invalid={meta.touched && meta.error} />}
      </InputMask>
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </Fragment>
  );
}
