import { Button, Col, Row } from "reactstrap";
import SleekKit from "../../../assets/image/product/SleekKit.webp";
import { BsCheckLg, BsChevronRight } from "react-icons/bs";
import AnimateContent from "../../../components/Animation/BlurAnimateContent";
// import PositionAnimateContent from "../../../components/Animation/AnimateContent";
import { CLUB } from "../../../constant/PlanName";

const DentalClub = ({ buttonLabel, handleSubmit }) => {
  return (
    <Row className="onboarding-area2-dental bg-wave mx-0">
      <Col sm="12">
        <Row className="onboarding-dental-row pt-0 text-white">
          <Col sm="11" className="m-auto">
            <Row className="align-items-center">
              <Col md="12" className="my-5">
                <AnimateContent animateDelay={0.3}>
                  <h1 className="text-center onboarding-dental-title">
                    <b className="onboard-pricing-text-desktop">
                      <span>Don’t Need Dental Benefits?</span>
                      <span className="pricing-text-underline"> Join SLEEK Dental Club
                        <svg className="pricing-text-underline-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M8.1,146.2c0,0,240.6-55.6,479-13.8"></path></svg>
                      </span>
                    </b>
                    <b className="onboard-pricing-text-mbl">
                      <span>Don’t Need Dental Benefits?</span>
                      <span className="pricing-text-underline"> Join SLEEK Dental Club
                        <svg className="pricing-text-underline-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M8.1,146.2c0,0,240.6-55.6,479-13.8"></path></svg>
                      </span>
                    </b>
                  </h1>
                </AnimateContent>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="onboarding-dental-content-left">
                {/* <PositionAnimateContent x={"-100vw"}> */}
                <Row>
                  <Col md="12">
                    <h2 className="mb-0">
                      <b>The Perfect Electric Toothbrush Experience</b>
                    </h2>
                    <h2 className="mb-4">
                      <b>Simple Features for Easy Use</b>
                    </h2>
                    <div className="kit-container">
                      <p className="mb-0 kit-container-pera">
                        SLEEK Dental Club members receives a smart sonic electric toothbrush welcome
                        kit with quarterly brush head and floss picks auto shipped.
                      </p>
                      <div className="my-4 kit-container-list">
                        <h3>Every SLEEK Dental Club kit contains:</h3>
                        <div className="my-2 kit-list">
                          <BsCheckLg />
                          <span className="mx-3">Electric Toothbrush with 5 Cleaning Modes</span>
                        </div>
                        <div className="my-2 kit-list">
                          <BsCheckLg />
                          <span className="mx-3">Oscillating Brush Head with 2-Minute Timer</span>
                        </div>
                        <div className="my-2 kit-list">
                          <BsCheckLg />
                          <span className="mx-3">Built-In Rechargeable USB Charger</span>
                        </div>
                        <div className="my-2 kit-list">
                          <BsCheckLg />
                          <span className="mx-3">Toothbrush Holder & Traveling Case</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <Button
                      className="bg-white text-black"
                      size="lg"
                      onClick={() => handleSubmit(CLUB)}
                      // onClick={() => navigation("/product-details")}
                      title={buttonLabel}
                    >
                      {buttonLabel} <BsChevronRight className="svg" />
                    </Button>
                  </Col>
                </Row>
                {/* </PositionAnimateContent> */}
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="onboarding-dental-content-right">
                {/* <PositionAnimateContent x={"100vw"}> */}
                <img src={SleekKit} className="w-100 rounded-3" alt="Sleek-Kit" />
                {/* </PositionAnimateContent> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default DentalClub;
