import axios from "axios";
import { getApiDetails } from "../utils/localStorageHelpers";
const axiosInstance = axios.create();

const authEnc = "Basic " + btoa(`${getApiDetails()?.username}:${getApiDetails()?.password}`);

axiosInstance.interceptors.request.use(
  (config) => {
    // const token =  getAuthToken();
    if (config.data instanceof FormData) {
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    if (authEnc) config.headers.Authorization = authEnc;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.data && !!error.response.data.message) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject({ message: "Server Error" });
  }
);

export default axiosInstance;
