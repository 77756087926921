import { Col, Row } from "reactstrap";
import {
  AssociationMembershipBenefits,
  // BlogPost,
  FindPlan,
  HeroSection,
  Membership,
  MembershipTag,
  MembershipValueAddedBenefits,
  MembershipBenefitsTag,
  MetLifeSection,
  ProductPreviewVideo,
  SleekMembershipBenefits,
  // HomeQuickLInk,
} from "./Component";
import DentalClub from "../Checkout/Step3/DentalClub";
import { FrequentlyAskedQuestions } from "../ProductDetails/Component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProviderInformationModalOCP from "../Checkout/Step3/Modal/ProviderInformationModalOCP";
import ProviderInformationModalMAX from "../Checkout/Step3/Modal/ProviderInformationModalMAX";
import ProviderInformationModalPRO from "../Checkout/Step3/Modal/ProviderInformationModalPRO";
import PlanInfoModalOCP from "./Modal/PlanInfoModalOCP";
import PlanInfoModalPRO from "./Modal/PlanInfoModalPRO";
import PlanInfoModalMAX from "./Modal/PlanInfoModalMAX";

export default function Home() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [planInfoModal, setPlanInfoModal] = useState(false);

  // --------------Provider Info Toggle Modal Function---------------
  const ProviderInfoToggleModal = (name) => {
    setOpenModal((pre) => (pre ? false : name));
  };
  // ----------------Plan Info Toggle Modal Function-----------------
  const PlanInfoToggleModal = (name) => {
    setPlanInfoModal((pre) => (pre ? false : name));
  };

  return (
    <>
      {/* Provider Info Modal */}
      <ProviderInformationModalOCP open={openModal} toggle={ProviderInfoToggleModal} />
      <ProviderInformationModalMAX open={openModal} toggle={ProviderInfoToggleModal} />
      <ProviderInformationModalPRO open={openModal} toggle={ProviderInfoToggleModal} />

      {/* Plan Info Modal */}
      <PlanInfoModalOCP open={planInfoModal} toggle={PlanInfoToggleModal} />
      <PlanInfoModalPRO open={planInfoModal} toggle={PlanInfoToggleModal} />
      <PlanInfoModalMAX open={planInfoModal} toggle={PlanInfoToggleModal} />

      <Row className="hero-index-row">
        <Col md="12">
          <HeroSection />
        </Col>
        <Col md="12">
          <ProductPreviewVideo />
        </Col>
        <Col md="12">
          <SleekMembershipBenefits />
        </Col>
        <Col md="12">
          <Membership
            ProviderInfoToggleModal={ProviderInfoToggleModal}
            PlanInfoToggleModal={PlanInfoToggleModal}
          />
        </Col>
        <Col md="12">
          <DentalClub
            buttonLabel="JOIN SLEEK DENTAL CLUB"
            handleSubmit={() => navigate("product-details")}
          />
        </Col>
        <Col md="12">
          <MembershipTag />
        </Col>
        <Col md="12">
          <MembershipValueAddedBenefits />
        </Col>
        <Col md="12">
          <MembershipBenefitsTag />
        </Col>
        <Col md="12">
          <AssociationMembershipBenefits />
        </Col>
        <Col md="12">
          <div className="onboarding-content product-pre-content">
            <FrequentlyAskedQuestions />
          </div>
        </Col>
        <Col md="12">
          <MetLifeSection />
        </Col>
        {/* <Col md="12">
          <BlogPost />
        </Col> */}
        <Col md="12">
          <FindPlan />
        </Col>
        {/* <Col md="12">
          <HomeQuickLInk />
        </Col> */}
      </Row>
    </>
  );
}
