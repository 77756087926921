import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { OCP } from "../../../constant/PlanName";
import { PlanInfoOcpUI } from "../../Checkout/Step3/OffCanvas/OffCanvasPlanDetailsOCP";

const PlanInfoModalOCP = ({ open, toggle }) => {
  return (
    <Modal
      isOpen={open === OCP}
      toggle={toggle}
      centered
      fade={true}
      className="provider-info-modal home-info modal-ocp"
      aria-label="OCP plan information"
    >
      <ModalHeader className="border-0" toggle={toggle}><span className="opacity-0">a</span></ModalHeader>
      <ModalBody className="provider-info-body-content overflow-auto">
        <PlanInfoOcpUI />
      </ModalBody>
    </Modal>
  );
};
export default PlanInfoModalOCP;
