import { BsCheckLg } from "react-icons/bs";

export const CorrectBulletPoint = ({ children, ...props }) => {
  return (
    <div className="w-100" {...props}>
      <BsCheckLg />
      <span>{children}</span>
    </div>
  );
};
