import { FaTooth } from "react-icons/fa";
import { Spinner } from "reactstrap";

const AreaBaseLoaderSpin = (props) => {
  return (
    <div className="area-loader-container" {...props}>
      <div className="text-center">
        <Spinner className="loader-ring">Loading...</Spinner>
        <i className="tooth-loader-icon">
          <FaTooth size={35} />
        </i>
      </div>
    </div>
  );
};

export default AreaBaseLoaderSpin;
