import { Offcanvas, OffcanvasHeader, OffcanvasBody, Col } from "reactstrap";
import { PRO } from "../../../../constant/PlanName";

const OffCanvasPlanDetailsPRO = ({ toggle, isOpen }) => {
  return (
    <div>
      <Offcanvas
        toggle={toggle}
        isOpen={isOpen === PRO}
        direction="end"
        // scrollable
        className="off-canvas-pro"
      >
        <OffcanvasHeader toggle={toggle}></OffcanvasHeader>
        <OffcanvasBody>
          <Col md="12">
            <h1 className="text-info-green">
              <b>SLEEK Dental PRO</b>
            </h1>
            <PlanInfoProUI />
          </Col>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default OffCanvasPlanDetailsPRO;

export const PlanInfoProUI = () => {
  return (
    <>
      <h4 className="text-info-green my-4">
        <b>Dental Insurance: Description of Covered Services</b>
      </h4>
      <div className="list-div-content">
        <div>
          <p>
            <b>Type A Covered Services</b>
          </p>
          <ol>
            <li>
              Oral exams and problem-focused exams, but no more than one exam (whether the exam is
              an oral exam or problem-focused exam) every 6 months.
            </li>
            <li>
              Screenings, including state or federally mandated screenings, to determine an
              individual’s need to be seen by a dentist for diagnosis, but no more than once every 6
              months.
            </li>
            <li>
              Patient assessments (limited clinical inspection that is performed to identify
              possible signs of oral or systemic disease, malformation, or injury, and the potential
              need for referral for diagnosis and treatment), but no more than once every 6 months.
            </li>
            <li>Bitewing x-rays 1 set every 12 months.</li>
            <li>
              Cleaning of teeth also referred to as oral prophylaxis (including full mouth scaling
              in presence of generalized moderate or severe gingival inflammation after oral
              evaluation) once every 6 months.
            </li>
            <li>Topical fluoride treatment for a Child under age 14 once in 12 months.</li>
          </ol>
        </div>
        <div>
          <p>
            <b>Type B Covered Services</b>
          </p>
          <ol>
            <li>Full mouth or panoramic x-rays once every 60 months.</li>
            <li>Intraoral-periapical x-rays.</li>
            <li>X-rays, except as mentioned elsewhere.</li>
            <li>
              Pulp vitality tests and bacteriological studies for determination of bacteriologic
              agents.
            </li>
            <li>
              Collection and preparation of genetic sample material for laboratory analysis and
              report, but no more than once per lifetime.
            </li>
            <li>Diagnostic casts.</li>
            <li>Emergency palliative treatment to relieve tooth pain.</li>
            <li>Initial placement of amalgam fillings.</li>
            <li>
              Replacement of an existing amalgam filling, but only if:
              <ul>
                <li>at least 24 months have passed since the existing filling was placed; or</li>
                <li>a new surface of decay is identified on that tooth.</li>
              </ul>
            </li>
            <li>Initial placement of resin-based composite fillings.</li>
            <li>
              Replacement of an existing resin-based composite filling, but only if:
              <ul>
                <li>at least 24 months have passed since the existing filling was placed; or</li>
                <li>a new surface of decay is identified on that tooth.</li>
              </ul>
            </li>
            <li>Protective (sedative) fillings.</li>
            <li>
              Periodontal maintenance, where periodontal treatment (including scaling, root planing,
              and periodontal surgery, such as gingivectomy, gingivoplasty and osseous surgery) has
              been performed. Periodontal maintenance is limited to two times in any 12 months less
              the number of teeth cleanings received during such 12 month period.
            </li>
            <li>Pulp capping (excluding final restoration).</li>
            <li>Pulp therapy.</li>
            <li>Injections of therapeutic drugs.</li>
            <li>Space maintainers for a Child under age 14 once per lifetime per tooth area.</li>
            <li>
              Sealants or sealant repairs for a Child under age 14, which are applied to
              non-restored, non-decayed first and second permanent molars, once per tooth every 60
              months.
            </li>
            <li>
              Preventive resin restorations, which are applied to non-restored first and second
              permanent molars, once per tooth every 60 months.
            </li>
            <li>
              Interim caries arresting medicament application applied to permanent bicuspids and 1st
              and 2nd molar teeth, once per tooth every 60 months.
            </li>
            <li>
              Application of desensitizing medicaments where periodontal treatment (including
              scaling, root planing, and periodontal surgery, such as osseous surgery) has been
              performed.
            </li>
          </ol>
        </div>
        <div>
          <p>
            <b>Type C Covered Services</b>
          </p>
          <p>
            <b>
              Certain benefit waiting periods may need to be satisfied before expenses for these
              services are payable. Refer to the SCHEDULE OF BENEFITS for the benefit waiting period
              that applies.
            </b>
          </p>
          <ol>
            <li>Therapeutic pulpotomy (excluding final restoration).</li>
            <li>Apexification/recalcification.</li>
            <li>Pulpal regeneration, but not more than once per lifetime.</li>
            <li>
              General anesthesia or intravenous sedation in connection with oral surgery,
              extractions or other Covered Services, when We determine such anesthesia is necessary
              in accordance with generally accepted dental standards.
            </li>
            <li>Local chemotherapeutic agents.</li>
            <li>
              Initial installation of full or partial Dentures (other than implant supported
              prosthetics):
              <ul>
                <li>when needed to replace congenitally missing teeth; or</li>
                <li>
                  when needed to replace teeth that are lost while the person receiving such
                  benefits was insured for Dental Insurance.
                </li>
              </ul>
            </li>
            <li>
              Addition of teeth to a partial removable Denture to replace teeth removed while this
              Dental Insurance was in effect for the person receiving such services.
            </li>
            <li>
              Replacement of a non-serviceable fixed Denture if such Denture was installed more than
              10 Years prior to replacement.
            </li>
            <li>
              Replacement of a non-serviceable removable Denture if such Denture was installed more
              than 10 Years prior to replacement.
            </li>
            <li>
              Replacement of an immediate, temporary, full Denture with a permanent, full Denture,
              if the immediate, temporary, full Denture cannot be made permanent and such
              replacement is done within 12 months of the installation of the immediate, temporary,
              full Denture.
            </li>
            <li>
              Relinings and rebasings of existing removable Dentures:
              <ul>
                <li>
                  if at least 6 months have passed since the installation of the existing removable
                  Denture; and
                </li>
                <li>not more than once in any 36 month period.</li>
              </ul>
            </li>
            <li>
              Re-cementing of Cast Restorations or Dentures, but not more than once in a 12 month
              period.
            </li>
            <li>
              Adjustments of Dentures, if at least 6 months have passed since the installation of
              the Denture and not more than once in any 12 month period.
            </li>
            <li>
              Initial installation of Cast Restorations (except implant supported Cast
              Restorations).
            </li>
            <li>
              Replacement of Cast Restorations (except an implant supported Cast Restoration) but
              only if at least 10 Years have passed since the most recent time that:
              <ul>
                <li>a Cast Restoration was installed for the same tooth; or</li>
                <li>a Cast Restoration for the same tooth was replaced.</li>
              </ul>
            </li>
            <li>
              Prefabricated crown, but no more than one replacement for the same tooth within 10
              Years.
            </li>
            <li>Core buildup, but no more than once per tooth in a period of 10 Years.</li>
            <li>Posts and cores, but no more than once per tooth in a period of 10 Years.</li>
            <li>Labial veneers, but no more than once per tooth in a period of 10 Years.</li>
            <li>Oral surgery, except as mentioned elsewhere in this certificate.</li>
            <li>
              Consultations for interpretation of diagnostic image by a Dentist not associated with
              the capture of the image, but not more than once in a 12 month period.
            </li>
            <li>Other consultations, but not more than once in a 12 month period.</li>
            <li>
              Root canal treatment, including bone grafts and tissue regeneration procedures in
              conjunction with periradicular surgery, but not more than once for the same tooth.
            </li>
            <li>
              Root canal treatment, including bone grafts and tissue regeneration procedures in
              conjunction with periradicular surgery, but not more than once for the same tooth.
            </li>
            <li>
              Other endodontic procedures, such as apicoectomy, retrograde fillings, root
              amputation, and hemisection.
            </li>
            <li>
              Periodontal scaling and root planing, but no more than once per quadrant in any 24
              month period.
            </li>
            <li>Full mouth debridements, but not more than once per lifetime.</li>
            <li>
              Periodontal surgery, including gingivectomy, gingivoplasty and osseous surgery, but no
              more than one surgical procedure per quadrant in any 36 month period.
            </li>
            <li>Simple extractions.</li>
            <li>Surgical extractions.</li>
            <li>Tissue conditioning, but not more than once in a 36 month period.</li>
            <li>
              Simple repair of Cast Restorations or Dentures other than recementing, but not more
              than once in a 12 month period.
            </li>
            <li>Occlusal adjustments, but not more than once in a 12 month period.</li>
          </ol>
        </div>

        <div>
          <p>
            <b>Dental Insurance: Exclusions</b>
          </p>
          <p>We will not pay Dental Insurance benefits for charges incurred for:</p>
          <ol>
            <li>
              services which are not Dentally Necessary, or those which do not meet generally
              accepted standards of care for treating the particular dental condition;
            </li>
            <li>
              services for which You would not be required to pay in the absence of Dental
              Insurance;
            </li>
            <li>
              services or supplies received by You or Your Dependent before the Dental Insurance
              starts for that person;
            </li>
            <li>
              services which are neither performed nor prescribed by a Dentist, except for those
              services of a licensed Dental Hygienist which are supervised and billed by a Dentist,
              and which are for:
            </li>
            <li>
              <ul>
                <li>scaling and polishing of teeth; or</li>
                <li>fluoride treatments;</li>
              </ul>
            </li>
            <li>
              services which are primarily cosmetic, (For residents of Texas, see notice page
              section);
            </li>
            <li>services or appliances which restore or alter occlusion or vertical dimension;</li>
            <li>
              restoration of tooth structure damaged by attrition, abrasion or erosion, unless
              caused by disease;
            </li>
            <li>restorations or appliances used for the purpose of periodontal splinting;</li>
            <li>
              counseling or instruction about oral hygiene, plaque control, nutrition and tobacco;
            </li>
            <li>
              personal supplies or devices including, but not limited to: water piks, toothbrushes,
              or dental floss;
            </li>
            <li>
              decoration or inscription of any tooth, device, appliance, crown or other dental work;
            </li>
            <li>missed appointments;</li>
            <li>
              services:
              <ul>
                <li>covered under any workers’ compensation or occupational disease law;</li>
                <li>covered under any employer liability law;</li>
                <li>
                  for which the employer of the person receiving such services is required to pay;
                  or
                </li>
                <li>
                  received at a facility maintained by the Policyholder, labor union, mutual benefit
                  association, or VA hospital;
                </li>
              </ul>
            </li>
            <li>services covered under other coverage provided by the Policyholder;</li>
            <li>biopsies of hard or soft oral tissue;</li>
            <li>temporary or provisional restorations;</li>
            <li>temporary or provisional appliances;</li>
            <li>prescription drugs;</li>
            <li>services for which the submitted documentation indicates a poor prognosis;</li>
            <li>
              the following, when charged by the Dentist on a separate basis:
              <ul>
                <li>claim form completion;</li>
                <li>infection control, such as gloves, masks, and sterilization of supplies; or</li>
                <li>
                  local anesthesia, non-intravenous conscious sedation or analgesia, such as nitrous
                  oxide;
                </li>
              </ul>
            </li>
            <li>
              dental services arising out of accidental injury to the teeth and supporting
              structures, except for injuries to the teeth due to chewing or biting of food;
            </li>
            <li>caries susceptibility tests;</li>
            <li>
              modification of removable prosthodontic and other removable prosthetic services;
            </li>
            <li>
              implants including, but not limited to any related surgery, placement, maintenance,
              and removal;
            </li>
            <li>implant supported Dentures;</li>
            <li>repair of implants;</li>
            <li>fixed and removable appliances for correction of harmful habits;</li>
            <li>appliances or treatment for bruxism (grinding teeth);</li>
            <li>
              initial installation of a Denture to replace one or more teeth which were missing
              before such person was insured for Dental Insurance, except for congenitally missing
              teeth;
            </li>
            <li>precision attachments associated with fixed and removable prostheses;</li>
            <li>
              adjustment of a Denture made within 6 months after installation by the same Dentist
              who installed it;
            </li>
            <li>duplicate prosthetic devices or appliances;</li>
            <li>replacement of a lost or stolen appliance, Cast Restoration or Denture;</li>
            <li>orthodontic services or appliances;</li>
            <li>repair or replacement of an orthodontic device;</li>
            <li>
              diagnosis and treatment of temporomandibular joint disorders and cone beam imaging
              associated with the treatment of temporomandibular joint disorders;
            </li>
            <li>intra and extraoral photographic images.</li>
          </ol>
        </div>
      </div>
    </>
  );
};
