import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { signatureWebLink } from "../../../../apis/connection";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import AreaBaseLoaderSpin from "../../../../components/Loader/AreaBaseLoaderSpin";

const AuthorizationForm = ({ signatureUUID, isSignatureLoading, handelSubmit }) => {
  const iframeRef = useRef(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  useEffect(() => {
    const iframeRefCurrent = iframeRef.current;
    iframeRefCurrent?.addEventListener("load", () => setIsIFrameLoaded(true));
    return () => {
      iframeRefCurrent.removeEventListener("load", () => setIsIFrameLoaded(true));
    };
  }, [iframeRef]);

  return (
    <div className="auth-tab-content">
      <p>
        Before submitting your enrollment, please note that you are required to complete the
        following electronic signature. Please read through all the terms and conditions and check
        the box next to the plan you would like to enroll in before signing. Once you have completed
        the electronic signature process, you can then proceed with “Submit Enrollment”. If you have
        any questions or encounter any difficulties, please reach out to our customer support team
        via chat or by calling 1-844-753-3532 for any assistance.
      </p>
      <Row>
        {isSignatureLoading && !signatureUUID ? (
          <Col lg={12}>
            <AreaBaseLoaderSpin />
          </Col>
        ) : (
          <Col lg={12}>
            {!isIFrameLoaded && <AreaBaseLoaderSpin />}
            <iframe
              ref={iframeRef}
              title="Signature Link"
              src={signatureWebLink(signatureUUID)}
              height="100%"
              width="100%"
              data-fusion-no-placeholder=""
              id="player_1"
              data-ready="true"
              style={{ width: "100%", height: "417.305px" }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={12} className="text-end">
          <FormGroup className="my-5 mb-5">
            <Button
              color="info-green-full-submit-button"
              title="Submit enrollment"
              onClick={handelSubmit}
              disabled={isSignatureLoading && signatureUUID}
            >
              {isSignatureLoading && signatureUUID ? (
                <>
                  <Spinner size="sm" className="me-1">
                    Loading...
                  </Spinner>
                  Loading...
                </>
              ) : (
                "SUBMIT ENROLLMENT"
              )}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
export default AuthorizationForm;
