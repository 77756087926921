import { Col, Row } from "reactstrap";
import AnimateContent from "../../../components/Animation/AnimateContent";
import PlanCard from "./PlanCard";
import pricingRound from "../../../assets/image/pricing-round.webp";

const Plans = ({
  AvailableOCP,
  AvailablePRO,
  AvailableMAX,
  toggle,
  toggleOffCanvas,
  handleFindPlan,
  handleSubmit,
}) => {
  return (
    <Row className="onboarding-area2 plain bg-black mx-0">
      <div className="fixed-shape">
        <img src={pricingRound} alt="Pricing Shape" />
      </div>
      <Col sm="12" className="p-0">
        <AnimateContent scale={0} animateDuration={0.3}>
          <div className="text-center pricing-title-center text-white">
            <h1>
              <b>A Dental Experience Worth Smiling About</b>
            </h1>
            <h2>
              Everything you need, nothing you don’t. Pick a plan that best suits you or your
              family.
            </h2>
          </div>
        </AnimateContent>
      </Col>
      <Col sm="12">
        <AnimateContent animateDuration={0.6} x={"-100vw"}>
          <PlanCard
            AvailableOCP={AvailableOCP}
            AvailablePRO={AvailablePRO}
            AvailableMAX={AvailableMAX}
            toggle={toggle}
            toggleOffCanvas={toggleOffCanvas}
            handleFindPlan={handleFindPlan}
            handleSubmit={handleSubmit}
          />
        </AnimateContent>
      </Col>
    </Row>
  );
};
export default Plans;
