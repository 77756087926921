import { Modal, ModalBody, ModalHeader, Table, UncontrolledTooltip } from "reactstrap";
import { MAX } from "../../../constant/PlanName";
import { PlanInfoMaxUI } from "../../Checkout/Step3/OffCanvas/OffCanvasPlanDetailsMAX";
import { BsFillInfoCircleFill } from "react-icons/bs";

export const TableLabel = ({ name, id, tooltipData }) => {
  return (
    <tr>
      <th scope="row" style={{ backgroundColor: "#f7f7f7bf" }}>
        {name}
        {tooltipData ? (
          <>
            <BsFillInfoCircleFill className="mx-1 text-info-green outline-0" id={id} />
            <UncontrolledTooltip placement="right" target={id}>
              {tooltipData}
            </UncontrolledTooltip>
          </>
        ) : null}
      </th>
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
      <td style={{ backgroundColor: "#f7f7f7bf" }} />
    </tr>
  );
};

const PlanInfoModalMAX = ({ open, toggle }) => {
  return (
    <Modal
      isOpen={open === MAX}
      toggle={toggle}
      centered
      fade={true}
      className="provider-info-modal home-info modal-max"
      aria-label="MAX plan information"
    >
      <ModalHeader className="border-0" toggle={toggle}><span className="opacity-0">a</span></ModalHeader>
      <ModalBody className="provider-info-body-content overflow-auto">
        <h1 className="text-info-green">
          <b>SLEEK Dental MAX (Underwritten by MetLife)</b>
        </h1>
        <div className="table-responsive w-100">
          <Table className="table-bordered table-responsive table">
            {/* -----------------------Table Body---------------------- */}
            <tbody className="align-middle onboarding-compare-table-tbody">
              <tr>
                <td className="align-middle text-center bg-info-green text-white"><b>Coverage Type</b></td>
                <td className="align-middle text-center bg-info-green text-white"><b>In-Network % of Negotiated Fee*</b></td>
                <td className="align-middle text-center bg-info-green text-white"><b>Out-of-Network % of MAC**</b></td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type A: Preventative</div>
                  <div className="h6 my-2"><small>(cleanings, exams, X-rays)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">100%</td>
                <td className="text-center text-secondary py-3">100%</td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type B: Basic Restorative</div>
                  <div className="h6 my-2"><small>(fillings, extractions)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">80%</td>
                <td className="text-center text-secondary py-3">80%</td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type C: Major Restorative</div>
                  <div className="h6 my-2"><small>(bridges, dentures)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">50%</td>
                <td className="text-center text-secondary py-3">50%</td>
              </tr>
              <tr>
                <td className="py-3">
                  <div className="text-secondary">Type D: Orthodontia</div>
                  <div className="h6 my-2"><small>(orthodontic diagnostics and orthodontic treatment)</small></div>
                  <div>No waiting period</div>
                </td>
                <td className="text-center text-secondary py-3">50%</td>
                <td className="text-center text-secondary py-3">50%</td>
              </tr>
              <tr>
                <td className="align-middle bg-info-green text-white" colspan="3"><span>Deductible†</span></td>
              </tr>
              <tr>
                <td className="py-3 text-secondary">Individual (per calendar year)</td>
                <td className="text-center text-secondary py-3">$50</td>
                <td className="text-center text-secondary py-3">$50</td>
              </tr>
              <tr>
                <td className="py-3 text-secondary">Family (per calendar year)</td>
                <td className="text-center text-secondary py-3">$150</td>
                <td className="text-center text-secondary py-3">$150</td>
              </tr>
              <tr>
                <td className="align-middle bg-info-green text-white" colspan="3"><span>Annual Maximum Benefit (Through Year 3)</span></td>
              </tr>
              <tr>
                <td className="py-3 text-secondary">Per Person</td>
                <td className="text-center text-secondary py-3">
                  <div>Year 1–$1,500</div>
                  <div>Year 2–$1,750</div>
                  <div>Year 3+–$2,000</div>
                </td>
                <td className="text-center text-secondary py-3">
                  <div>Year 1–$1,500</div>
                  <div>Year 2–$1,750</div>
                  <div>Year 3+–$2,000</div>
                </td>
              </tr>
              <tr>
                <td className="align-middle bg-info-green text-white" colspan="3"><span>Orthodontia Lifetime Maximum</span></td>
              </tr>
              <tr>
                <td className="py-3 text-secondary">
                  <div>Per Person</div>
                  <div>(for children up to age 19 only)</div>
                </td>
                <td className="text-center text-secondary py-3">$1,500</td>
                <td className="text-center text-secondary py-3">$1,500</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <p>Child(ren)’s eligibility for dental coverage is from birth up to age 26</p>
          <p>
            *Negotiated Fee refers to the fees that participating dentists have agreed to accept as
            payment in full for covered services, subject to any copayments, deductibles, cost
            sharing and benefits maximums. Negotiated fees are subject to change.
          </p>
          <p>
            **MAC refers to the Maximum Allowed Charge, which is based on the lesser of (1) the
            amount charged by the out-of-network dentist or (2) the out-of-network scheduled amount
            for the state where the dental service is performed.
          </p>
          <p>†Applies only to Type B and C Services.</p>
        </div>
        <PlanInfoMaxUI />
      </ModalBody>
    </Modal>
  );
};
export default PlanInfoModalMAX;
