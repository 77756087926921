import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import InputField from "../../../../components/FormControl/InputField";
import * as yup from "yup";
import SelectField from "../../../../components/FormControl/SelectField";
import { getCheckoutData } from "../../../../utils/checkoutHelpers";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../apis/axiosInstance";
import apis from "../../../../apis";

const BillingInformationForm = ({ billingZipCode, setBillingZipCode, handelSubmit }) => {
  const [isApartment, setIsApartment] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [invalidZipCode, setInvalidStateList] = useState(false);

  const initialValues = {
    ADDRESS1: "",
    ADDRESS2: "",
    CITY: "",
    STATE: "",
    ZIPCODE: getCheckoutData()?.zipCode,
    is_billing_shipping_same: false,
  };

  const validationSchema = yup.object().shape({
    ADDRESS1: yup.string().strict(true).trim().required("This is required field"),
    CITY: yup.string().strict(true).trim().required("This is required field"),
    ZIPCODE: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits")
      .required("This is required field"),
    STATE: yup.string().strict(true).trim().required("This is required field"),
  });

  // -----------------------------------get State List-----------------------------
  const getStateList = () => {
    setInvalidStateList(false);
    if (
      billingZipCode?.length === 5 ||
      (getCheckoutData()?.zipCode && (billingZipCode?.length === 5 || !billingZipCode))
    ) {
      const selectProductDetails = getCheckoutData()?.selectProductDetails;
      axiosInstance
        .post(apis.checkout.stateList, {
          no_sale_states: selectProductDetails?.NOSALESTATES
            ? selectProductDetails?.NOSALESTATES?.split(",")
            : [""],
          default_no_sale_states: selectProductDetails?.DEFAULTNOSALESTATES
            ? selectProductDetails?.DEFAULTNOSALESTATES?.split(",")
            : [""],
          zip_code: billingZipCode ? billingZipCode : getCheckoutData()?.zipCode,
        })
        .then((resp) => {
          if (resp?.state?.length === 0) {
            setInvalidStateList(true);
          }
          setStateList(
            resp?.state?.map(({ state_name, state }) => ({
              title: state_name,
              value: state,
            }))
          );
        });
    }
  };
  useEffect(getStateList, [billingZipCode]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <InputField
                    label="Street Address"
                    type="text"
                    name="ADDRESS1"
                    id="ADDRESS1"
                    placeholder="Street Address"
                    aria-label="Billing street address"
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                {isApartment ? (
                  <FormGroup>
                    <InputField
                      label="Apartment, Suite, etc. (optional)"
                      type="text"
                      name="ADDRESS2"
                      id="ADDRESS2"
                      placeholder="Apartment, Suite, etc. (optional)"
                      aria-label="Billing address line 2"
                    />
                  </FormGroup>
                ) : (
                  <Button
                    color="link"
                    onClick={() => {
                      setIsApartment(true);
                    }}
                    className="text-decoration-none text-info-green fw-bold ps-0"
                  >
                    + Add apartment, suite, etc.
                  </Button>
                )}
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <InputField
                    label="City"
                    type="text"
                    name="CITY"
                    id="CITY"
                    placeholder="City"
                    aria-label="Billing city"
                  />
                </FormGroup>
              </Col>

              <Col lg={4}>
                <FormGroup>
                  <SelectField
                    label="State"
                    name="STATE"
                    options={stateList}
                    aria-label="Billing State"
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label htmlFor="ZIPCODE">ZIP code</Label>
                  <Input
                    invalid={(touched.ZIPCODE && errors.ZIPCODE) || invalidZipCode}
                    type="text"
                    name="ZIPCODE"
                    id="ZIPCODE"
                    value={values?.ZIPCODE}
                    onChange={(e) => {
                      setBillingZipCode(e.target.value);
                      setFieldValue("ZIPCODE", e.target.value);
                    }}
                    maxLength="5"
                    placeholder="ZIP Code"
                    aria-label="Billing ZIP code"
                  />
                  {touched.ZIPCODE && errors.ZIPCODE ? (
                    <small className="invalid-feedback-zip">{errors.ZIPCODE}</small>
                  ) : invalidZipCode ? (
                    <small className="invalid-feedback-zip">Zip code is Invalid!</small>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup className="my-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="is_billing_shipping_same"
                      id="email"
                      aria-label="Check this box to use this address for shipping"
                      checked={values.is_billing_shipping_same}
                      onChange={() =>
                        setFieldValue("is_billing_shipping_same", !values.is_billing_shipping_same)
                      }
                    />
                    <span className="mx-2">Use this address for shipping</span>
                  </Label>
                </FormGroup>
              </Col>

              <Col lg={12} className="text-end">
                <FormGroup className="my-3">
                  <Button
                    color="info"
                    type="submit"
                    className="px-4 py-2 bg-info-green text-white"
                    title="Continue"
                  >
                    CONTINUE
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default BillingInformationForm;
