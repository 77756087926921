import { useCallback, useEffect, useState } from "react";
import CompareTable from "./CompareTable";
import DentalClub from "./DentalClub";
import OffCanvasPlanDetailsMAX from "./OffCanvas/OffCanvasPlanDetailsMAX";
import OffCanvasPlanDetailsOCP from "./OffCanvas/OffCanvasPlanDetailsOCP";
import OffCanvasPlanDetailsPRO from "./OffCanvas/OffCanvasPlanDetailsPRO";
import ProviderInformationModalOCP from "./Modal/ProviderInformationModalOCP";
import ProviderInformationModalMAX from "./Modal/ProviderInformationModalMAX";
import ProviderInformationModalPRO from "./Modal/ProviderInformationModalPRO";
import BlurAnimateContent from "../../../components/Animation/BlurAnimateContent";
import { getCheckoutData, setCheckoutData } from "../../../utils/checkoutHelpers";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../apis/axiosInstance";
import apis from "../../../apis";
import LoaderSpin from "../../../components/Loader/LoaderSpin";
import { AnimatePresence } from "framer-motion";
import Plans from "./Plans";
import { CLUB, MAX, OCP, PRO } from "../../../constant/PlanName";
import { Alert, Col, Row } from "reactstrap";
import { PRODUCT_LABEL } from "../../../constant";
import OffCanvasPlanInformationOCP from "./OffCanvas/OffCanvasPlanInformationOCP";
import OffCanvasPlanInformationMAX from "./OffCanvas/OffCanvasPlanInformationMAX";
import OffCanvasPlanInformationPRO from "./OffCanvas/OffCanvasPlanInformationPRO";
import { getApiDetails } from "../../../utils/localStorageHelpers";
// import axios from "axios";

const Step3 = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  // ---------------------------State--------------------------
  const [isDefaultAgent, setIsDefaultAgent] = useState(!agentId);
  const [isOpenOffCanvasPlanDetails, setIsOpenOffCanvasPlanDetails] = useState(false);
  const [isOpenOffCanvasPlanInformation, setIsOpenOffCanvasPlanInformation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productRate, setProductRate] = useState([]);
  const [productDetails, setProductDetails] = useState([]);

  const [planLoading, setPlanLoading] = useState(true);
  const [AvailableOCP, setAvailableOCP] = useState(false);
  const [AvailableMAX, setAvailableMAX] = useState(false);
  const [AvailablePRO, setAvailablePRO] = useState(false);

  const [AvailableCLUB, setAvailableCLUB] = useState(true);
  const [licenseError, setLicenseError] = useState(false);

  // ----------Toggle Off Canvas Function for plan details----------
  const togglePlanDetailsOffCanvas = (name) => {
    setIsOpenOffCanvasPlanDetails((pre) => (pre ? false : name));
  };
  // --------Toggle Off Canvas Function for plan Information--------
  const togglePlanInformationOffCanvas = (name) => {
    setIsOpenOffCanvasPlanInformation((pre) => (pre ? false : name));
  };

  // --------------------Toggle Modal Function----------------------
  const toggleModal = (name) => {
    setOpenModal((pre) => (pre ? false : name));
  };

  //----------------Get Available Products Function----------------
  const getAvailableProducts = useCallback((Id) => {
    setPlanLoading(true);
    const payload = {
      state: getCheckoutData()?.state,
      agentId: Id,
    };
    axiosInstance
      .post(apis.checkout.getAvailableProducts, payload)
      .then((resp) => {
        setProductDetails(resp);
        const availableProductsLabel = resp?.map(({ LABEL }) => LABEL);
        if (availableProductsLabel) {
          setAvailableOCP(availableProductsLabel?.includes(PRODUCT_LABEL.OCP_LABEL));
          setAvailablePRO(availableProductsLabel?.includes(PRODUCT_LABEL.PRO_LABEL));
          setAvailableMAX(availableProductsLabel?.includes(PRODUCT_LABEL.MAX_LABEL));
          setAvailableCLUB(availableProductsLabel?.includes(PRODUCT_LABEL.CLUB_LABEL));
        }
        setPlanLoading(false);
      })
      .catch((err) => console.log("Product Api Error", err));
  }, []);

  // -------------------Get License State List---------------------
  const getLicenseStateList = useCallback(() => {
    setPlanLoading(true);
    const payload = { agentId: isDefaultAgent ? getApiDetails()?.agentId : agentId };
    console.log("isDefaultAgent", isDefaultAgent);
    if (payload) {
      axiosInstance
        .post(apis.checkout.LicenseList, payload)
        .then((resp) => {
          setLicenseError(false);
          let arr = resp?.map((item) => item?.STATE);
          let isAvailableLicense = arr.includes(getCheckoutData()?.state);
          if (isAvailableLicense) {
            console.log("getAvailableProducts");
            getAvailableProducts(payload.agentId);
          } else {
            console.log("enter false");
            setIsDefaultAgent(true);
            if (isDefaultAgent) setPlanLoading(false);
          }
        })
        .catch((err) => {
          console.log("enter catch");
          setIsDefaultAgent(true);
          if (isDefaultAgent) {
            setLicenseError(err);
            setPlanLoading(false);
          }
        });
    }
  }, [isDefaultAgent, agentId, getAvailableProducts]);
  useEffect(() => getLicenseStateList(), [getLicenseStateList]);

  // ------------------Get Product Rate Function-------------------
  const getProductRate = useCallback(() => {
    setLoading(true);
    const checkoutData = getCheckoutData();
    axiosInstance
      .post(apis.checkout.planRate, {
        level: checkoutData?.levelValue,
        area: checkoutData?.zipAgainstArea?.id,
        period_id: "1",
      })
      .then((resp) => {
        if (resp?.length !== 0) {
          setProductRate(resp);
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    getProductRate();
  }, [getProductRate]);

  // ------------------------Route Check------------------------
  useEffect(() => {
    if (!getCheckoutData()?.zipCode) {
      navigate(agentId ? `/${agentId}/zipcode` : "/zipcode");
    }
  }, [navigate, agentId]);
  // ------------------------Find Plan Function------------------------
  const handleFindPlan = (id) =>
    productRate?.find((item) => item?.plan_details?.description === id);

  // ------------------------Submit Function------------------------
  const handleSubmit = (id) => {
    const selectProductRate = handleFindPlan(id);
    const labelName =
      id === OCP
        ? "SLEEK Dental OCP"
        : id === PRO
        ? "SLEEK Dental PRO"
        : id === MAX
        ? "SLEEK Dental MAX"
        : "SLEEK Dental Club";
    // const selectProductDetails =
    //   productDetails[id === OCP ? 2 : id === PRO ? 3 : id === MAX ? 1 : 0];
    const selectProductDetails = productDetails.find((data) => data?.LABEL === labelName);
    setCheckoutData({
      ...getCheckoutData(),
      selectProductRate,
      selectProductDetails,
      product: "",
    });
    if (id === CLUB) {
      navigate(agentId ? `/${agentId}/product-details` : "/product-details");
    } else {
      navigate(agentId && !isDefaultAgent ? `/${agentId}/checkout-ocp` : "/checkout-ocp");
    }
  };

  return (
    <>
      {licenseError ? (
        <Row className="onboarding-area2 plain mx-0">
          <Col sm="12" className="p-0">
            <Alert color="danger">
              <h2 className="alert-heading">Error!</h2>
              <hr />
              <p>Error: {licenseError.message}</p>
            </Alert>
          </Col>
        </Row>
      ) : (
        <>
          <AnimatePresence>
            {loading || planLoading ? (
              <div className="loader-wrapper">
                <LoaderSpin />
              </div>
            ) : (
              <BlurAnimateContent>
                {AvailableOCP || AvailablePRO || AvailableMAX ? (
                  <>
                    {/* plan details OffCanvas */}
                    <OffCanvasPlanDetailsOCP
                      isOpen={isOpenOffCanvasPlanDetails}
                      toggle={togglePlanDetailsOffCanvas}
                    />
                    <OffCanvasPlanDetailsMAX
                      isOpen={isOpenOffCanvasPlanDetails}
                      toggle={togglePlanDetailsOffCanvas}
                    />
                    <OffCanvasPlanDetailsPRO
                      isOpen={isOpenOffCanvasPlanDetails}
                      toggle={togglePlanDetailsOffCanvas}
                    />

                    {/* plan Information & features OffCanvas */}
                    <OffCanvasPlanInformationOCP
                      isOpen={isOpenOffCanvasPlanInformation}
                      togglePlanDetailsOffCanvas={togglePlanDetailsOffCanvas}
                      togglePlanInformationOffCanvas={togglePlanInformationOffCanvas}
                      handleSubmit={handleSubmit}
                    />
                    <OffCanvasPlanInformationMAX
                      isOpen={isOpenOffCanvasPlanInformation}
                      togglePlanDetailsOffCanvas={togglePlanDetailsOffCanvas}
                      togglePlanInformationOffCanvas={togglePlanInformationOffCanvas}
                      handleSubmit={handleSubmit}
                    />
                    <OffCanvasPlanInformationPRO
                      isOpen={isOpenOffCanvasPlanInformation}
                      togglePlanDetailsOffCanvas={togglePlanDetailsOffCanvas}
                      togglePlanInformationOffCanvas={togglePlanInformationOffCanvas}
                      handleSubmit={handleSubmit}
                    />

                    {/* Plan Provider Info Modal */}
                    <ProviderInformationModalOCP open={openModal} toggle={toggleModal} />
                    <ProviderInformationModalMAX open={openModal} toggle={toggleModal} />
                    <ProviderInformationModalPRO open={openModal} toggle={toggleModal} />
                    <Plans
                      AvailableOCP={AvailableOCP}
                      AvailablePRO={AvailablePRO}
                      AvailableMAX={AvailableMAX}
                      toggle={toggleModal}
                      toggleOffCanvas={togglePlanInformationOffCanvas}
                      handleFindPlan={handleFindPlan}
                      handleSubmit={handleSubmit}
                    />
                    <CompareTable
                      AvailableOCP={AvailableOCP}
                      AvailablePRO={AvailablePRO}
                      AvailableMAX={AvailableMAX}
                      toggle={togglePlanDetailsOffCanvas}
                      handleFindPlan={handleFindPlan}
                      handleSubmit={handleSubmit}
                    />
                  </>
                ) : (
                  <Row className="onboarding-area2 plain mx-0">
                    <Col sm="12" className="p-0">
                      <Alert color="warning">
                        <h2 className="alert-heading">
                          Discount and Insurance Membership Levels Unavailable in Your State
                        </h2>
                        <hr />
                        <p>The membership level available in your state is SLEEK Dental Club.</p>
                      </Alert>
                    </Col>
                  </Row>
                )}

                {AvailableCLUB && <DentalClub buttonLabel="ONLY JOIN SLEEK DENTAL CLUB" handleSubmit={handleSubmit} />}
              </BlurAnimateContent>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};
export default Step3;
