import { FaCircle, FaList, FaTooth } from "react-icons/fa";
import CareProduct from "../../assets/image/care-product.jpg";
import { Button, Card } from "reactstrap";

export default function MembershipCard({
  membershipData,
  HandleOpenPlanInfo,
  HandleOpenProviderInfo,
}) {
  const { title, subtitle, price, benefitPoints } = membershipData;
  const splitValue = price ? price?.toString()?.split(".") : [];
  const subtitleSplit = subtitle.split(" ");
  return (
    <Card className="value-product-membership-card">
      <div className="membership-card-img-content">
        <img src={CareProduct} className="membership-card-img" alt="Sleek Care" />
      </div>
      {/* membership Title */}
      <div className="membership-card-title-content">
        <h3 className="membership-card-title">{title}</h3>
        <h3 className="membership-card-title">{`${subtitleSplit[0]} ${subtitleSplit[1]}`}</h3>
        <h3 className="membership-card-title">{subtitleSplit[2]}</h3>
        <div className="membership-card-price-holder">
          <sup className="icon-dollar">$</sup>
          <span className="price">{splitValue[0]}</span>
          <sup className="decimal">{splitValue[1]}</sup>
          <sub className="starting-text">starting</sub>
          <p className="mb-0">$25 One-time Enrollment</p>
        </div>
      </div>

      {/* membership Benefit Points */}
      <div className="membership-card-benefit-content">
        {benefitPoints?.map((item, index) => (
          <div key={`${title} ${index}`} className="card-list">
            <FaCircle />
            {item}
          </div>
        ))}
      </div>

      {/* Plan & Provider Information Button*/}
      <div className="rounded-bottom">
        <Button
          className="text-uppercase w-100 border-0 rounded-0 plan-details-btn"
          title={`Plan information for ${title}`}
          onClick={HandleOpenPlanInfo}
        >
          <b>Plan Information</b>
          <i className="ml-icon mt-1">
            <FaList className="ml-external-icon" />
          </i>
        </Button>
        <Button
          className="text-uppercase w-100 border-0 provider-info-btn"
          onClick={HandleOpenProviderInfo}
          title={`Provider information for ${title}`}
        >
          <b>Provider Information</b>
          <i className="ml-icon">
            <FaTooth />
          </i>
        </Button>
      </div>
    </Card>
  );
}
