import { Offcanvas, OffcanvasHeader, OffcanvasBody, Col, Row, Table } from "reactstrap";
import { OCP } from "../../../../constant/PlanName";

const OffCanvasPlanDetailsOCP = ({ toggle, isOpen }) => {
  return (
    <div>
      <Offcanvas
        toggle={toggle}
        isOpen={isOpen === OCP}
        direction="end"
        // scrollable
        className="off-canvas-ocp"
      >
        <OffcanvasHeader toggle={toggle}></OffcanvasHeader>
        <OffcanvasBody>
          <PlanInfoOcpUI />
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default OffCanvasPlanDetailsOCP;

export const PlanInfoOcpUI = () => {
  return (
    <Row>
      <Col md="12">
        <h1 className="text-info-green">
          <b>
            SLEEK Dental OCP (Aetna Dental Access<sup>®</sup>)
          </b>
        </h1>
      </Col>
      <Col md="12">
        <div className="table-responsive w-100">
          <Table className="table table-bordered table-responsive">
            <thead className="thead">
              <tr>
                <th
                  className="align-middle text-center bg-info-green text-white py-4 border-bottom border-4 border-white"
                  colSpan="10"
                >
                  <h5 className="mb-0">
                    <b>Sample Savings*</b>
                  </h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle text-center bg-info-green text-white" colSpan="3">
                  <span>Product/Service</span>
                </td>
                <td className="align-middle text-center bg-info-green text-white" colSpan="2">
                  <span>AVG. Price</span>
                </td>
                <td className="align-middle text-center bg-info-green text-white" colSpan="2">
                  <span>You Pay</span>
                </td>
                <td className="align-middle text-center bg-info-green text-white" colSpan="2">
                  <span>Savings</span>
                </td>
                <td className="align-middle text-center bg-info-green text-white" colSpan="2">
                  <span>% Saved</span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-secondary">
                  <span>Dental Cleaning (Adult)</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$130.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$69.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$61.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>47%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-secondary">
                  <span>Dental Cleaning (Child)</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$96.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$53.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$43.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>45%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-secondary">
                  <span>Complete X-rays</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$174.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$89.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$85.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>49%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-secondary">
                  <span>Root Canal (Anterior)</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$906.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$548.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$358.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>40%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-secondary">
                  <span>Complete Upper Denture</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$1,422.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$1,025.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>$397.00</span>
                </td>
                <td className="text-center align-middle text-secondary" colSpan="2">
                  <span>28%</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <p className="small">
          <sup>*</sup>Actual costs and savings may vary by provider, service and geographic
          location. We use the average of negotiated fees from participating providers to determine
          the average costs, as shown on the chart. The select regional average cost represents the
          average fees for the procedures listed above in Los Angeles, Orlando, Chicago and New York
          City, as displayed in the cost of care tool as of June 2020.
        </p>
        <p className="small">
          The discount program provides access to the Aetna Dental Access® network. This network is
          administered by Aetna Life Insurance Company (ALIC). Neither ALIC nor any of its
          affiliates offers or administers the discount program. Neither ALIC nor any of its
          affiliates is an affiliate, agent, representative or employee of the discount program.
          Dental providers are independent contractors and not employees or agents of ALIC or its
          affiliates. ALIC does not provide dental care or treatment and is not responsible for
          outcomes.
        </p>
      </Col>
      {/* <Col md="12">
      <h3 className="text-info-green">
        <b>SLEEK OCP Marketing Disclosure</b>
      </h3>
    </Col> */}
      <Col md="12">
        {/* <p className="small">
        Dental, Teledentist and Pharmacy Disclosure. <strong>This plan is NOT insurance.</strong> This plan is
        not a qualified health plan under the Affordable Care Act (ACA). Some services may be covered by a
        qualified health plan under the ACA. This plan does not meet the minimum creditable coverage
        requirements under M.G.L. c. 111M and 956 CMR 5.00. This is not a Medicare prescription drug plan.
        Discounts on hospital services are not available in Maryland. The plan provides discounts at certain
        health care providers of medical services. The plan does not make payments directly to the providers of
        medical services. The plan member is obligated to pay for all health care services but will receive a
        discount from those health care providers who have contracted with the discount plan organization. The
        range of discounts will vary depending on the provider type and services provided. The licensed discount
        plan organization is Coverdell &amp; Company, Inc., at 2850 W. Golf Road, Rolling Meadows, IL 60008,
        1-888-868-6199. To view a list of participating providers visit www.findbestbenefits.com and enter promo
        code 575313.
        <strong>
          You have the right to cancel this plan within 30 days of the effective date for a full refund of fees
          paid.
        </strong>
        Such refunds are issued within 30 days of cancellation.
      </p>
      <p>
        Click to view
        <a href="pdf" target="_blank" rel="noopener" className="text-info-green text-decoration-none">
          Terms, Conditions and Disclosures
        </a>
      </p> */}
        <p className="mb-2">
          <b>This benefit is not available to residents of Vermont</b>
        </p>
        <p>
          <b>This is not Insurance</b>
        </p>
      </Col>
    </Row>
  );
};
