import { Button, Col, Input, Label, Progress, Row } from "reactstrap";
// User Image
import singleUserImg from "../../../assets/image/user/SingleTransparent.webp";
import coupleImg from "../../../assets/image/user/CoupleTransparent.webp";
import parentKidsImg from "../../../assets/image/user/ParentKidsTransparent.webp";
import familyImg from "../../../assets/image/user/FamilyTransparent.webp";

import { useNavigate, useParams } from "react-router-dom";
import WrapContent from "../../../components/WrapContent";
import { useEffect, useState } from "react";
import BlurAnimateContent from "../../../components/Animation/BlurAnimateContent";
import AnimateContent from "../../../components/Animation/AnimateContent";
import axiosInstance from "../../../apis/axiosInstance";
import apis from "../../../apis";
import { getCheckoutData, setCheckoutData } from "../../../utils/checkoutHelpers";
import AreaBaseLoaderSpin from "../../../components/Loader/AreaBaseLoaderSpin";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const Step1 = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  // const [selectUserType, setSelectUserType] = useState(getCheckoutData()?.levelValue);
  const [selectUserType, setSelectUserType] = useState();
  const [progressValue, setProgressValue] = useState(0);
  const [levelList, setLevelList] = useState([]);
  const [loading, setLoading] = useState(true);

  // ---------------------------initial progress Value---------------------------
  useEffect(() => {
    setCheckoutData({});
    setTimeout(() => {
      setProgressValue(35);
    }, 200);
  }, []);
  // -------------------------------Get Level List-------------------------------
  const getLevelList = () => {
    setLoading(true);
    axiosInstance.get(apis.checkout.levelList).then((resp) => {
      if (resp?.length !== 0) {
        setLevelList(resp);
        setLoading(false);
      }
    });
  };
  useEffect(getLevelList, []);
  // --------------------------------Handle Submit-------------------------------
  const handleSubmit = () => {
    if (selectUserType) {
      setCheckoutData({ ...getCheckoutData(), levelValue: selectUserType });
      navigate(agentId ? `/${agentId}/zipcode` : "/zipcode");
    }
  };
  // ------------------------------custom User Radio Button Render-------------------------------
  const customUserRadio = (beforeImage, value, labelName, animateDelay) => {
    return (
      <AnimateContent scale={0} animateDelay={animateDelay}>
        <Label check>
          <div
            className={`d-flex justify-content-center custom-user-radio-button ${
              selectUserType === value ? "custom-user-radio-button-active" : ""
            }`}
          >
            <img src={beforeImage} alt="Dental Plan Img" className="w-100 w-mbl-radio-img" />
          </div>
          <div className="d-flex justify-content-center custom-user-radio-label">
            <Input
              type="radio"
              name="user"
              className="mx-2 custom-user-radio-input"
              value={value}
              checked={selectUserType === value}
              onChange={() => setSelectUserType(value)}
            />
            <span className="text-black radio-label">{labelName}</span>
          </div>
        </Label>
      </AnimateContent>
    );
  };

  return (
    <WrapContent>
      {/* -----------------------------------Progress Bar--------------------------------------- */}
      <Row>
        <Col md={12}>
          <BlurAnimateContent>
            <div className="onboarding-progress-content">
              <Progress animated value={progressValue} barAriaLabelledBy="Progress for step1"/>
            </div>
          </BlurAnimateContent>
        </Col>
      </Row>

      <Row className="mt-0" id="step1">
        <Col lg="12">
          <BlurAnimateContent>
            <div className="text-center joining-title-center text-black joining-adjust m-0 pt-0">
              <h1>
                <b>Who Will Be Joining</b>
              </h1>
              <h1>
                <b>SLEEK Dental Club Today?</b>
              </h1>
            </div>
          </BlurAnimateContent>
        </Col>
        <AnimatePresence>
          {!loading ? (
            <BlurAnimateContent exit={{ opacity: 0 }}>
              {/* -----------------------------------Radio Button Group--------------------------------------- */}
              <Col lg="12">
                <Row className="form-check-content-row">
                  <Col
                    className="form-check-img-col"
                    xl="3"
                    lg="3"
                    md="6"
                    sm="6"
                    xs="12"
                    title="Just Me"
                  >
                    {customUserRadio(singleUserImg, levelList[0]?.id, "Just Me", 0.3)}
                  </Col>
                  <Col
                    className="form-check-img-col"
                    xl="3"
                    lg="3"
                    md="6"
                    sm="6"
                    xs="12"
                    title="Me & Spouse"
                  >
                    {customUserRadio(coupleImg, levelList[1]?.id, "Me & Spouse", 0.1)}
                  </Col>
                  <Col
                    className="form-check-img-col"
                    xl="3"
                    lg="3"
                    md="6"
                    sm="6"
                    xs="12"
                    title="Me & Kiddos"
                  >
                    {customUserRadio(parentKidsImg, levelList[2]?.id, "Me & Kiddos", 0.1)}
                  </Col>
                  <Col
                    className="form-check-img-col"
                    xl="3"
                    lg="3"
                    md="6"
                    sm="6"
                    xs="12"
                    title="Whole Family"
                  >
                    {customUserRadio(familyImg, levelList[3]?.id, "Whole Family", 0.3)}
                  </Col>
                </Row>
              </Col>

              {/* -----------------------------------Next Button--------------------------------- */}
              {/* <AnimatePresence>
                {selectUserType && (
                  <AnimateContent scale={0}> */}
              <Col lg="12">
                <div className="d-flex justify-content-center mt-1 mt-md-next-btn">
                  <Button
                    color="primary"
                    size="lg"
                    disabled={!selectUserType}
                    className="text-white checkout-next-pre-button mt-4"
                    onClick={handleSubmit}
                    title="Next"
                    aria-label="Next"
                  >
                    NEXT
                  </Button>
                </div>
              </Col>
              {/* </AnimateContent>
                )}
              </AnimatePresence> */}
            </BlurAnimateContent>
          ) : (
            <motion.div className="mt-5" animate={{ opacity: loading ? 1 : 0 }}>
              <AreaBaseLoaderSpin />
            </motion.div>
          )}
        </AnimatePresence>
      </Row>
    </WrapContent>
  );
};
export default Step1;
