import { BsCheckCircleFill, BsFillRecord2Fill, BsFillRecordFill } from "react-icons/bs";
import SLEEKClubGraphic from "../../assets/image/product/SLEEK-Club-Graphic.webp";
import SLEEKRefillCombo from "../../assets/image/product/SLEEK-Refill-Combo.webp";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  ButtonGroup,
  Card,
  Col,
  Input,
  List,
  Row,
} from "reactstrap";
import { BiChevronLeft, BiChevronRight, BiMinus, BiPlus } from "react-icons/bi";
import { useState } from "react";

export const ImagePreview = ({ items, selectImage, setSelectImage }) => {
  const handlePrevious = () => {
    setSelectImage((pre) => (pre ? pre - 1 : items.length - 1));
  };
  const handleNext = () => {
    setSelectImage((pre) => (items.length - 1 > pre ? pre + 1 : 0));
  };
  return (
    <Row>
      <Col xl="11" lg="11" md="11" sm="12">
        <div className="pro-details-img-container d-flex align-items-center">
          <div onClick={handlePrevious}>
            <BiChevronLeft className="pro-chevron-icon pre" title="Next image" />
          </div>
          <img
            src={items[selectImage].src}
            alt="Product"
            className="img-responsive rounded-2 w-100 single-product-full-img"
          />
          <div onClick={handleNext}>
            <BiChevronRight className="pro-chevron-icon next" title="Previous image" />
          </div>
        </div>

        <div className="pro-chevron-dots-container">
          {items.map((item, index) =>
            item.key === items[selectImage].key ? (
              <BsFillRecord2Fill className="text-info-green" key={index} />
            ) : (
              <BsFillRecordFill key={index} onClick={() => setSelectImage(index)} />
            )
          )}
        </div>
        <div className="d-flex align-items-center">
          <div className="single-left-product-view-col">
            <Row className="single-product-left-view-content overflow-auto">
              {items?.map((item, index) => (
                <Col xl="2" lg="2" md="2" sm="2" xs="3" className="single-product-left-img-content" key={item.key}>
                  <img
                    className={`single-product-left-img rounded-2 ${item.key === items[selectImage].key && "active"}`}
                    src={item.src}
                    alt={item.altText}
                    onClick={() => setSelectImage(index)}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const ProductPriceAndDescription = ({
  kitIncludes,
  refillsPerKit,
  productDetails,
  qty,
  setQty,
  handleSubmit,
}) => {
  return (
    <>
      <h1 className="h1 single-product-title">SLEEK Dental Club</h1>
      {/* -------------------Product description-------------------- */}
      <div className="mt-3">
        <p className="single-product-pera">
          SLEEK Dental Club members receives a smart sonic electric toothbrush kit with quarterly brush head and floss
          picks auto shipped.
        </p>
      </div>
      <div className="refills-card-container">
        <div className="fw-bold mt-2 mb-3 refills-card-title text-info-green h5">
          Every SLEEK Dental Club Kit Includes:
        </div>
        <List type="unstyled">
          {kitIncludes.map((item, index) => (
            <li className="d-flex align-items-center mb-2" key={index}>
              <i className="text-info-green" ><BsCheckCircleFill/></i>
              <span className="mx-2">{item}</span>
            </li>
          ))}
        </List>
      </div>
      <div className="product-set-container">
        <div className="fw-bold mt-2 mb-3 product-set-title text-info-green h5">Quarterly Refills per Kit:</div>
        <List type="unstyled">
          {refillsPerKit.map((item, index) => (
            <li className="d-flex align-items-center mb-2" key={index}>
              <i className="text-info-green" ><BsCheckCircleFill/></i>
              <span className="mx-2">{item}</span>
            </li>
          ))}
        </List>

        <p className="pro-month-des">
          <i className="mb-0 text-muted">
            {productDetails?.[1]?.price} ( {productDetails?.[0]?.price} Every 3 Months )
          </i>
        </p>
        <Row className="mt-3 single-product-btn-grp">
          <Col xl="6" lg="6" md="6" sm="12" xs="12" className="single-product-count-content">
            <ButtonGroup className="single-product-count-btn-grp w-100">
              <Button
                className="count-btn remove-btn border-0"
                title="Remove"
                aria-label="Remove product"
                onClick={() => setQty((pre) => (pre > 1 ? pre - 1 : pre))}
              >
                <BiMinus />
              </Button>
              <Input
                type="number"
                aria-label="Number of products"
                value={qty}
                onChange={(e) => {
                  e.target.value >= 1 && e.target.value <= 10 && setQty(e.target.value);
                }}
                min={1}
                className="rounded-0 text-center single-product-count-input w-50"
              />
              <Button
                className="count-btn add-btn border-0"
                title="Add"
                aria-label="Add product"
                onClick={() => setQty((pre) => (pre < 10 ? +pre + 1 : pre))}
              >
                <BiPlus />
              </Button>
            </ButtonGroup>
          </Col>
          <Col xl="12" lg="12" md="12" sm="12" xs="12" className="single-product-count-add-content mt-0">
            <Button
              color="primary"
              className="mx-2 mt-3 px-4 product-add-button float-left w-50"
              title="Add to cart"
              onClick={handleSubmit}
            >
              Add to cart
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const InfoCard1 = () => {
  return (
    <Row className="info-card-row align-items-center first-card">
      <Col xl={5} lg={5} md={5} sm={12} className="info-card-col-left">
        <img
          src={SLEEKClubGraphic}
          className="rounded-3 product-routine-info-img shadow"
          alt="SLEEK Club Graphic Img"
        />
      </Col>
      <Col xl={5} lg={5} md={5} sm={12} className="info-card-col-right">
        <h2 className="mb-2 text-info-green fw-bold">Sonic Electric Toothbrush</h2>
        <p className="mb-2">Epic oral care at the push of a button.</p>
        <List className="" type="unstyled">
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Help prevent cavities with deep cleaning</span>
          </li>
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Perfect for individuals with sensitive teeth</span>
          </li>
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Ideal for whitening and plaque removal</span>
          </li>
        </List>
      </Col>
    </Row>
  );
};

export const InfoCard2 = () => {
  return (
    <Row className="info-card-row align-items-center second-card">
      <Col xl={5} lg={5} md={5} sm={12} className="info-card-col-left">
        <img
          src={SLEEKRefillCombo}
          className="rounded-3 product-routine-info-img shadow"
          alt="SLEEK Refill Combo Img"
        />
      </Col>
      <Col xl={5} lg={5} md={5} sm={12} className="info-card-col-right">
        <h2 className="mb-2 text-info-green fw-bold">Hassle Free Quarterly Refills</h2>
        <p className="mb-2">Conveniently auto shipped brush head & floss picks.</p>
        <List className="" type="unstyled">
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Automatic delivery to your doorstep every 3 months</span>
          </li>
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Free Shipping in the contiguous United States</span>
          </li>
          <li className="d-flex align-items-center mb-2">
            <i className="text-info-green" ><BsCheckCircleFill/></i>
            <span className="mx-2">Cancel your plan at anytime</span>
          </li>
        </List>
      </Col>
    </Row>
  );
};

export const FrequentlyAskedQuestions = () => {
  const [open, setOpen] = useState("one");
  const toggle = (id) => {
    if (open === id) {
      setOpen("one");
    } else {
      setOpen(id);
    }
  };
  return (
    <Card className="p-5 border-0 shadow product-question-card">
      <h2 className="fw-bold mb-5 text-info-green text-center question-text">Frequently Asked Questions</h2>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Accordion flush open={open} toggle={toggle} className="product-question-accordion-content">
            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa1">
                <span>What is SLEEK Dental Club?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa1">
                <p>
                  SLEEK Dental Club is a modern, convenient oral care program offering four dental benefit membership
                  levels to choose from. Each qualifying member receives an introductory oral care kit that includes a
                  SLEEK sonic electric toothbrush, carrying case, USB charger, toothbrush holder and traveling case.
                  Quarterly auto-shipped toothbrush heads and floss picks are included with each membership.
                  Additionally, members who join the Premier Business Association (PBA) have access to dental insurance
                  underwritten by Metropolitan Life Insurance Company as part of their enrollment into the association.
                </p>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa2">
                <span>How many kits and quarterly refills are included in my membership?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa2">
                <p>Each SLEEK Dental Club membership includes the following welcome kit(s):</p>
                <ul>
                  <li>Member receives: 1</li>
                  <li>Member plus spouse: 2</li>
                  <li>Member plus child: 1</li>
                  <li>Member plus family: 2</li>
                </ul>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa3">
                <span>What is the Premier Business Association?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa3">
                <p>
                  The PBA is a non-profit organization that offers small businesses, the self-employed, independent
                  contractors, and entrepreneurs ages 18 and over access to quality benefits on business, health and
                  even consumer products and services.
                </p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Accordion flush open={open} toggle={toggle} className="product-question-accordion-content">
            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa4">
                <span>How do I look up providers and access my benefits?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa4">
                <p>
                  Members can search for providers and learn how to utilize each benefit included in their membership by
                  logging into their member portal 24 hours a day, 7 days a week.
                </p>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa5">
                <span>What are Byte invisible aligners?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa5">
                <p>
                  Invisible aligners are these amazing, totally clear plastic devices custom made to fit your teeth.
                  They gradually shift your teeth so that they’re straight and your smile looks great.
                </p>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem className="product-question-accordion-item">
              <AccordionHeader targetId="qa6">
                <span>What is teledentistry?</span>
              </AccordionHeader>
              <AccordionBody accordionId="qa6">
                <p>
                  Teledentistry provides virtual online dental consultations to help with oral health issues related to
                  dental emergencies, pediatric dentistry, general questions related to dental products, and other oral
                  health concerns.
                </p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
    </Card>
  );
};
