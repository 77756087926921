import { createContext, useState } from "react";
export const GlobalData = createContext(null);

const Context = ({ children }) => {
  const [memberPerchesDetails, setMemberPerchesDetails] = useState();

  return (
    <GlobalData.Provider value={{ memberPerchesDetails, setMemberPerchesDetails }}>
      {children}
    </GlobalData.Provider>
  );
};
export default Context;
