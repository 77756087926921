import { useContext, useEffect, useLayoutEffect, useState } from "react";
import FooterContent from "./FooterContent";
import HeaderContent from "./HeaderContent";
import { useLocation, useParams } from "react-router-dom";
import { GlobalData } from "../../context/context";
let timeout = null;

const PublicLayout = ({ children }) => {
  const { pathname } = useLocation();
  let location = useLocation();
  const { agentId } = useParams();

  const { memberPerchesDetails } = useContext(GlobalData);
  const [defaultPageHeight, setDefaultPageHeight] = useState(true);
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [visibleFloatingButton, setVisibleFloatingButton] = useState(false);
  const [heaterExtension, setHeaterExtension] = useState(false);

  const isFirefoxBrowser = window?.navigator?.userAgent?.includes?.("Firefox");

  const scrollFunction = () => {
    const scrolled = document.documentElement.scrollTop;
    // ---------------------scroll To header small------------------------
    setIsScrollTop(scrolled > 50);
    // ----------------------scroll To Top Button-------------------------
    setVisibleFloatingButton(scrolled > 100);
  };
  useLayoutEffect(() => {
    timeout = setTimeout(() => {
      setDefaultPageHeight(false);
    }, 250);
    return () => {
      setDefaultPageHeight(true);
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.onscroll = () => scrollFunction();

  // --------------------Impact Identify function---------------------
  useEffect(() => {
    if (memberPerchesDetails?.EMAIL && memberPerchesDetails?.memberPerchesInfo?.MEMBER?.ID) {
      window.ire("identify", {
        customerId: memberPerchesDetails?.MEMBER?.ID,
        customerEmail: memberPerchesDetails?.EMAIL,
      });
    } else {
      window.ire("identify", { customerId: "", customerEmail: "" });
    }
  }, [pathname, memberPerchesDetails]);

  // ---------------Impact CONVERSION TRACKING function-----------------
  useEffect(() => {
    if (memberPerchesDetails?.EMAIL && memberPerchesDetails?.memberPerchesInfo?.MEMBER?.ID) {
      window.ire(
        "trackConversion",
        40944,
        {
          orderId: memberPerchesDetails?.memberPerchesInfo?.PRODUCT?.[0]?.POLICYNUMBER, // policy no
          customerId: "",
          customerEmail: memberPerchesDetails?.EMAIL, // Customer’s Email
          currencyCode: "USD",
          orderPromoCode: "",
          orderDiscount: "",
          items: [
            {
              subTotal: memberPerchesDetails?.memberPerchesInfo?.TRANSACTION?.AMOUNT, // item amount
              category: memberPerchesDetails?.selectProductDetails?.LABEL, // product name (like OCP, PRO, MAX)
              sku: "",
              quantity: 1, // item quantity
              name: "",
            },
          ],
        },
        {
          verifySiteDefinitionMatch: true,
        }
      );
    }
  }, [memberPerchesDetails]);

  return (
    <div>
      {/* ---------------------------------Header------------------------------------ */}
      <header
        className={`public-layout-header${isScrollTop ? " public-layout-header-scroll-time" : ""} ${
          location.pathname === "/" || location.pathname === `/${agentId}` ? "home-header-glow" : ""
        }`}
      >
        <HeaderContent
          isScrollTop={isScrollTop}
          heaterExtension={heaterExtension}
          setHeaterExtension={setHeaterExtension}
        />
      </header>
      {/* ---------------------------------Body------------------------------------ */}
      <main className="public-layout-body">
        <div style={defaultPageHeight && isFirefoxBrowser ? { height: "9000px" } : {}}>
          {children}
        </div>
      </main>
      {/* ---------------------------------Footer------------------------------------ */}
      <footer>
        <FooterContent visibleFloatingButton={visibleFloatingButton} scrollToTop={scrollToTop} />
      </footer>
    </div>
  );
};

export default PublicLayout;
