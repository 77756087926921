import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
import MetlifeLogo from "../../../../assets/image/product/MetlifeLogo.webp";
import { PRO } from "../../../../constant/PlanName";

const ProviderInformationModalPRO = ({ open, toggle }) => {
  const handleClick = () => {
    window.open(`https://providers.online.metlife.com/findDentist?searchType=findDentistMetLife`, "_blank");
  };
  return (
    <Modal isOpen={open === PRO} toggle={toggle} centered fade={true} className="provider-info-modal modal-pro" aria-label="PRO provider information">
      <ModalHeader className="border-0" toggle={toggle}><span className="opacity-0">a</span></ModalHeader>
      <ModalBody className="provider-info-body-content">
        <Row>
          <Col md="6" className="provider-info-img-content">
            <img src={MetlifeLogo} className="provider-info-img" alt="Metlife Logo" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h1 className="text-black">
              <b>Locate A Provider</b>
            </h1>
            <p className="py-2 text-black">
              With 153 years of experience, the MetLife companies are a leading innovator and a recognized leader in
              protection planning and retirement and savings solutions around the world. We have established a strong
              presence in more than 40 markets globally through organic growth, acquisitions, joint ventures and other
              partnerships. We are strengthening our global brand by extending core products and competencies to markets
              around the world – an important driver of growth for the enterprise.
            </p>
            <div>
              <p>
                <b>To Locate a Dentist in the MetLife PDP Plus Network:</b>
              </p>
              <ol className="mt-3 mb-4 ml-5 space-y-1 text-gray-700 list-decimal ">
                <li>Click on “Find a Dentist” button below</li>
                <li>Choose “PDP Plus” from the “Your Network” drop down list</li>
                <li>Enter your zip code and/or your dentist’s name</li>
              </ol>
            </div>
            <Button
              color="modal-submit"
              className="float-start modal-submit-btn-xs text-uppercase px-4"
              title="Find a dentist"
              onClick={handleClick}
            >
              Find A Dentist
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
export default ProviderInformationModalPRO;
