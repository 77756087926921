export const PAYMENT_TYPE = {
  CREDIT_CARD: "CC",
  ONLINE_CHECK: "ACH",
};

export const MEMBER_LABEL = {
  MEMBER_ONLY: 1,
  MEMBER_SPOUSE: 2,
  MEMBER_CHILDREN: 3,
  WHOLE_FAMILY: 4,
};

export const PRODUCT_LABEL = {
  CLUB_LABEL: "SLEEK Dental Club",
  OCP_LABEL: "SLEEK Dental OCP",
  PRO_LABEL: "SLEEK Dental PRO",
  MAX_LABEL: "SLEEK Dental MAX",
};

export const HEADER_LOGIN_BUTTON_VISIBLE_PAGE = ["/thank-you"];
