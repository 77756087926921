import { IoChevronUpOutline } from "react-icons/io5";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { FaFacebookF, FaInstagram, FaTiktok, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import { IoMailOutline, IoCall } from "react-icons/io5";
import { Link } from "react-router-dom";
import logo from "../../assets/image/logo/SLEEK-BETA.svg";

const FooterContent = ({ visibleFloatingButton, scrollToTop }) => {
  return (
    <>
      <Row className="onboarding-area-lg-footer bg-black mx-0">
        <Col sm="12">
          <Row>
            <Col md="11" sm="12" xs="12" className="m-auto p-0 p-footer">
              <div>
                <Row className="quick-link-content">
                  <Col xl="2" lg="2" md="3" sm="12" xs="12" className="link-my quick-link-img">
                    <img className="img-fluid" src={logo} alt="Logo" />
                  </Col>
                  <Col xl="1" className="d-none d-xl-block"></Col>
                  <Col
                    xl="2"
                    lg="2"
                    md="2"
                    sm="4"
                    xs="12"
                    className="link-my quick-link-menu-holder"
                  >
                    <small className="menu-text-title">Quick Links</small>
                    <div className="menu-text-grp">
                      <Link to="https://sleekdentalclub.com" className="menu-text active">
                        <div>SLEEK Dental Club</div>
                      </Link>
                      <Link to="https://sleekdentalclub.com/blog" className="menu-text">
                        <div>Blog</div>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    lg="2"
                    md="3"
                    sm="4"
                    xs="12"
                    className="link-my quick-link-menu-holder"
                  >
                    <small className="menu-text-title">SLEEK Dental</small>
                    <div className="menu-text-grp">
                      <Link to="https://members.sleekdentalclub.com/" className="menu-text">
                        <div>My Account</div>
                      </Link>
                      <Link
                        to="https://enrollment.sleekdentalclub.com/onboarding"
                        className="menu-text"
                      >
                        <div>Join SLEEK Dental Club</div>
                      </Link>
                      <Link to="https://sleekdentalclub.com/privacy-policy" className="menu-text">
                        <div>Privacy Policy</div>
                      </Link>
                      <Link
                        to="https://sleekdentalclub.com/terms-and-conditions"
                        className="menu-text"
                      >
                        <div>Terms and Conditions</div>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xl="2"
                    lg="3"
                    md="4"
                    sm="4"
                    xs="12"
                    className="link-my quick-link-menu-holder"
                  >
                    <small className="menu-text-title">Marketing Programs</small>
                    <div className="menu-text-grp">
                      <Link
                        to="https://sleekdentalclub.com/Marketing-Programs"
                        className="menu-text"
                      >
                        <div>Dentists</div>
                      </Link>
                      <Link
                        to="https://sleekdentalclub.com/Marketing-Programs"
                        className="menu-text"
                      >
                        <div>Affiliate Marketers</div>
                      </Link>
                      <Link
                        to="https://sleekdentalclub.com/Marketing-Programs"
                        className="menu-text"
                      >
                        <div>Groups / Associations</div>
                      </Link>
                      <Link
                        to="https://sleekdentalclub.com/Marketing-Programs"
                        className="menu-text"
                      >
                        <div>Licensed Agents / Brokers</div>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    xl="3"
                    lg="3"
                    md="12"
                    sm="12"
                    xs="12"
                    className="link-my quick-link-menu-holder"
                  >
                    <small className="menu-text-title">Let’s Connect</small>
                    <div className="menu-text-grp menu-icon-group">
                      <Link to="/" className="menu-text" id="facebook" aria-label="Go to our facebook page">
                        <FaFacebookF />
                        <UncontrolledTooltip placement="top" target="facebook">
                          Facebook
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="instagram" aria-label="Go to our instagram page">
                        <FaInstagram />
                        <UncontrolledTooltip placement="top" target="instagram">
                          Instagram
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="tiktok" aria-label="Go to our tiktok page">
                        <FaTiktok />
                        <UncontrolledTooltip placement="top" target="tiktok">
                          Tiktok
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="youtube" aria-label="Go to our youtube page">
                        <FaYoutube />
                        <UncontrolledTooltip placement="top" target="youtube">
                          Youtube
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="linkedin" aria-label="Go to our linkedin page">
                        <FaLinkedinIn />
                        <UncontrolledTooltip placement="top" target="linkedin">
                          Linkedin
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="email" aria-label="Email us">
                        <IoMailOutline />
                        <UncontrolledTooltip placement="top" target="email">
                          Email
                        </UncontrolledTooltip>
                      </Link>
                      <Link to="/" className="menu-text" id="call" aria-label="Call us">
                        <IoCall />
                        <UncontrolledTooltip placement="top" target="call">
                          Call
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                  </Col>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <hr className="border-info-green" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="11" sm="12" xs="12" className="m-auto p-0 p-footer">
              <div>
                <Row>
                  <Col sm="12">
                    <div className="footer-text">
                      <p>
                        <sup>1</sup>Value added benefits included in all membership levels are not
                        provided by or affiliated with Metropolitan Life Insurance Company.
                      </p>
                      <p>
                        *Per visit, in most instances, on services. Actual costs and savings vary by
                        provider, service, and geographical area.
                      </p>
                      <p>
                        Like most group benefit programs, benefit programs offered by MetLife and
                        its affiliates contain certain exclusions, exceptions, waiting periods,
                        reductions of benefits, limitations and terms for keeping them in force.
                        Please contact Affinity Partners at{" "}
                        <a href="tel:844-753-3532" className="text-info-green text-decoration-none" aria-label="Call Affinity Partners at 844-753-3532">
                          844-753-3532
                        </a>{" "}
                        for complete details.
                      </p>
                      <p>
                        Metropolitan Life Insurance Company | 200 Park Avenue | New York, NY 10166
                        L0223029203[exp0225][All States][DC,GU,MP,PR,VI] © 2023 MSS
                      </p>
                      <p>
                        Dental, Teledentist and Pharmacy Disclosure. This plan is NOT insurance.
                        This plan is not a qualified health plan under the Affordable Care Act
                        (ACA). Some services may be covered by a qualified health plan under the
                        ACA. This plan does not meet the minimum creditable coverage requirements
                        under M.G.L. c. 111M and 956 CMR 5.00. This is not a Medicare prescription
                        drug plan. Discounts on hospital services are not available in Maryland. The
                        plan provides discounts at certain health care providers of medical
                        services. The plan does not make payments directly to the providers of
                        medical services. The plan member is obligated to pay for all health care
                        services but will receive a discount from those health care providers who
                        have contracted with the discount plan organization. The range of discounts
                        will vary depending on the provider type and services provided. The licensed
                        discount plan organization is Coverdell & Company, Inc., at 2850 W. Golf
                        Road, Rolling Meadows, IL 60008,{" "}
                        <a
                          href="tel:1-888-868-6199"
                          className="text-info-green text-decoration-none"
                          aria-label="Call Teledentist at 1-888-868-6199"
                        >
                          1-888-868-6199
                        </a>
                        . To view a list of participating providers visit{" "}
                        <a
                          href="https://www.findbestbenefits.com/"
                          className="text-info-green text-decoration-none"
                          aria-label="Visit www.findbestbenefits.com"
                        >
                          www.findbestbenefits.com
                        </a>{" "}
                        and enter promo code 575313. You have the right to cancel this plan within
                        30 days of the effective date for a full refund of fees paid. Such refunds
                        are issued within 30 days of cancellation.
                      </p>
                      <p>
                        Chiropractic, Hearing, Vision, Nurseline, Vitamin, Online Wellness, Diabetic
                        & Home Medical Supplies Disclosure: This plan is NOT insurance. This is not
                        a qualified health plan under the Affordable Care Act (ACA). Some services
                        may be covered by a qualified health plan under the ACA. The plan provides
                        discounts at certain health care providers of medical services. The plan
                        does not make payments directly to the providers of medical services. The
                        plan member is obligated to pay for all health care services but will
                        receive a discount from those health care providers who have contracted with
                        the discount plan organization. The range of discounts for services will
                        vary depending on the type of provider and services. The licensed discount
                        plan organization is Coverdell & Company, Inc., at Coverdell & Co., Inc.
                        2850 W. Golf Road Rolling Meadows, IL 60008,{" "}
                        <a
                          href="tel:1-866-215-1376"
                          className="text-info-green text-decoration-none"
                          aria-label="Call Coverdell & Company at 1-866-215-1376"
                        >
                          1-866-215-1376
                        </a>
                        . To view a listing of participating providers visit{" "}
                        <a
                          href="https://www.findbestbenefits.com/"
                          className="text-info-green text-decoration-none"
                          aria-label="Visit www.findbestbenefits.com"
                        >
                          www.findbestbenefits.com
                        </a>{" "}
                        and enter promo code 725336. The discount health benefits have been provided
                        at no cost to you and will remain active until you cancel.
                      </p>
                      <p className="mb-0">
                        © Copyright 2024 Affinity Partners | Content, Pricing and Availability
                        Subject to Change.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {visibleFloatingButton ? (
        <Button
          className="scroll-to-top-Button p-1"
          onClick={scrollToTop}
          id="scrollTop"
          aria-label="Scroll to top"
        >
          <IoChevronUpOutline size={25} />
          <UncontrolledTooltip placement="top" target="scrollTop">
            Scroll to top
          </UncontrolledTooltip>
        </Button>
      ) : null}

      {/* <Row className="onboarding-area-sm-footer bg-black pt-0 mx-0">
        <Col sm="12">
          <Row>
            <Col sm="11" className="m-auto p-0">
              <div>
                <Row>
                  <Col sm="12">
                    <div className="text-white footer-text">
                      <div className="text-center copyright-area">
                        <p className="mb-0">
                          © Copyright 2023 Affinity Partners | Content, Pricing and Availability
                          Subject to Change.
                        </p>
                        <p className="mb-0">
                          <a
                            href="https://sleekdentalclub.com/privacy-policy/"
                            className="text-info-green text-decoration-none"
                          >
                            Privacy Policy
                          </a>
                          <span className="mx-1">|</span>
                          <a
                            href="https://sleekdentalclub.com/terms-and-conditions/"
                            className="text-info-green text-decoration-none"
                          >
                            Terms
                          </a>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
};
export default FooterContent;
