import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Row,
} from "reactstrap";
import AnimateContent from "../../components/Animation/AnimateContent";
import { CorrectBulletPoint } from "../../components/Element/BulletPoint";
import { BenefitsKeyPoint } from "../../components/Element/KeyPoint";
import MembershipCard from "../../components/Card/MembershipCard";
import { useState } from "react";
import BlogCard from "../../components/Card/BlogCard";
import { Link, useNavigate } from "react-router-dom";
import {
  membershipData,
  benefitsPoints,
  productBenefitsPoint,
  membershipValueAddedBenefitsData,
  membershipBenefitsAccordionData1,
  membershipBenefitsAccordionData2,
  blogPostData,
} from "./constantData";
import { MAX, OCP, PRO } from "../../constant/PlanName";

export const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="onboarding-content bg-question attach-fix-banner">
      <Row className="justify-content-center h-100">
        <Col lg="12">
          <div className="hero-section">
            <AnimateContent opacity={0} x={"-30px"} animateDuration={0.6} animateDelay={0.3}>
              <h1 className="hero-sec-title text-black">A Dental Experience Worth Smiling About</h1>
              <h2 className="hero-sec-subtitle text-black">
                Streamlined. Cost Effective. Smile Enhancing.
              </h2>
              <Button
                color="primary"
                size="lg"
                className="text-white checkout-next-pre-button"
                id="perfectplan2"
                onClick={() => navigate("onboarding")}
                title="Find the perfect plan"
              >
                <b>FIND THE PERFECT PLAN</b>
              </Button>
            </AnimateContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const ProductPreviewVideo = () => {
  return (
    <div className="onboarding-content product-pre-content bg-transparent bg-black-wave">
      <Row className="justify-content-center align-items-center wave-margin">
        <Col xl="6" lg="6" md="12" sm="12">
          <Row>
            <Col md="12">
              <h3 className="product-pre-title text-white">SLEEK, A Modern Dental Care Solution</h3>
              <h4 className="product-pre-sub-title text-info-green">
                Benefits & Oral Care Together at Last
              </h4>
            </Col>
            <Col md="12" className="my-4">
              <p className="product-pre-para text-white mb-4">
                We provide members with a variety of membership level options to receive savings on
                out-of-pocket costs associated with dental care. Each membership receives a sonic
                electric toothbrush welcome kit with quarterly brush head and floss picks auto
                shipped. OCP, PRO and MAX membership levels also receive perks with value-added
                benefits.
              </p>              
              <div xl="6" lg="6" md="12" sm="12" className="product-pre-frame mbl">
                <iframe
                  title="Product video"
                  src="https://player.vimeo.com/video/756870661?title=0&byline=0&portrait=0&color=ffffff&badge=0&title=0&autoplay=1&muted=1&loop=1"
                  height="100%"
                  width="100%"
                  data-fusion-no-placeholder=""
                  id="player_1"
                  data-ready="true"
                  style={{ width: "100%", height: "417.305px" }}
                />
              </div>
              <p className="product-pre-para text-white mb-0">
                Every SLEEK Membership kit contains:
              </p>
            </Col>
            {productBenefitsPoint.map((item, index) => (
              <Col md="6" key={index}>
                <div className="product-pre-list">
                  <CorrectBulletPoint>{item}</CorrectBulletPoint>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xl="6" lg="6" md="12" sm="12" className="product-pre-frame desktop">
          <iframe
            title="Product video"
            src="https://player.vimeo.com/video/756870661?title=0&byline=0&portrait=0&color=ffffff&badge=0&title=0&autoplay=1&muted=1&loop=1"
            height="100%"
            width="100%"
            data-fusion-no-placeholder=""
            id="player_1"
            data-ready="true"
            style={{ width: "100%", height: "417.305px" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export const SleekMembershipBenefits = () => {
  return (
    <div className="onboarding-content value-member-wrap">
      <Row>
        <Col md="12">
          <h2 className="value-member-title text-info-green">The Value of SLEEK Membership</h2>
          <p className="value-member-para">
            An exclusive membership club with oral care centric benefits.
          </p>
        </Col>
        {benefitsPoints.map(({ icon, title, subTitle }, index) => (
          <Col xl="4" lg="4" md="6" sm="12" key={"Benefits" + index}>
            <BenefitsKeyPoint icon={icon} title={title} subTitle={subTitle} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const Membership = ({ ProviderInfoToggleModal, PlanInfoToggleModal }) => {
  return (
    <div className="onboarding-content value-product-wrap bg-black">
      <Row>
        <Col md="12">
          <h2 className="value-product-title text-white">
            Affordable & Sustainable Dental Care Membership
          </h2>
          <p className="value-product-para">
            A club that puts good oral care in the palm of your hand, literally.
          </p>
        </Col>
        <Col lg="4" md="12" sm="12">
          <MembershipCard
            membershipData={membershipData.ocp}
            HandleOpenPlanInfo={() => PlanInfoToggleModal(OCP)}
            HandleOpenProviderInfo={() => ProviderInfoToggleModal(OCP)}
          />
        </Col>
        <Col lg="4" md="12" sm="12">
          <MembershipCard
            membershipData={membershipData.pro}
            HandleOpenPlanInfo={() => PlanInfoToggleModal(PRO)}
            HandleOpenProviderInfo={() => ProviderInfoToggleModal(PRO)}
          />
        </Col>
        <Col lg="4" md="12" sm="12">
          <MembershipCard
            membershipData={membershipData.max}
            HandleOpenPlanInfo={() => PlanInfoToggleModal(MAX)}
            HandleOpenProviderInfo={() => ProviderInfoToggleModal(MAX)}
          />
        </Col>
      </Row>
    </div>
  );
};

export const MembershipTag = () => {
  return (
    <div className="onboarding-content bg-membership-tag-wave">
      <Row>
        <Col md="12">
          <h3 className="text-center text-white">
            OCP, PRO & MAX Membership Value Added Benefits<sup>1</sup>
          </h3>
        </Col>
      </Row>
    </div>
  );
};

export const MembershipValueAddedBenefits = () => {
  const imageRender = (item) => (
    <div className="value-benefit-img dektop">
      <img src={item.image} alt="Benefits Img" className="img-fluid" />
    </div>
  );
  const contentRender = (item, index) => (
    <div className="value-benefit-text">
      <h2>{item.title}</h2>
      <h3>{item.subTitle1}</h3>
      <p>{item.subTitle2}</p>
      <div className="value-benefit-img mbl">
        <img src={item.image} alt="Benefits Img" className="img-fluid" />
      </div>
      <div className="value-benefit-list">
        {item.BenefitPoint.map((point, index2) => (
          <CorrectBulletPoint key={"benefit-list" + index + index2}>{point}</CorrectBulletPoint>
        ))}
      </div>
      {item.termsLink && (
        <Link target="_blank" to={item.termsLink}>
          Terms, Conditions and Disclosures
        </Link>
      )}
    </div>
  );
  return (
    <div className="value-benefit-wrap">
      {membershipValueAddedBenefitsData.map((item, index) => (
        <Row key={"MembershipValueAddedBenefits" + index} className="value-benefit-row">
          <Col xl="6" lg="6" md="12" sm="12" className="col-first">
            {contentRender(item, index)}
          </Col>
          <Col xl="6" lg="6" md="12" sm="12" className="col-second">
            {imageRender(item)}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export const MembershipBenefitsTag = () => {
  return (
    <div className="onboarding-content bg-black-membership-tag-wave">
      <Row>
        <Col md="12">
          <h3 className="text-center text-white">PRO & MAX Association Membership Benefits</h3>
        </Col>
      </Row>
    </div>
  );
};

export const AssociationMembershipBenefits = () => {
  const [open, setOpen] = useState("one");
  const toggle = (id) => {
    if (open === id) {
      setOpen("one");
    } else {
      setOpen(id);
    }
  };
  return (
    <div className="onboarding-content value-premier-wrap">
      <Row>
        <Col xl="4" lg="4" md="12" sm="12">
          <div className="value-premier-img-content">
            <img
              src="/home-img/Premier-Business-Association-logo.png"
              alt="B4 Logo"
              className="img-fluid"
            />
          </div>
        </Col>
        <Col xl="8" lg="8" md="12" sm="12">
          <div className="value-premier-para-content">
            <p>
              The Premier Business Association (Premier Business)* is a non-profit organization
              serving the goals and needs of small businesses, the self-employed, independent
              contractors, and entrepreneurs from across the nation. Premier Business has been
              dedicated to providing benefits and resources to help its members with professional,
              lifestyle and health-related benefits, as well as advocacy opportunities so they can
              have a voice on issues that may impact their success. Despite changes to the economy
              and the health care landscape that have made an uncertain future, Premier Business is
              focused on assisting its members in any way possible and growing into a leader for the
              independent business person.
            </p>
          </div>
        </Col>
        <Col md="12">
          <div className="premier-question-accordion-wrap">
            <Row>
              <Col md="6" sm="6">
                {membershipBenefitsAccordionData1.map((item, index) => (
                  <Accordion
                    flush
                    key={"Accordion1" + index}
                    open={open}
                    toggle={toggle}
                    className="premier-question-accordion-content"
                  >
                    <AccordionItem key={item.id} className="premier-question-accordion-item">
                      <AccordionHeader targetId={item.id}>
                        <span>{item.title}</span>
                      </AccordionHeader>
                      <AccordionBody accordionId={item.id}>
                        <p>{item.subTitle}</p>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                ))}
              </Col>
              <Col md="6" sm="6">
                {membershipBenefitsAccordionData2.map((item, index) => (
                  <Accordion
                    flush
                    key={"Accordion2" + index}
                    open={open}
                    toggle={toggle}
                    className="premier-question-accordion-content"
                  >
                    <AccordionItem key={item.id} className="premier-question-accordion-item">
                      <AccordionHeader targetId={item.id}>
                        <span>{item.title}</span>
                      </AccordionHeader>
                      <AccordionBody accordionId={item.id}>
                        <p>{item.subTitle}</p>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                ))}
              </Col>
            </Row>
          </div>
        </Col>
        <Col md="12">
          <p className="premier-note">
            *Included in Pro & Max Plans only. PBA benefits are not provided by Metropolitan Life
            Insurance Company.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export const MetLifeSection = () => {
  return (
    <div className="onboarding-content value-met-wrap">
      <Row className="align-items-center">
        <Col xl="3" lg="3" md="12" sm="12">
          <div className="value-met-img-content">
            <img src="/home-img/metlife-logo.png" className="img-fluid" alt="MetLife Logo" />
          </div>
        </Col>
        <Col xl="9" lg="9" md="12" sm="12">
          <div className="value-met-para-content">
            <p>
              With 153 years of experience, the MetLife companies are a leading innovator and a
              recognized leader in protection planning and retirement and savings solutions around
              the world. We have established a strong presence in more than 40 markets globally
              through organic growth, acquisitions, joint ventures and other partnerships. We are
              strengthening our global brand by extending core products and competencies to markets
              around the world – an important driver of growth for the enterprise.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const BlogPost = () => {
  return (
    <div className="onboarding-content value-blog-wrap">
      <Row className="value-blog-content">
        <Col xl="9" lg="" md="6" sm="6" xs="12">
          <p className="value-blog-para">BLOG POSTS</p>
          <h3 className="value-blog-title">Tips and Insights</h3>
        </Col>
        <Col xl="3" lg="" md="6" sm="6" xs="12">
          <Link to={"https://sleekdentalclub.com/blog"} className="btn btn-dark">
            VIEW YOUR BLOG
          </Link>
        </Col>
      </Row>
      <Row>
        {blogPostData.map((item, index) => (
          <Col md="4" key={"blog" + index + 1}>
            <BlogCard data={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const FindPlan = () => {
  const navigate = useNavigate();
  return (
    <div className="onboarding-content below-content">
      <Row className="justify-content-center h-100">
        <Col lg="12">
          <div className="hero-section">
            <AnimateContent opacity={0} x={"-30px"} animateDuration={0.6} animateDelay={0.3}>
              <h1 className="hero-sec-title text-black">A Dental Experience Worth Smiling About</h1>
              <h2 className="hero-sec-subtitle text-info-green">
                Simplified. Affordable. Convenient.
              </h2>
              <Button
                color="primary"
                size="lg"
                className="text-white checkout-next-pre-button"
                id="perfectplan2"
                onClick={() => navigate("onboarding")}
                title="Find the perfect plan"
              >
                <b>FIND THE PERFECT PLAN</b>
              </Button>
            </AnimateContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

// export const HomeQuickLInk = () => {
//   return (
//     <div className="onboarding-content bg-black quick-link-content">

//     </div>
//   );
// };
