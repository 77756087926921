import { Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import {
  FrequentlyAskedQuestions,
  ImagePreview,
  InfoCard1,
  InfoCard2,
  ProductPriceAndDescription,
} from "./Component";
import { getCheckoutData, setCheckoutData } from "../../utils/checkoutHelpers";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../apis/axiosInstance";
import apis from "../../apis";
import { getApiDetails } from "../../utils/localStorageHelpers";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  // const [defaultPageHeight, setDefaultPageHeight] = useState(true);
  const [selectImage, setSelectImage] = useState(0);
  const [qty, setQty] = useState(1);
  const [productDetails, setProductDetails] = useState();
  const items = [
    {
      src: "/product-img/Photo1.jpg",
      key: 1,
      altText: "Product view image 1",
    },
    {
      src: "/product-img/Photo2.jpg",
      key: 2,
      altText: "Product view image 2",
    },
    {
      src: "/product-img/Photo3.jpg",
      key: 3,
      altText: "Product view image 3",
    },
    {
      src: "/product-img/Photo4.jpg",
      key: 4,
      altText: "Product view image 4",
    },
    {
      src: "/product-img/Photo5.jpg",
      key: 5,
      altText: "Product view image 5",
    },
  ];

  const kitIncludes = [
    "Electric Toothbrush with 5 Cleaning Modes",
    "Oscillating Brush Head with 2-Minute Timer",
    "Built-In Rechargeable USB Charger",
    "Toothbrush Holder",
    "Traveling Case",
  ];
  const refillsPerKit = ["(1) Brush Head", "(50) Foss Picks"];

  // useEffect(() => {
  //   setTimeout(() => {
  //     setDefaultPageHeight(false);
  //   }, 200);
  // }, []);
  // ------------------Get Product Details Function-----------------
  const getProductDetails = () => {
    axiosInstance
      .post(apis.checkout.clubProductDetails, {
        prod_id: getApiDetails()?.prod_club?.id,
        period_id: 2,
        qty,
      })
      .then((resp) => {
        setProductDetails(resp);
      });
  };
  useEffect(getProductDetails, [qty]);
  // ------------------------Submit Function------------------------
  const handleSubmit = () => {
    setCheckoutData({
      ...getCheckoutData(),
      selectProductDetails: {
        ...getCheckoutData()?.selectProductDetails,
        clubProductCheckoutItem: {
          product_id: getApiDetails()?.prod_club?.id,
          period_id: 2,
          // benefit_id: productDetails?.[0]?.benefit_id,
          benefit_id: 0,
          is_club: true,
          qty,
          // kit_total: productDetails?.[1]?.price.substr(1),
          // qtr_bill: productDetails?.[0]?.price.substr(1),
        },
      },
    });
    navigate(agentId ? `/${agentId}/checkout-ocp` : "/checkout-ocp");
  };

  return (
    <>
      {/* <div style={defaultPageHeight ? { height: "3500px" } : {}}> */}
      {/* ----------------------------------Product Preview & Description------------------------------ */}
      <div className="onboarding-content product-pre-content">
        <Row className="single-product-view-row">
          <Col xl="5" lg="5" md="5" sm="12">
            <ImagePreview items={items} selectImage={selectImage} setSelectImage={setSelectImage} />
          </Col>
          <Col xl="5" lg="6" md="7" sm="12" className="mt-4 mt-md-0 mx-0 mx-xl-5 mx-lg-3">
            <ProductPriceAndDescription
              kitIncludes={kitIncludes}
              refillsPerKit={refillsPerKit}
              productDetails={productDetails}
              qty={qty}
              setQty={setQty}
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </div>
      {/* --------------------------------------Product Preview Video---------------------------------- */}
      <div className="bg-black onboarding-content product-pre-content">
        <Row className="justify-content-center align-items-center">
          <Col xl="7" lg="7" md="10" sm="10">
            <iframe
              title="Product video"
              src="https://player.vimeo.com/video/756870661?title=0&byline=0&portrait=0&color=ffffff&badge=0&title=0&autoplay=1&muted=1&loop=1"
              height="100%"
              width="100%"
              data-fusion-no-placeholder=""
              id="player_1"
              data-ready="true"
              style={{ width: "100%", height: "417.305px" }}
            />
          </Col>
        </Row>
      </div>
      {/* --------------------------------------Toothbrush Experience---------------------------------- */}
      <div className="bg-info-green onboarding-content py-4 product-green-ribbon">
        <Row>
          <Col sm="12" className="text-center text-white">
            <h1 className="my-2 fw-bold ribbon-text">The Perfect Electric Toothbrush Experience</h1>
          </Col>
        </Row>
      </div>
      <div className="onboarding-content product-pre-content">
        <Row className="product-routine-info-row justify-content-center">
          <Col md="10">
            <InfoCard1 />
            <hr className="pb-4 shadow-hr" />
            <InfoCard2 />
          </Col>
        </Row>
      </div>
      {/* ------------------------------------Frequently Asked Questions-------------------------------- */}
      <div className="onboarding-content bg-question product-pre-content">
        <Row className="justify-content-center">
          <Col md="10">
            <FrequentlyAskedQuestions />
          </Col>
        </Row>
      </div>
      {/* </div> */}
    </>
  );
};
export default ProductDetails;
