import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody, CardTitle, CardText } from "reactstrap";
import {
  FaAddressCard,
  FaClock,
  FaPrint,
  FaUser,
  FaBox,
  FaTruck,
  FaCreditCard,
} from "react-icons/fa";
import { getCheckoutData } from "../../utils/checkoutHelpers";
import { getFormatDate } from "../../utils/DateHelpers";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const ThankYouPage = () => {
  const printRef = useRef();
  const navigate = useNavigate();
  const { agentId } = useParams();
  const CheckoutData = getCheckoutData();
  const memberInfo = CheckoutData?.memberInfo;
  const orderSummaryData = CheckoutData?.orderSummaryData;
  const memberPerchesInfo = CheckoutData?.memberPerchesInfo;
  const isClub = () => CheckoutData?.selectProductDetails?.clubProductCheckoutItem?.is_club;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (!memberPerchesInfo?.SUCCESS) {
      navigate(agentId ? `/${agentId}/onboarding` : "/onboarding");
    }
  }, [navigate, memberPerchesInfo, agentId]);
  return (
    <div className="onboarding-checkout-content" ref={printRef}>
      <div className="confirmation-thanks-container">
        <Row className="m-0">
          <Col lg={12} className="p-0">
            <div className="text-center my-4">
              <h1 className="fw-bold confirmation-thanks-title">Thank You!</h1>
              <h3 className="confirmation-thanks-subtitle d-sm-block d-none">
                Your order is confirmed. Welcome to SLEEK Dental Club!
              </h3>
              <h3 className="confirmation-thanks-subtitle d-sm-none d-block">
                Your order is confirmed.
                <br />
                Welcome to SLEEK Dental Club!
              </h3>
              <p className="confirmation-thanks-paragraph">
                We sent an email to <strong>{memberInfo?.EMAIL} </strong>with your order
                confirmation and receipt. If the email hasn’t arrived within two minutes, please
                check your spam folder to see if the email was routed there.
              </p>
              <div className="mt-5 text-center">
                {/* <Link to="/" className="btn btn-secondary ">
                  Back to Home
                </Link> */}
                <Row className="confirmation-thanks-info-row">
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-info-col"
                  >
                    <Card className="border-0 confirmation-info-card">
                      <CardBody className="pt-0 px-0 p-xl-3">
                        <CardTitle className="info-card-title" tag="h6">
                          <FaAddressCard className="info-card-icon" />
                          <strong>Member Number: </strong>
                          <span className="mx-1">{memberPerchesInfo?.MEMBER?.ID}</span>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-info-col"
                  >
                    <Card className="border-0 confirmation-info-card">
                      <CardBody className="pt-0 px-0 p-xl-3">
                        <CardTitle className="info-card-title" tag="h6">
                          <FaClock className="info-card-icon" />
                          <strong>Time: </strong>
                          <span className="mx-1"> {memberPerchesInfo?.PRODUCT?.[0]?.BILLING}</span>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-info-col hide-on-print"
                  >
                    <Card className="border-0 confirmation-info-card">
                      <CardBody className="pt-0 px-0 p-xl-3">
                        <CardTitle className="info-card-title" tag="h6" onClick={handlePrint}>
                          <FaPrint className="info-card-icon" />
                          <strong>
                            <Link to="#" className="text-black">
                              Print
                            </Link>
                          </strong>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="confirmation-member-info-row">
                  <Col
                    xl={4}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-member-col"
                  >
                    <Card className="confirmation-member-card">
                      <CardBody>
                        <CardTitle className="member-card-title" tag="h4">
                          <FaUser className="member-card-icon" />
                          <span>Member</span>
                        </CardTitle>
                        <CardText className="member-card-text">
                          <h6>
                            <strong>Name: </strong>
                            {memberPerchesInfo?.MEMBER?.NAME}
                          </h6>
                          <h6>
                            <strong>Address: </strong>
                            {memberInfo?.ADDRESS1}
                          </h6>
                          <h6>
                            <strong>Phone: </strong>{" "}
                            {memberInfo?.PHONE1?.replaceAll(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")}
                          </h6>
                          <h6>
                            <strong>Email: </strong> {memberInfo?.EMAIL}
                          </h6>
                          <h6>
                            <strong>Dependents: </strong>
                            {memberInfo?.DEPENDENTS?.map(
                              (item) => `${item?.FIRSTNAME} ${item?.LASTNAME}`
                            ).toString()}
                          </h6>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-member-col "
                  >
                    <Card className="confirmation-member-card">
                      <CardBody>
                        <CardTitle className="member-card-title" tag="h4">
                          <FaBox className="member-card-icon" />
                          <span>Membership</span>
                        </CardTitle>
                        <CardText className="member-card-text">
                          <h6>
                            <strong>
                              SLEEK Dental {orderSummaryData?.product_details?.description}
                            </strong>
                          </h6>
                          <h6>
                            <strong>Policy: </strong>
                            {memberPerchesInfo?.PRODUCT?.[0]?.POLICYNUMBER}
                          </h6>
                          <h6>
                            <strong>Active: </strong>
                            {getFormatDate({
                              date: memberPerchesInfo?.PRODUCT?.[0]?.EFFECTIVE,
                              format: "LL",
                            })}
                          </h6>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col
                    xl={4}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-member-col print-media-page-break"
                  >
                    <Card className="confirmation-member-card ">
                      <CardBody>
                        <CardTitle className="member-card-title" tag="h4">
                          <FaTruck className="member-card-icon" />
                          <span>Shipping</span>
                        </CardTitle>
                        <CardText className="member-card-text">
                          <h6>
                            <strong>{memberPerchesInfo?.MEMBER?.NAME}</strong>
                          </h6>
                          <h6>U.S. Standard</h6>
                          <h6>(normally 4-5 business days,</h6>
                          <h6>unless otherwise noted)</h6>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xl={8}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-member-col"
                  >
                    <Card className="confirmation-member-card border-0 px-0">
                      <CardBody className="py-md-3 py-sm-0 px-0">
                        <CardTitle className="order-card-title" tag="h1">
                          Order List
                        </CardTitle>

                        <CardText className="member-card-text">
                          <h6 className="d-flex align-items-center text-black mb-4">
                            <FaUser className="member-card-icon ml-5" />
                            <strong>
                              x1 SLEEK {orderSummaryData?.product_details?.description} Membership
                            </strong>
                          </h6>
                          {/* <h6 className="d-flex align-items-center text-black mb-4">
                            <FaBox className="member-card-icon ml-5" />
                            <strong>x1 SLEEK Dental Kit</strong>
                          </h6> */}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="confirmation-thanks-member-col"
                  >
                    <Card className="confirmation-member-card">
                      <CardBody>
                        <CardTitle className="member-card-title" tag="h4">
                          <FaCreditCard className="member-card-icon" />
                          <span>Summary</span>
                        </CardTitle>
                        <CardText className="member-card-text">
                          <h6>{orderSummaryData?.membership_details?.[1]?.price} Enrollment</h6>
                          <h6>
                            {orderSummaryData?.membership_details?.[0]?.price} One-Time Membership
                            Fulfillment
                          </h6>
                          <h6>
                            {isClub()
                              ? `${orderSummaryData?.billed_quarter} Product per quarter for Area 3`
                              : `${CheckoutData?.selectProductRate?.rate} Product per Month for Area 3`}
                          </h6>
                          <hr className="member-card-border" />

                          <h6>
                            <strong>Today’s Total </strong>{" "}
                            {isClub() ? (
                              <b>
                                $
                                {
                                  // +orderSummaryData?.billed_quarter?.substr(1) +
                                  (+orderSummaryData?.kit_total_price?.substr(1)).toFixed(2)
                                }
                              </b>
                            ) : (
                              <b>
                                $
                                {(
                                  +orderSummaryData?.membership_details?.[1]?.price?.substr(1) +
                                  +orderSummaryData?.membership_details?.[0]?.price?.substr(1) +
                                  +CheckoutData?.selectProductRate?.rate?.substr(1)
                                ).toFixed(2)}
                              </b>
                            )}
                          </h6>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ThankYouPage;
