import { FieldArray, Form, Formik } from "formik";
import { BsDashCircle } from "react-icons/bs";
import { Button, Card, Col, FormGroup, Row, Table } from "reactstrap";
import InputField from "../../../../components/FormControl/InputField";
import SelectField from "../../../../components/FormControl/SelectField";
import * as yup from "yup";
import { getCheckoutData } from "../../../../utils/checkoutHelpers";
import { MEMBER_LABEL } from "../../../../constant";
import moment from "moment";
import InputDateMask from "../../../../components/FormControl/InputDateMask";
// import InputPhoneMask from "../../../../components/FormControl/InputPhoneMask";
// import InputDatePicker from "../../../../components/FormControl/InputDatePicker";

const DependentInformationForm = ({ handelSubmit }) => {
  const levelValue = getCheckoutData()?.levelValue;
  const { MEMBER_SPOUSE, MEMBER_CHILDREN } = MEMBER_LABEL;

  // ---------------------------------initial Values-----------------------------------
  const initialValues = {
    // RELATIONSHIP: "Spouse",
    // NAME: "",
    // DOB: "",
    // PHONE1: "",
    // GENDER: "",
    FIRSTNAME: "",
    LASTNAME: "",
    DOB: "",
    GENDER: "",
    RELATIONSHIP: "Spouse",
    Dependent: [
      {
        FIRSTNAME: "",
        LASTNAME: "",
        DOB: "",
        GENDER: "",
        RELATIONSHIP: "Child",
      },
    ],
  };
  // --------------------------------Validation Schema----------------------------------
  const SpouseValidation = {
    FIRSTNAME: yup.string().strict(true).trim().required("This is required field"),
    LASTNAME: yup.string().strict(true).trim().required("This is required field"),
    DOB: yup
      .string()
      .required("This is required field")
      .test("DOB", "Invalid date, Please enter valid format (MM/DD/YYYY)", (value) => {
        return moment(value, "MM/DD/YYYY", true).isValid();
      }),
  };
  const ChildrenValidation = {
    Dependent: yup.array().of(
      yup.object().shape({
        FIRSTNAME: yup.string().strict(true).trim().required("This is required field"),
        LASTNAME: yup.string().strict(true).trim().required("This is required field"),
        DOB: yup
          .string()
          .required("This is required field")
          .test("DOB", "Invalid date, Please enter valid format (MM/DD/YYYY)", (value) => {
            return moment(value, "MM/DD/YYYY", true).isValid();
          }),
      })
    ),
  };
  const validationSchema = yup
    .object()
    .shape(
      levelValue === MEMBER_SPOUSE
        ? SpouseValidation
        : levelValue === MEMBER_CHILDREN
        ? ChildrenValidation
        : { ...SpouseValidation, ...ChildrenValidation }
    );
  // ----------------------------------------------------------------------------------

  const genderOption = [
    { title: "Male", value: "M" },
    { title: "Female", value: "F" },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <Row>
              {levelValue !== MEMBER_CHILDREN && (
                <>
                  <Col lg={12}>
                    <div className="h4 text-info-green mb-4">Spouse's Information</div>
                    <hr />
                  </Col>

                  <Col xl={3} lg={6} md={12} sm={6} xs={12} className="spouse-info-col">
                    <FormGroup>
                      <InputField
                        label="First Name"
                        type="text"
                        name="FIRSTNAME"
                        id="FIRSTNAME"
                        placeholder="First Name"
                        aria-label="Spouse's First Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={12} sm={6} xs={12} className="spouse-info-col">
                    <FormGroup>
                      <InputField
                        label="Last Name"
                        type="text"
                        name="LASTNAME"
                        id="LASTNAME"
                        placeholder="Last Name"
                        aria-label="Spouse's Last Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={12} sm={6} xs={12} className="spouse-info-col">
                    <FormGroup>
                      {/* <InputField
                        label="Date of Birth"
                        type="text"
                        name="DOB"
                        id="DOB"
                        placeholder="Date of Birth"
                        aria-label="Spouse's date of birth"
                      /> */}
                      <InputDateMask
                        label="Date of Birth"
                        mask="99/99/9999"
                        name="DOB"
                        id="DOB"
                        placeholder="MM/DD/YYYY"
                        aria-label="Spouse's date of birth"
                      />
                      {/* <InputDatePicker
                        showIcon
                        label="Date of Birth"
                        name="DOB"
                        id="DOB"
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        placeholderText="Date of Birth"
                        aria-label="Spouse's date of birth"
                      /> */}
                    </FormGroup>
                  </Col>
                  {/* <Col xl={3} lg={6} md={12} sm={6} xs={12} className="spouse-info-col">
                    <FormGroup>
                      <InputPhoneMask
                        label="Phone Number"
                        mask="999-999-9999"
                        name="PHONE1"
                        id="PHONE1"
                        placeholder="Phone Number"
                        aria-label="Spouse's phone number"
                      />
                    </FormGroup>
                  </Col> */}
                  <Col xl={3} lg={6} md={12} sm={6} xs={12} className="spouse-info-col">
                    <SelectField
                      label="Gender"
                      name={`GENDER`}
                      id={`GENDER`}
                      options={genderOption}
                      aria-label="Spouse's Gender"
                    />
                  </Col>
                </>
              )}
              {levelValue !== MEMBER_SPOUSE && (
                <>
                  <Col lg={12}>
                    <div className="h4 text-info-green my-4 mt-3">Children Information</div>
                    <hr />
                  </Col>
                  <Col lg={12}>
                    <FieldArray
                      name="Dependent"
                      render={(arrayHelpers) => (
                        <>
                          <div className="table-responsive desktop-child-table-responsive">
                            <Table borderless className="onboarding-checkout-children-table">
                              <thead className="onboarding-checkout-children-table-thead">
                                <tr>
                                  <th></th>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Date of Birth</th>
                                  <th>Gender</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="onboarding-checkout-children-table-tbody">
                                {values.Dependent.length > 0 &&
                                  values.Dependent.map((paramList, index) => {
                                    return (
                                      <tr key={index}>
                                        <td style={{ width: "50px" }} className="px-0">
                                          <Button
                                            color="link"
                                            disabled={!index}
                                            onClick={() => arrayHelpers.remove(index)}
                                            title="Remove child information"
                                          >
                                            <BsDashCircle color="var(--secondary-clr)" />
                                          </Button>
                                        </td>

                                        <td>
                                          <InputField
                                            type="text"
                                            name={`Dependent.${index}.FIRSTNAME`}
                                            id={`Dependent.${index}.FIRSTNAME`}
                                            placeholder="First Name"
                                            aria-label="Children's first name"
                                            style={{ width: "190px" }}
                                          />
                                        </td>
                                        <td>
                                          <InputField
                                            type="text"
                                            name={`Dependent.${index}.LASTNAME`}
                                            id={`Dependent.${index}.LASTNAME`}
                                            placeholder="Last Name"
                                            aria-label="Children's last name"
                                            style={{ width: "190px" }}
                                          />
                                        </td>
                                        <td>
                                          {/* <InputField
                                            type="text"
                                            name={`Dependent.${index}.DOB`}
                                            id={`Dependent.${index}.DOB`}
                                            placeholder="Date of Birth"
                                            aria-label="Children's date of birth"
                                          /> */}
                                          <InputDateMask
                                            mask="99/99/9999"
                                            name={`Dependent.${index}.DOB`}
                                            id={`Dependent.${index}.DOB`}
                                            placeholder="MM/DD/YYYY"
                                            aria-label="Children's date of birth"
                                          />
                                          {/* <InputDatePicker
                                            showIcon
                                            name={`Dependent.${index}.DOB`}
                                            id={`Dependent.${index}.DOB`}
                                            dateFormat="MM/dd/yyyy"
                                            maxDate={new Date()}
                                            placeholderText="Date of Birth"
                                            aria-label="Children's date of birth"
                                          /> */}
                                        </td>
                                        <td>
                                          <SelectField
                                            className="w-auto"
                                            name={`Dependent.${index}.GENDER`}
                                            id={`Dependent.${index}.GENDER`}
                                            options={genderOption}
                                            aria-label="Children's Gender"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </div>

                          {values.Dependent.length > 0 &&
                            values.Dependent.map((paramList, index) => {
                              return (
                                <Card className="border-0 mbl-child-card-responsive" key={index}>
                                  <Row className="align-items-center">
                                    <Col xl={3} lg={12} md={12} sm={12} className="child-input-col">
                                      <FormGroup>
                                        <InputField
                                          type="text"
                                          label="First Name"
                                          name={`Dependent.${index}.FIRSTNAME`}
                                          id={`Dependent.${index}.FIRSTNAME`}
                                          placeholder="First Name"
                                          aria-label="Children's first name"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl={3} lg={12} md={12} sm={12} className="child-input-col">
                                      <FormGroup>
                                        <InputField
                                          type="text"
                                          label="Last Name"
                                          name={`Dependent.${index}.LASTNAME`}
                                          id={`Dependent.${index}.LASTNAME`}
                                          placeholder="Last Name"
                                          aria-label="Children's last name"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl={3} lg={12} md={12} sm={12} className="child-input-col">
                                      <FormGroup>
                                        {/* <InputField
                                          label="Date of Birth"
                                          type="text"
                                          name={`Dependent.${index}.DOB`}
                                          id={`Dependent.${index}.DOB`}
                                          placeholder="Date of Birth"
                                          aria-label="Children's date of birth"
                                        /> */}
                                        <InputDateMask
                                         label="Date of Birth"
                                            mask="99/99/9999"
                                            name={`Dependent.${index}.DOB`}
                                            id={`Dependent.${index}.DOB`}
                                            placeholder="MM/DD/YYYY"
                                            aria-label="Children's date of birth"
                                          />
                                        {/* <InputDatePicker
                                          showIcon
                                          label="Date of Birth"
                                          name={`Dependent.${index}.DOB`}
                                          id={`Dependent.${index}.DOB`}
                                          dateFormat="MM/dd/yyyy"
                                          maxDate={new Date()}
                                          placeholderText="Date of Birth"
                                          aria-label="Children's date of birth"
                                        /> */}
                                      </FormGroup>
                                    </Col>
                                    <Col xl={2} lg={12} md={12} sm={12} className="child-input-col">
                                      <FormGroup>
                                        <SelectField
                                          label="Gender"
                                          name={`Dependent.${index}.GENDER`}
                                          id={`Dependent.${index}.GENDER`}
                                          options={genderOption}
                                          aria-label="Children's Gender"
                                        />
                                      </FormGroup>
                                    </Col>
                                    {index > 0 && (
                                      <Col xl={1} lg={12} className="child-input-col pr-0">
                                        <Button
                                          type="button"
                                          color="danger"
                                          className="my-3 px-4 py-2 bg-white text-danger"
                                          title="Add children"
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          Remove Children
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                  {values.Dependent.length - 1 !== index && <hr />}
                                </Card>
                              );
                            })}

                          <div className="text-end">
                            <Button
                              type="button"
                              color="outline-info-green"
                              className="my-3 px-4 py-2 bg-white"
                              title="Add children"
                              onClick={() =>
                                arrayHelpers.push({
                                  FIRSTNAME: "",
                                  LASTNAME: "",
                                  DOB: "",
                                  GENDER: "",
                                  RELATIONSHIP: "Child",
                                })
                              }
                            >
                              Add Children
                            </Button>
                            <Button
                              type="submit"
                              color="info"
                              className="m-3 px-4 py-2 bg-info-green text-white"
                              title="Continue"
                            >
                              CONTINUE
                            </Button>
                          </div>
                        </>
                      )}
                    />
                  </Col>
                </>
              )}
              {levelValue === MEMBER_SPOUSE && (
                <div className="text-end">
                  <Button
                    type="submit"
                    color="info"
                    className="m-3 px-4 py-2 bg-info-green text-white"
                    title="Continue"
                  >
                    CONTINUE
                  </Button>
                </div>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default DependentInformationForm;
