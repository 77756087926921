import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import InputField from "../../../../components/FormControl/InputField";
import * as yup from "yup";
import SelectField from "../../../../components/FormControl/SelectField";
import { getCheckoutData } from "../../../../utils/checkoutHelpers";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../apis/axiosInstance";
import apis from "../../../../apis";

const ShippingInformationForm = ({ checkoutFormData, handelSubmit }) => {
  const [isApartment, setIsApartment] = useState(false);
  const [zipCode, setZipCode] = useState();
  const [invalidZipCode, setInvalidStateList] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    setIsApartment(checkoutFormData?.PRODUCTS?.[0].SHIPPINGADDRESS1);
    if (checkoutFormData?.PRODUCTS?.[0]) {
      setZipCode(checkoutFormData?.PRODUCTS?.[0]?.SHIPPINGZIPCODE);
    }
  }, [checkoutFormData?.PRODUCTS]);

  const initialValues = {
    SHIPPINGADDRESS: "",
    SHIPPINGADDRESS1: "",
    SHIPPINGCITY: "",
    SHIPPINGSTATE: "",
    SHIPPINGZIPCODE: getCheckoutData()?.zipCode,
    SHIPPINGCOUNTRY: "United States",
  };

  const validationSchema = yup.object().shape({
    SHIPPINGCITY: yup.string().strict(true).trim().required("This is required field"),
    SHIPPINGZIPCODE: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits")
      .required("This is required field"),
    SHIPPINGSTATE: yup.string().strict(true).trim().required("This is required field"),
  });

  // -----------------------------------get State List-----------------------------
  const getStateList = () => {
    setInvalidStateList(false);
    if (
      zipCode?.length === 5 ||
      (getCheckoutData()?.zipCode && (zipCode?.length === 5 || !zipCode))
    ) {
      const selectProductDetails = getCheckoutData()?.selectProductDetails;
      axiosInstance
        .post(apis.checkout.stateList, {
          no_sale_states: selectProductDetails?.NOSALESTATES
            ? selectProductDetails?.NOSALESTATES?.split(",")
            : [""],
          default_no_sale_states: selectProductDetails?.DEFAULTNOSALESTATES
            ? selectProductDetails?.DEFAULTNOSALESTATES?.split(",")
            : [""],
          zip_code: zipCode ? zipCode : getCheckoutData()?.zipCode,
        })
        .then((resp) => {
          if (resp?.state?.length === 0) {
            setInvalidStateList(true);
          }
          setStateList(
            resp?.state?.map(({ state_name, state }) => ({
              title: state_name,
              value: state,
            }))
          );
        });
    }
  };
  useEffect(getStateList, [zipCode]);

  return (
    <>
      <Formik
        initialValues={{ ...initialValues, ...checkoutFormData?.PRODUCTS?.[0] }}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <InputField
                    label="Street Address"
                    type="text"
                    name="SHIPPINGADDRESS"
                    id="SHIPPINGADDRESS"
                    placeholder="Street Address"
                    aria-label="Shipping street address"
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                {isApartment ? (
                  <FormGroup>
                    <InputField
                      label="Apartment, Suite, etc. (optional)"
                      type="text"
                      name="SHIPPINGADDRESS1"
                      id="SHIPPINGADDRESS1"
                      placeholder="Apartment, Suite, etc. (optional)"
                      aria-label="Shipping Apartment, Suite, etc. (optional)"
                    />
                  </FormGroup>
                ) : (
                  <Button
                    color="link"
                    onClick={() => {
                      setIsApartment(true);
                    }}
                    className="text-decoration-none text-info-green fw-bold ps-0"
                  >
                    + Add apartment, suite, etc.
                  </Button>
                )}
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <InputField
                    label="City"
                    type="text"
                    name="SHIPPINGCITY"
                    id="SHIPPINGCITY"
                    placeholder="City"
                    aria-label="Shipping City"
                  />
                </FormGroup>
              </Col>

              <Col lg={4}>
                <FormGroup>
                  <SelectField
                    label="State"
                    name="SHIPPINGSTATE"
                    options={stateList}
                    aria-label="Shipping State"
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label htmlFor="ZIPCODE">ZIP code</Label>
                  <Input
                    invalid={(touched.SHIPPINGZIPCODE && errors.SHIPPINGZIPCODE) || invalidZipCode}
                    type="text"
                    name="SHIPPINGZIPCODE"
                    id="SHIPPINGZIPCODE"
                    maxLength="5"
                    placeholder="ZIP Code"
                    aria-label="Shipping ZIP code"
                    value={values?.SHIPPINGZIPCODE}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                      setFieldValue("SHIPPINGZIPCODE", e.target.value);
                    }}
                  />
                  {touched.SHIPPINGZIPCODE && errors.SHIPPINGZIPCODE ? (
                    <small className="invalid-feedback-zip">{errors.SHIPPINGZIPCODE}</small>
                  ) : invalidZipCode ? (
                    <small className="invalid-feedback-zip">Zip code is Invalid!</small>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg={12} className="text-end">
                <FormGroup className="my-3">
                  <Button
                    color="info"
                    type="submit"
                    className="px-4 py-2 bg-info-green text-white"
                    title="Continue"
                  >
                    CONTINUE
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ShippingInformationForm;
