import React, { Fragment } from "react";
import { useField } from "formik";
import { FormFeedback, Label, Input } from "reactstrap";
export default function SelectField({ label, ...props }) {
  const [field, meta] = useField(props);
  const { options, name, defaultoption } = props;
  return (
    <Fragment>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input type="select" id={name} {...field} {...props} invalid={meta.touched && meta.error}>
        <option value="">{defaultoption}</option>
        {options.map(({ title, value }, index) => {
          return (
            <option key={`${name}_${value}`} value={value}>
              {title}
            </option>
          );
        })}
      </Input>
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </Fragment>
  );
}
SelectField.defaultProps = {
  options: [],
  defaultoption: "Select",
};
