import { Collapse } from "reactstrap";
import BlurAnimateContent from "../../components/Animation/BlurAnimateContent";
import { motion } from "framer-motion";
import { Link, useLocation, useParams } from "react-router-dom";
// import logo from "../../assets/image/logo/SleekDental-Logo-200x50.webp";
import logo from "../../assets/image/logo/SLEEK-BETA.svg";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { HEADER_LOGIN_BUTTON_VISIBLE_PAGE } from "../../constant";
import { FaUser } from "react-icons/fa";

const HeaderContent = ({ isScrollTop, heaterExtension, setHeaterExtension }) => {
  const location = useLocation();
  const { agentId } = useParams();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <BlurAnimateContent>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="https://sleekdentalclub.com" className="outline-0 text-decoration-none">
              <img
                className={`header-logo${isScrollTop ? " header-logo-scroll-time" : ""}`}
                src={logo}
                alt="Logo"
              />
              {/* for BETA version START */}
              {/* <sub className={`beta-sup${isScrollTop ? " beta-sup-scroll-time" : ""}`}>Beta</sub> */}
              {/* for BETA version END */}
            </Link>
          </motion.div>
        </BlurAnimateContent>
        <div
          className="d-sm-block d-md-none nav-collapse-menu-icon"
          onClick={() => setHeaterExtension((pre) => !pre)}
        >
          {heaterExtension ? (
            <RxCross2 color="white" size={isScrollTop ? 20 : 25} />
          ) : (
            <RxHamburgerMenu color="white" size={isScrollTop ? 20 : 25} />
          )}
        </div>
        {(location.pathname === "/" || location.pathname === `/${agentId}`) && (
          <Link
            to="https://members.sleekdentalclub.com/index.cfm?id=649168"
            className={`btn btn-vav-login ${
              isScrollTop && "btn-vav-login-scroll-time"
            } border-0 justify-content-center bg-black`}
          >
            My Account
          </Link>
        )}
        {HEADER_LOGIN_BUTTON_VISIBLE_PAGE.includes(location.pathname) && (
          <Link
            to="https://members.sleekdentalclub.com"
            className={`btn btn-vav-login ${
              isScrollTop && "btn-vav-login-scroll-time"
            } btn-labeled`}
          >
            <span className="btn-label">
              <i>
                <FaUser />
              </i>
            </span>
            MEMBER LOGIN
          </Link>
        )}
      </div>
      <Collapse isOpen={heaterExtension}>
        <div className="text-center mt-4 mb-2 text-white d-sm-block d-md-none">
          <span>
            Give us a call to book your tuition!{" "}
            <a className="text-white text-decoration-none" href="tel:tel:1800555555">
              1-800-555-555
            </a>
          </span>
        </div>
        <div className="text-center mt-3 text-white d-sm-block d-md-none">
          <span>
            <Link className="text-white text-decoration-none border p-2" href="#">
              My Account
            </Link>
          </span>
        </div>
        {HEADER_LOGIN_BUTTON_VISIBLE_PAGE.includes(location.pathname) && (
          <div className="text-center mt-3 text-white d-sm-block d-md-none">
            <span>
              <a
                className="text-white text-decoration-none border p-2"
                href="https://members.sleekdentalclub.com"
              >
                MEMBER LOGIN
              </a>
            </span>
          </div>
        )}
      </Collapse>
    </>
  );
};
export default HeaderContent;
