import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Table,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import { PRO } from "../../../../constant/PlanName";
import { TableLabel } from "../Component";
import { BsCheckLg, BsFillInfoCircleFill, BsXLg } from "react-icons/bs";
import { MEMBER_LABEL } from "../../../../constant";
import { getCheckoutData } from "../../../../utils/checkoutHelpers";

const OffCanvasPlanInformationPRO = ({
  isOpen,
  togglePlanDetailsOffCanvas,
  togglePlanInformationOffCanvas,
  handleSubmit,
}) => {
  const levelValue = getCheckoutData()?.levelValue;
  const { MEMBER_SPOUSE, MEMBER_CHILDREN, MEMBER_ONLY } = MEMBER_LABEL;

  return (
    <div>
      <Offcanvas
        toggle={togglePlanInformationOffCanvas}
        isOpen={isOpen === PRO}
        direction="end"
        className="off-canvas-pro"
      >
        <OffcanvasHeader toggle={togglePlanInformationOffCanvas}></OffcanvasHeader>
        <OffcanvasBody className="off-canvas-text-normal">
          <h1 className="text-info-green fw-bolder">SLEEK Dental Club PRO</h1>
          <div className="table-responsive">
            <Table>
              {/* -----------------------Table Body---------------------- */}
              <tbody className="align-middle onboarding-compare-table-tbody">
                <tr>
                  <th scope="row" className="h4 py-3 left-title text-black">
                    Coverage Type
                  </th>

                  <td className="text-center py-3">In-Network*/Out of Network**</td>
                </tr>

                <TableLabel name="Insurance Reimbursement" />

                <tr>
                  <td className="py-3">
                    Type A: Preventative
                    <BsFillInfoCircleFill
                      className="mx-1 text-info-green outline-0"
                      id="preventative"
                    />
                    <UncontrolledTooltip placement="right" target="preventative">
                      <div className="my-1">(Cleanings, Exams, X-rays)</div>
                      <div className="my-1">No Waiting Period</div>
                    </UncontrolledTooltip>
                  </td>

                  <td className="text-center py-3">80%</td>
                </tr>

                <tr>
                  <td className="py-3">
                    Type B: Basic Restorative
                    <BsFillInfoCircleFill
                      className="mx-1 text-info-green outline-0"
                      id="basicRestorative"
                    />
                    <UncontrolledTooltip placement="right" target="basicRestorative">
                      <div className="my-1">(Fillings, Extractions) </div>
                      <div className="my-1">No Waiting Period</div>
                    </UncontrolledTooltip>
                  </td>

                  <td className="text-center py-3">60%</td>
                </tr>

                <tr>
                  <td className="py-3">
                    Type C: Major Restorative
                    <BsFillInfoCircleFill
                      className="mx-1 text-info-green outline-0"
                      id="majorRestorativeLabel"
                    />
                    <UncontrolledTooltip placement="right" target="majorRestorativeLabel">
                      <div>No Waiting Period on MAX</div>
                      <div className="mt-2">
                        PRO Benefit Waiting Period: Benefits are payable after a 12-month waiting
                        period from the start date of an individual’s benefits.
                      </div>
                    </UncontrolledTooltip>
                  </td>

                  <td className="text-center py-3">
                    50%
                    <BsFillInfoCircleFill
                      className="mx-1 text-info-green outline-0"
                      id="majorRestorative"
                    />
                    <UncontrolledTooltip placement="right" target="majorRestorative">
                      *Benefits are payable after 12 month waiting period from the start date of an
                      individual's benefits
                    </UncontrolledTooltip>
                  </td>
                </tr>

                <TableLabel
                  name="Type D: Orthodontia"
                  id="valueAddedBenefits"
                  tooltipData={
                    <div>
                      <div className="my-2">
                        (Orthodontic Diagnostics and Orthodontic Treatment)
                      </div>
                      <div className="my-2">No Waiting Period</div>
                      <div className="my-2">Children Only Coverage to Age 19</div>
                    </div>
                  }
                />

                <tr>
                  <td className="py-3">Reimbursement</td>

                  <td className="text-center py-3">
                    <BsXLg className="text-secondary" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">Lifetime Maximum</td>

                  <td className="text-center py-3">
                    <BsXLg className="text-secondary" />
                  </td>
                </tr>

                <TableLabel
                  name="Deductible (Calendar Year)"
                  id="insuranceDeductible"
                  tooltipData="Applies to Basic & Major Services"
                />

                <tr>
                  <td className="py-3">
                    {levelValue === MEMBER_ONLY
                      ? "Member"
                      : levelValue === MEMBER_SPOUSE
                      ? "Member + Spouse"
                      : levelValue === MEMBER_CHILDREN
                      ? "Member + Children"
                      : "Family"}
                  </td>

                  <td className="text-center py-3">
                    {levelValue === MEMBER_ONLY
                      ? "$100"
                      : levelValue === MEMBER_SPOUSE
                      ? "$200"
                      : levelValue === MEMBER_CHILDREN
                      ? "$200"
                      : "$300"}
                  </td>
                </tr>

                <TableLabel
                  name="Annual Maximum Benefit (Through Year 3)"
                  id="insuranceMaximumPayout"
                  tooltipData="Increasing Annual Max Applies to Preventative, Basic & Major Services."
                />

                <tr>
                  <td className="py-3">Year 1</td>

                  <td className="text-center py-3">$1,000</td>
                </tr>

                <tr>
                  <td className="py-3">Year 2</td>

                  <td className="text-center py-3">$1,250</td>
                </tr>

                <tr>
                  <td className="py-3">Year 3+</td>
                  <td className="text-center py-3">$1,500</td>
                </tr>

                <TableLabel name="Provider Networks" id="providerNetworks" />

                <tr>
                  <td className="py-3">Dental Network</td>
                  <td className="text-center py-3">MetLife PDP Plus</td>
                </tr>

                <tr>
                  <td className="py-3">Pharmacy Network</td>
                  <td className="text-center py-3">OptumRx®</td>
                </tr>

                <tr>
                  <td className="text-center py-3 plan-details-btn-container" colSpan={2}>
                    <Button
                      className="bg-black py-2 px-4 plan-details-btn w-100"
                      onClick={() => togglePlanDetailsOffCanvas("PRO")}
                      title="LIMITATIONS & EXCLUSIONS for PRO"
                    >
                      LIMITATIONS & EXCLUSIONS
                    </Button>
                  </td>
                </tr>

                <TableLabel
                  name="Additional Membership Benefits"
                  id="additionalMembershipBenefits"
                />

                <tr>
                  <td className="py-3">
                    SLEEK Electric Toothbrush Kit<sup>1</sup>
                  </td>

                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">
                    Quarterly Oral Care Refills<sup>1</sup>
                  </td>
                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">
                    Discount Rx Benefits<sup>1</sup>
                  </td>
                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">Association Vision Care Discounts</td>

                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">Association Chiropractic Discounts</td>
                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">Association Hearing Discounts</td>
                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>

                <tr>
                  <td className="py-3">Association Lifestyle Benefits</td>
                  <td className="text-center py-3">
                    <BsCheckLg className="text-info-green" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Button
            color="info"
            size="lg"
            className="text-white w-100 py-3 bg-info-green enroll-btn mb-5 mt-3"
            onClick={() => handleSubmit(PRO)}
            title="Enroll now for OCP"
          >
            <b>ENROLL NOW</b>
          </Button>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default OffCanvasPlanInformationPRO;
