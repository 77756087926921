import { AnimatePresence } from "framer-motion";
// import { BsCheckLg, BsFillInfoCircleFill } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { Button, Col, Input, Row } from "reactstrap";
import AnimateContent from "../../../components/Animation/AnimateContent";
import BlurAnimateContent from "../../../components/Animation/BlurAnimateContent";

// const RenderStatus = ({ type }) => {
//   if (type === "loading") {
//     return (
//       <Spinner size="sm" className="text-info-green mt-1">
//         Loading
//       </Spinner>
//     );
//   } else if (type === "failed") {
//     return <BsFillInfoCircleFill className="text-warning mt-1" />;
//   } else {
//     return <BsCheckLg className="text-info-green mt-1" />;
//   }
// };

const FormUI = ({ inputFeedback, fieldData, checkZip, checkArea, checkMessage, handleZipInput, handleSubmit }) => {
  return (
    <Row id="step2">
      <Col lg="12">
        <AnimateContent scale={0}>
          <div className="text-center joining-title-center onboarding-title-2 text-black joining-adjust join-mb-md mt-0">
            <h1>
              <b className="plans-text-desktop">Let’s Find The Best Plans In Your Area</b>
              <b className="plans-text-mbl">Let’s Find The Best<br/>Plans In Your Area</b>
            </h1>
          </div>
        </AnimateContent>
      </Col>
      {/* ---------------------------------------Zip Code Field--------------------------------*/}
      <Col lg="12">
        <AnimateContent scale={0}>
          <Row className="d-flex justify-content-center">
            <Col xl="5" lg="5" md="12" sm="8" xs="8">
              <div className={`input-group  group-${inputFeedback}`}>
                <span className="input-group-text bg-transparent border-right-0 grey-icon">
                  <GrLocation />
                </span>
                <Input
                  valid={inputFeedback === "valid"}
                  invalid={inputFeedback === "invalid"}
                  type="number"
                  maxLength={5}
                  name="user"
                  placeholder="Zipcode"
                  className=" border-left-0"
                  onChange={handleZipInput}
                  value={fieldData}
                  aria-label="Enter your zipcode"
                />
              </div>
              <AnimatePresence>
                {inputFeedback === "invalid" && (
                  <BlurAnimateContent exit={{ opacity: 0 }}>
                    <small className="text-danger checking-msg">Zip code must be 5 digit</small>
                  </BlurAnimateContent>
                )}
              </AnimatePresence>
            </Col>
          </Row>
        </AnimateContent>
      </Col>
      {/* ---------------------------------------Checking Info--------------------------------*/}
      {/* <Col lg="12" className="d-flex justify-content-center mt-4 mb-2 fw-bold checking-info-col">
        <div>
          <AnimatePresence>
            {checkZip && (
              <BlurAnimateContent exit={{ opacity: 0 }}>
                <div className="d-flex justify-content-start mt-2 checking-info">
                  <RenderStatus type={checkZip} />
                  <span className="mx-2">Check Zip Code</span>
                </div>
              </BlurAnimateContent>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {checkArea && (
              <BlurAnimateContent animateDelay={0.8} exit={{ opacity: 0 }}>
                <div className="d-flex justify-content-start mt-2 checking-info">
                  <RenderStatus type={checkArea} />
                  <span className="mx-2">Zip Code Against Area Find</span>
                </div>
              </BlurAnimateContent>
            )}
          </AnimatePresence>
        </div>
      </Col> */}
      <Col>
        <AnimatePresence>
          {checkMessage && (
            <BlurAnimateContent animateDelay={0.2} exit={{ opacity: 0 }}>
              <div className="d-flex justify-content-center text-danger mt-2 checking-msg">{checkMessage}</div>
            </BlurAnimateContent>
          )}
        </AnimatePresence>
      </Col>
      {/* ---------------------------------------Button--------------------------------*/}
      <Col lg="12">
        <div className="d-flex justify-content-center">
          {/* <AnimatePresence>
            {(inputFeedback === "valid" && !checkArea) || (fieldData?.length === 5 && !checkArea) ? (
              <AnimateContent scale={0} exit={{ scale: 0 }}> */}
          <Button
            color="primary"
            size="lg"
            className="text-white checkout-next-pre-button pre-btn-mt"
            disabled={!(inputFeedback === "valid" && !checkArea) && !(fieldData?.length === 5 && !checkArea)}
            onClick={handleSubmit}
            title="View plan"
            aria-label="View plan"
          >
            {/* {checkArea === "loading" && <Spinner>Loading...</Spinner>}VIEW PLAN */}
            VIEW PLAN
          </Button>
          {/* </AnimateContent>
            ) : null}
          </AnimatePresence> */}
        </div>
      </Col>
    </Row>
  );
};

export default FormUI;
