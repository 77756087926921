import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Row } from "reactstrap";
import InputField from "../../../../components/FormControl/InputField";
import SelectField from "../../../../components/FormControl/SelectField";
import * as yup from "yup";
import InputPhoneMask from "../../../../components/FormControl/InputPhoneMask";
// import InputDatePicker from "../../../../components/FormControl/InputDatePicker";
import moment from "moment";
import InputDateMask from "../../../../components/FormControl/InputDateMask";

const MemberInformationForm = ({ handelSubmit }) => {
  const initialValues = {
    FIRSTNAME: "",
    LASTNAME: "",
    DOB: "",
    GENDER: "",
    PHONE1: "",
    EMAIL: "",
    // CITY: "",
    // STATE: "",
  };
  const validationSchema = yup.object().shape({
    FIRSTNAME: yup.string().strict(true).trim().required("This is required field"),
    LASTNAME: yup.string().strict(true).trim().required("This is required field"),
    DOB: yup
      .string()
      .required("This is required field")
      .test("DOB", "Invalid date, Please enter valid format (MM/DD/YYYY)", (value) => {
        return moment(value, "MM/DD/YYYY", true).isValid();
      }),
    GENDER: yup.string().required("This is required field"),
    PHONE1: yup.string().required("This is required field"),
    EMAIL: yup.string().email("Email must be a valid email").required("This is required field"),
    // STATE:yup.string().strict(true).trim().required("This is required field"),
  });

  const genderOption = [
    { title: "Male", value: "M" },
    { title: "Female", value: "F" },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <InputField
                    label="First Name"
                    type="text"
                    name="FIRSTNAME"
                    id="FIRSTNAME"
                    placeholder="First Name"
                    aria-label="Member's first name"
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <InputField
                    label="Last Name"
                    type="text"
                    name="LASTNAME"
                    id="LASTNAME"
                    placeholder="Last Name"
                    aria-label="Member's last name"
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  {/* <InputField
                    label="Date of Birth"
                    type="text"
                    name="DOB"
                    id="DOB"
                    placeholder="Date of Birth"
                    aria-label="Member's date of birth"
                  /> */}
                  <InputDateMask
                    label="Date of Birth"
                    mask="99/99/9999"
                    name="DOB"
                    id="DOB"
                    placeholder="MM/DD/YYYY"
                    aria-label="Member's date of birth"
                  />
                  {/* <InputDatePicker
                    showIcon
                    label="Date of Birth"
                    name="DOB"
                    id="DOB"
                    open={false}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    placeholderText="Date of Birth"
                    aria-label="Member's date of birth"
                  /> */}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <SelectField
                    label="Gender"
                    name="GENDER"
                    options={genderOption}
                    aria-label="Member's gender"
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <InputPhoneMask
                    label="Phone Number"
                    mask="999-999-9999"
                    name="PHONE1"
                    id="PHONE1"
                    placeholder="Phone Number"
                    aria-label="Member's phone number"
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <InputField
                    label="Email"
                    type="text"
                    name="EMAIL"
                    id="EMAIL"
                    placeholder="Email"
                    aria-label="Member's email"
                  />
                </FormGroup>
              </Col>
              {/* <Col lg={6}>
                <FormGroup>
                  <InputField
                    label="City"
                    type="text"
                    name="CITY"
                    id="CITY"
                    placeholder="City"
                    aria-label="Member's City"
                  />
                </FormGroup>
              </Col> */}
              {/* <Col lg={6}>
                <FormGroup>
                  <SelectField label="State" name="STATE" options={stateList} aria-label="Member's State" />
                </FormGroup>
              </Col> */}
              <Col lg={12} className="text-end">
                <FormGroup className="my-3">
                  <Button
                    color="info"
                    type="submit"
                    className="px-4 py-2 bg-info-green text-white"
                    title="Continue"
                  >
                    CONTINUE
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default MemberInformationForm;
