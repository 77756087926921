import { useCallback, useContext, useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row } from "reactstrap";
import AnimateContent from "../../../components/Animation/BlurAnimateContent";
import PositionAnimateContent from "../../../components/Animation/AnimateContent";
import AuthorizationForm from "./Form/AuthorizationForm";
import BillingInformationForm from "./Form/BillingInformationForm";
import DependentInformationForm from "./Form/DependentInformationForm";
import MemberInformationForm from "./Form/MemberInformationForm";
import PaymentForm from "./Form/PaymentForm";
import ShippingInformationForm from "./Form/ShippingInformationForm";
import OrderSummaryCard from "./OrderSummaryCard";
import { getCheckoutData, setCheckoutData } from "../../../utils/checkoutHelpers";
import { useNavigate, useParams } from "react-router-dom";
import apis from "../../../apis";
import axiosInstance from "../../../apis/axiosInstance";
import { getFormatDate } from "../../../utils/DateHelpers";
import { MEMBER_LABEL } from "../../../constant";
import { GlobalData } from "../../../context/context";
import { getApiDetails } from "../../../utils/localStorageHelpers";

const Step4 = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const { setMemberPerchesDetails } = useContext(GlobalData);
  const levelValue = getCheckoutData()?.levelValue;
  const { MEMBER_SPOUSE, MEMBER_CHILDREN, MEMBER_ONLY } = MEMBER_LABEL;
  const isClub = () => getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.is_club;

  const [isDefaultAgent, setIsDefaultAgent] = useState(false);
  const [open, setOpen] = useState("");
  const [orderSummaryData, setOrderSummaryData] = useState();
  const [memberPerchesInfo, setMemberPerchesInfo] = useState();
  const [billingZipCode, setBillingZipCode] = useState();
  const [signatureUUID, setSignatureUUID] = useState();
  const [checkoutFormData, setCheckoutFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);
  const [policyDetails, setPolicyDetails] = useState();
  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false);
  const [productQty, setProductQty] = useState(
    getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.qty
  );
  const [orderDetails, setOrderDetails] = useState();

  const toggle = (name) => {
    if (open === name) {
      setOpen();
    } else {
      setOpen(name);
    }
  };

  useEffect(() => {
    if (!getCheckoutData()?.selectProductDetails) {
      navigate(agentId ? `/${agentId}/onboarding` : "/onboarding");
    }
    setTimeout(() => {
      setOpen("memberInformationTab");
    }, 1000);
  }, [navigate, agentId]);

  // ---------------------------Get Agent List Data--------------------------
  const getAgentListData = () => {
    if (agentId) {
      axiosInstance.get(apis.agent.list(getApiDetails()?.agentId)).then((resp) => {
        if (resp) {
          const agentArr = resp?.map((item) => item?.BROKER_ID);
          console.log({
            agentArr: agentArr,
            agentId2: +agentId,
            isInclude: agentArr?.includes(+agentId),
          });
          setIsDefaultAgent(!agentArr?.includes(+agentId));
        }
      });
    }
  };
  useEffect(getAgentListData, [agentId]);
  // -------------------------------------------------------------------

  // -----------------------------------Get Order Summary-----------------------------
  const getOrderSummary = () => {
    const selectProductRate = getCheckoutData()?.selectProductRate;
    const selectProductDetails = getCheckoutData()?.selectProductDetails;

    // ocp/pro/max product checkout payload
    const otherProduct = {
      benefit_id: selectProductRate?.benefit_id,
      product_id: selectProductRate?.plan_details?.id,
      period_id: 7,
    };

    // Club product checkout payload
    const clubProduct = { ...selectProductDetails?.clubProductCheckoutItem, qty: productQty };

    // set product Qty in Local Storage
    setCheckoutData({
      ...getCheckoutData(),
      selectProductDetails: {
        ...getCheckoutData()?.selectProductDetails,
        clubProductCheckoutItem: clubProduct,
      },
    });

    // checkout details api call
    axiosInstance
      .post(apis.checkout.checkoutDetails, isClub() ? clubProduct : otherProduct)
      .then((resp) => {
        setOrderSummaryData(resp);
      });
  };
  useEffect(getOrderSummary, [productQty]);

  // ---------------------------------get Policy Details----------------------------
  const getPolicyDetails = () => {
    if (orderSummaryData?.product_id) {
      axiosInstance.get(apis.checkout.policyDetails(orderSummaryData?.product_id)).then((resp) => {
        setPolicyDetails(resp);
      });
    }
  };
  useEffect(getPolicyDetails, [orderSummaryData]);
  // ---------------------------------store Order Details----------------------------
  const orderDetailsStore = useCallback(
    (orderData) => {
      axiosInstance.post(apis.checkout.orderDetailsStore, orderData).then((resp) => {
        if (resp?.status) {
          navigate(agentId ? `/${agentId}/thank-you` : "/thank-you");
        }
      });
    },
    [agentId, navigate]
  );

  // -------------------------------Get Signature Status--------------------------
  const getSignatureStatus = () => {
    if (memberPerchesInfo) {
      setIsSignatureLoading(true);
      axiosInstance
        .post(apis.checkout.signatureStatus, {
          enc_key: btoa(`${getApiDetails()?.username}:${getApiDetails()?.password}`),
          agent_id: agentId && !isDefaultAgent ? agentId : getApiDetails()?.agentId,
          member_id: memberPerchesInfo?.MEMBER?.ID,
          pd_id: memberPerchesInfo?.PRODUCT?.[0]?.PDID,
          policy_number: memberPerchesInfo?.PRODUCT?.[0]?.POLICYNUMBER,
        })
        .then((resp) => {
          if (resp.SUCCESS) {
            const { A, EXISTS } = resp.SIGNATUREEXISTS;
            setIsSignatureLoading(false);
            if (EXISTS) {
              setCheckoutData({
                ...getCheckoutData(),
                memberInfo: checkoutFormData,
                orderSummaryData,
              });
              orderDetailsStore(orderDetails);
              // navigate(agentId ? `/${agentId}/thank-you` : "/thank-you");
            } else {
              setSignatureUUID(A);
            }
          }
        });
    }
  };
  useEffect(getSignatureStatus, [
    memberPerchesInfo,
    checkoutFormData,
    navigate,
    orderSummaryData,
    agentId,
    isDefaultAgent,
    orderDetailsStore,
    orderDetails,
  ]);

  // ---------------------------Member Information Form Submit----------------------
  const memberInformationFormSubmit = (submitData) => {
    setCheckoutFormData((preData) => ({
      ...preData,
      ...submitData,
      DOB: getFormatDate({ date: submitData.DOB }),
      CORPID: getApiDetails()?.cropId,
      AGENT: agentId && !isDefaultAgent ? agentId : getApiDetails()?.agentId,
      FIRSTNAME: submitData?.FIRSTNAME?.split(" ")[0],
      MIDDLENAME: submitData?.FIRSTNAME?.split(" ")[1],
      ZIPCODE: getCheckoutData()?.zipCode,
      PHONE1: submitData?.PHONE1?.replaceAll("-", ""),
      PHONE2: "",
    }));
    console.log("Member Information", submitData);
    if (!levelValue || levelValue === MEMBER_ONLY || isClub()) {
      toggle("billingInformationTab");
    } else {
      toggle("dependentInformationTab");
    }
  };

  // ---------------------------Dependent Information Form Submit----------------------
  const dependentInformationFormSubmit = (submitData) => {
    const { Dependent, ...others } = submitData;
    const { CITY, STATE } = checkoutFormData;
    // Set Spouse Data
    const ArrangeSpouseData = {
      ...others,
      CITY,
      STATE,
      ZIPCODE: getCheckoutData()?.zipCode,
      DOB: getFormatDate({ date: others.DOB }),
      // PHONE1: submitData?.PHONE1?.replaceAll("-", ""),
    };

    // Set Children Data
    const ArrangeChildrenData = Dependent?.map((item) => ({
      ...item,
      CITY,
      STATE,
      ZIPCODE: getCheckoutData()?.zipCode,
      DOB: getFormatDate({ date: item.DOB }),
    }));
    // // Name Split
    // const nameSplit = others?.NAME?.split(" ");
    // const FIRSTNAME = nameSplit[0];
    // const LASTNAME = nameSplit[1];

    // // condition base on level Value
    // if (levelValue !== MEMBER_ONLY) {
    //   if (levelValue === MEMBER_SPOUSE) {
    //     setCheckoutFormData((preData) => ({
    //       ...preData,
    //       BENEFICIARY: ArrangeSpouseData,
    //       DEPENDENTS: [{ ...ArrangeSpouseData, FIRSTNAME, LASTNAME }],
    //     }));
    //   } else if (levelValue === MEMBER_CHILDREN) {
    //     setCheckoutFormData((preData) => ({ ...preData, DEPENDENTS: ArrangeChildrenData }));
    //   } else {
    //     setCheckoutFormData((preData) => ({
    //       ...preData,
    //       BENEFICIARY: ArrangeSpouseData,
    //       DEPENDENTS: [{ ...ArrangeSpouseData, FIRSTNAME, LASTNAME }, ...ArrangeChildrenData],
    //     }));
    //   }
    // }
    if (levelValue && levelValue !== MEMBER_ONLY) {
      if (levelValue === MEMBER_SPOUSE) {
        setCheckoutFormData((preData) => ({
          ...preData,
          BENEFICIARY: "",
          DEPENDENTS: [ArrangeSpouseData],
        }));
      } else if (levelValue === MEMBER_CHILDREN) {
        setCheckoutFormData((preData) => ({
          ...preData,
          DEPENDENTS: ArrangeChildrenData,
        }));
      } else {
        setCheckoutFormData((preData) => ({
          ...preData,
          BENEFICIARY: "",
          DEPENDENTS: [ArrangeSpouseData, ...ArrangeChildrenData],
        }));
      }
    }
    toggle("billingInformationTab");
  };

  // -------------------------Billing Information Form Submit---------------------
  const billingInformationFormSubmit = (submitData) => {
    const { ADDRESS1, ADDRESS2, CITY, STATE, ZIPCODE, is_billing_shipping_same } = submitData;
    // this object when billing and shipping address same
    const productWithBillingInfo = {
      PDID: orderSummaryData?.product_id,
      PERIODID: getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.period_id ? 2 : 1,
      BENEFITID: orderSummaryData?.benefit_id,
      SHIPPINGADDRESS: ADDRESS1,
      SHIPPINGADDRESS1: ADDRESS2,
      SHIPPINGCITY: CITY,
      SHIPPINGSTATE: STATE,
      SHIPPINGZIPCODE: ZIPCODE,
    };

    // this object only for billing address
    const productWithOutBillingInfo = {
      PDID: orderSummaryData?.product_id,
      PERIODID: getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.period_id ? 2 : 1,
      BENEFITID: orderSummaryData?.benefit_id,
      SHIPPINGADDRESS: "",
      SHIPPINGADDRESS1: "",
      SHIPPINGCITY: "",
      SHIPPINGSTATE: "",
      SHIPPINGZIPCODE: "",
    };

    // assign state value
    setCheckoutFormData((preData) => ({
      ...preData,
      ADDRESS1,
      ADDRESS2,
      STATE,
      CITY,
      PAYMENT: { ...preData?.PAYMENT, ...submitData },
      // BENEFICIARY:
      //   levelValue === MEMBER_SPOUSE || levelValue === WHOLE_FAMILY
      //     ? { ...preData?.BENEFICIARY, ADDRESS: ADDRESS1 }
      //     : "",
      DEPENDENTS: preData.DEPENDENTS?.map((item) => ({
        ...item,
        ADDRESS: ADDRESS1,
      })),
      PRODUCTS: [is_billing_shipping_same ? productWithBillingInfo : productWithOutBillingInfo],
    }));

    toggle(is_billing_shipping_same ? "paymentTab" : "shippingInformationTab");
  };

  console.log("final Form Submit", checkoutFormData);
  // -------------------------Shipping Information Form Submit---------------------
  const shippingInformationFormSubmit = (submitData) => {
    setCheckoutFormData((preData) => ({
      ...preData,
      PRODUCTS: [
        {
          PDID: orderSummaryData?.product_id,
          PERIODID: getCheckoutData()?.selectProductDetails?.clubProductCheckoutItem?.period_id
            ? 2
            : 1,
          BENEFITID: orderSummaryData?.benefit_id,
          ...submitData,
        },
      ],
    }));
    toggle("paymentTab");
  };

  // -------------------------Payment Information Form Submit---------------------
  const paymentInformationFormSubmit = (submitData) => {
    setIsLoading(true);
    const spritName = submitData?.nameOnCard?.split(" ");
    const FIRSTNAME = spritName[0];
    const LASTNAME = spritName.length === 2 ? spritName[1] : spritName[2];

    setCheckoutFormData((preData) => ({
      ...preData,
      PAYMENT: {
        ...preData?.PAYMENT,
        ...submitData,
        FIRSTNAME,
        LASTNAME,
      },
      PAYMENTPROCESS: "Y",
    }));
    // set context value
    setMemberPerchesDetails((pre) => ({
      ...pre,
      ...getCheckoutData(),
      ...checkoutFormData,
      PAYMENT: submitData,
    }));

    axiosInstance
      .post(apis.checkout.memberCreate, {
        ...checkoutFormData,
        ZIPCODE: getCheckoutData()?.zipCode ? getCheckoutData()?.zipCode : billingZipCode,
        enc_key: btoa(`${getApiDetails()?.username}:${getApiDetails()?.password}`),
        PAYMENT: { ...checkoutFormData?.PAYMENT, ...submitData, FIRSTNAME, LASTNAME },
        PAYMENTPROCESS: "Y",
      })
      .then((resp) => {
        if (resp?.SUCCESS) {
          const orderDetailsResp = {
            agent_id: checkoutFormData?.AGENT,
            order_details: resp,
            member_details: {
              ...checkoutFormData,
              ZIPCODE: getCheckoutData()?.zipCode ? getCheckoutData()?.zipCode : billingZipCode,
              PAYMENT: { ...checkoutFormData?.PAYMENT, ...submitData, FIRSTNAME, LASTNAME },
              PAYMENTPROCESS: "Y",
            },
          };
          setOrderDetails(orderDetailsResp);
          setMemberPerchesInfo(resp);
          setCheckoutData({ ...getCheckoutData(), memberPerchesInfo: resp });
          setMemberPerchesDetails((pre) => ({ ...pre, memberPerchesInfo: resp }));

          if (isClub()) {
            setCheckoutData({
              ...getCheckoutData(),
              memberInfo: checkoutFormData,
              orderSummaryData,
            });
            orderDetailsStore(orderDetailsResp);
          } else {
            toggle("authorizationTab");
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };
  // -------------------------Final Information Form Submit---------------------
  const finalInformationFormSubmit = () => {
    // if (isCheckedPolicy) {
    getSignatureStatus();
    // }
  };

  return (
    <div className="onboarding-checkout-content">
      <AnimateContent>
        <Row className="m-0">
          <Col sm="12">
            <Row>
              <Col sm="12" className="px-0 mb-4">
                <div className="text-info-green secure-check-title">
                  <h1>Secure Checkout</h1>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="7" lg="7" xl="8" className="onboarding-checkout-left-col">
            <Row className="onboarding-checkout-tab-row">
              <Col sm="12" className="onboarding-checkout-tab-sm-12">
                <div>
                  <Accordion
                    flush
                    open={open}
                    toggle={toggle}
                    className="onboarding-checkout-accordion-content"
                  >
                    <AccordionItem className="onboarding-checkout-accordion-item">
                      <AccordionHeader targetId="memberInformationTab">
                        <span>Member Information</span>
                      </AccordionHeader>
                      <AccordionBody accordionId="memberInformationTab">
                        <MemberInformationForm handelSubmit={memberInformationFormSubmit} />
                      </AccordionBody>
                    </AccordionItem>
                    {isClub()
                      ? null
                      : levelValue &&
                        levelValue !== MEMBER_ONLY && (
                          <AccordionItem className="onboarding-checkout-accordion-item">
                            <AccordionHeader targetId="dependentInformationTab">
                              <span>Dependent Information</span>
                            </AccordionHeader>
                            <AccordionBody accordionId="dependentInformationTab">
                              <DependentInformationForm
                                handelSubmit={dependentInformationFormSubmit}
                              />
                            </AccordionBody>
                          </AccordionItem>
                        )}

                    <AccordionItem className="onboarding-checkout-accordion-item">
                      <AccordionHeader targetId="billingInformationTab">
                        <span>Mailing / Billing Address</span>
                      </AccordionHeader>
                      <AccordionBody accordionId="billingInformationTab">
                        <BillingInformationForm
                          billingZipCode={billingZipCode}
                          setBillingZipCode={setBillingZipCode}
                          handelSubmit={billingInformationFormSubmit}
                        />
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem className="onboarding-checkout-accordion-item">
                      <AccordionHeader targetId="shippingInformationTab">
                        <span>Shipping Address</span>
                      </AccordionHeader>
                      <AccordionBody accordionId="shippingInformationTab">
                        <ShippingInformationForm
                          checkoutFormData={checkoutFormData}
                          handelSubmit={shippingInformationFormSubmit}
                        />
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem className="onboarding-checkout-accordion-item">
                      <AccordionHeader targetId="paymentTab">
                        <span>Payment Details</span>
                      </AccordionHeader>
                      <AccordionBody accordionId="paymentTab">
                        <PaymentForm
                          isLoading={isLoading}
                          checkoutFormData={checkoutFormData}
                          handelSubmit={paymentInformationFormSubmit}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    {isClub() ? null : (
                      <AccordionItem className="onboarding-checkout-accordion-item">
                        <AccordionHeader targetId="authorizationTab">
                          <span>Authorization & Verification</span>
                        </AccordionHeader>
                        <AccordionBody accordionId="authorizationTab">
                          <AuthorizationForm
                            policyDetails={policyDetails}
                            signatureUUID={signatureUUID}
                            isCheckedPolicy={isCheckedPolicy}
                            isSignatureLoading={isSignatureLoading}
                            setIsCheckedPolicy={setIsCheckedPolicy}
                            handelSubmit={finalInformationFormSubmit}
                          />
                        </AccordionBody>
                      </AccordionItem>
                    )}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="5" lg="5" xl="4" className="onboarding-checkout-right-col">
            <PositionAnimateContent x={1000} animateDelay={0.4}>
              <OrderSummaryCard
                productQty={productQty}
                setProductQty={setProductQty}
                orderSummaryData={orderSummaryData}
                memberPerchesInfo={memberPerchesInfo}
              />
            </PositionAnimateContent>
          </Col>
        </Row>
      </AnimateContent>
    </div>
  );
};
export default Step4;
