// import { lazy } from "react";
// import { Navigate, useParams } from "react-router-dom";

// ==================================Normal Import===============================
// -------------------------------Public Page Import-----------------------------
// Checkout Page
import Step1 from "../pages/Checkout/Step1";
import Step2 from "../pages/Checkout/Step2";
import Step3 from "../pages/Checkout/Step3";
import Step4 from "../pages/Checkout/Step4";
// Products
import ProductDetails from "../pages/ProductDetails";
// Thank You Page
import ThankYouPage from "../pages/ThankYouPage";
import Home from "../pages/Home";

// const RedirectOnBoarding1 = () => {
//   const { agentId } = useParams();
//   return <Navigate to={`/${agentId}/onboarding`} />;
// };

// ------------------------------------------------------------------------------
// ==============================================================================

// ===================================Lazy Import================================
// -------------------------------Public Page Import-----------------------------
// Checkout Page
// const Step3 = lazy(() => import("../pages/Checkout/Step3"));
// ------------------------------------------------------------------------------
// ==============================================================================

// ------------------------------Auth Protected Routes---------------------------
const authProtectedRoutes = [
  // Dashboard
  // { path: "/dashboard", element: Dashboard },
  // { path: "/", exact: true, element: () => <Navigate to="/dashboard" /> }
];
// ------------------------------------------------------------------------------

// ----------------------------------Public Routes-------------------------------
const publicRoutes = [
  // Checkout Page
  { path: "/onboarding", element: Step1 },
  { path: "/zipcode", element: Step2 },
  { path: "/pricing", element: Step3 },
  { path: "/checkout-ocp", element: Step4 },
  { path: "/", exact: true, element: Home },
  // Checkout Page with agent ID
  { path: "/:agentId/onboarding", element: Step1 },
  { path: "/:agentId/zipcode", element: Step2 },
  { path: "/:agentId/pricing", element: Step3 },
  { path: "/:agentId/checkout-ocp", element: Step4 },
  { path: "/:agentId", exact: true, element: Home },
  // Products
  { path: "/product-details", element: ProductDetails },
  { path: "/:agentId/product-details", element: ProductDetails },
  // Thank You Page
  { path: "/thank-you", element: ThankYouPage },
  { path: "/:agentId/thank-you", element: ThankYouPage },
];
// ------------------------------------------------------------------------------
export { authProtectedRoutes, publicRoutes };
