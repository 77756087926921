import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import BlurAnimateContent from "../../components/Animation/BlurAnimateContent";
import WrapContent from "../../components/WrapContent";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <WrapContent>
      <BlurAnimateContent>
        <Row className="error-row er-404">
          <Col xl="12" lg="12" md="12" sm="12" className="text-center text-black">
            <h1>404</h1>
            <h2 className="line-height-sm fw-bold mb-3">Opps! Page Not Found</h2>

            <h3 className="line-height-sm font-weight-light mb-3 text-black-50">
              The page you were looking for doesn't exist.
            </h3>
            {/* <p>You may have mistyped the address or the page may have moved. Try searching below.</p> */}

            <Button
              color="primary"
              size="lg"
              onClick={() => navigate("/")}
              className="text-white checkout-next-pre-button mt-4"
            >
              <BsArrowLeft size={20} className="mx-1" />
              <span className="mx-1">Back to Home</span>
            </Button>
          </Col>
        </Row>
      </BlurAnimateContent>
    </WrapContent>
  );
};
export default Error404;
