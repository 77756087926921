import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import InputField from "../../../../components/FormControl/InputField";
import PricingCards from "../../../../assets/image/pricing/PricingCards.svg";
import * as yup from "yup";
import SelectField from "../../../../components/FormControl/SelectField";
import { useState } from "react";
import { PAYMENT_TYPE } from "../../../../constant";

const PaymentForm = ({ isLoading, checkoutFormData, handelSubmit }) => {
  const firstName = checkoutFormData?.FIRSTNAME ? `${checkoutFormData?.FIRSTNAME}` : "";
  const middleName = checkoutFormData?.MIDDLENAME ? ` ${checkoutFormData?.MIDDLENAME}` : "";
  const lastName = checkoutFormData?.LASTNAME ? ` ${checkoutFormData?.LASTNAME}` : "";
  const [selectPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD);
  // ------------------------Initial Values--------------------------
  const initialValues =
    selectPaymentType === PAYMENT_TYPE.CREDIT_CARD
      ? {
          CCNUMBER: "",
          CCEXPMONTH: "",
          CCEXPYEAR: "",
          CCSECURITYCODE: "",
          PAYMENTTYPE: selectPaymentType,
          nameOnCard: `${firstName}${middleName}${lastName}`,
        }
      : selectPaymentType === PAYMENT_TYPE.ONLINE_CHECK
      ? {
          ACHROUTING: "",
          ACHACCOUNT: "",
          PAYMENTTYPE: selectPaymentType,
        }
      : { PAYMENTTYPE: selectPaymentType };

  // ------------------------Validation Schema---------------------------
  const validationSchema = yup.object().shape(
    selectPaymentType === PAYMENT_TYPE.CREDIT_CARD
      ? {
          CCNUMBER: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(16, "Must be exactly 16 digits")
            .max(16, "Must be exactly 16 digits")
            .required("This is required field"),
          CCSECURITYCODE: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(3, "Must be exactly 3 digits")
            .max(3, "Must be exactly 3 digits"),
          CCEXPMONTH: yup.string().strict(true).trim().required("This is required field"),
          CCEXPYEAR: yup.string().strict(true).trim().required("This is required field"),
          PAYMENTTYPE: yup.string().strict(true).trim().required("This is required field"),
        }
      : selectPaymentType === PAYMENT_TYPE.ONLINE_CHECK
      ? {
          ACHROUTING: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(9, "Must be exactly 9 digits")
            .max(9, "Must be exactly 9 digits")
            .required("This is required field"),
          ACHACCOUNT: yup.string().strict(true).trim().required("This is required field"),
        }
      : { PAYMENTTYPE: yup.string().strict(true).trim().required("This is required field") }
  );

  // ---------------------Static Payment Type Option-------------------------
  // const paymentTypeOption = [
  //   { title: "Credit Card", value: PAYMENT_TYPE.CREDIT_CARD },
  //   { title: "On-line Check", value: PAYMENT_TYPE.ONLINE_CHECK },
  // ];
  // --------------------------Static Month List------------------------------
  const getMonthList = [
    { title: "January (01)", value: "01" },
    { title: "February (02)", value: "02" },
    { title: "March (03)", value: "03" },
    { title: "April (04)", value: "04" },
    { title: "May (05)", value: "05" },
    { title: "June (06)", value: "06" },
    { title: "July (07)", value: "07" },
    { title: "August (08)", value: "08" },
    { title: "September (09)", value: "09" },
    { title: "October (10)", value: "10" },
    { title: "November (11)", value: "11" },
    { title: "December (12)", value: "12" },
  ];
  // --------------------------Static Year List------------------------------
  const getYearList = () => {
    let yearList = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 15; i++) {
      yearList.push({ title: i, value: i.toString().slice(2) });
    }
    return yearList;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <Row>
              <Col lg="12" className="px-1 mb-4">
                <img src={PricingCards} alt="Pricing Cards" className="pricing-cards-img" />
              </Col>
              {/* <Col lg={12}>
                <FormGroup>
                  <div>
                    <SelectField
                      label="Payment Type"
                      name="PAYMENTTYPE"
                      value={selectPaymentType}
                      onChange={(e) => {
                        setSelectPaymentType(e.target.value);
                        setFieldValue("PAYMENTTYPE", e.target.value);
                      }}
                      options={paymentTypeOption}
                      aria-label="Payment Type"
                    />
                  </div>
                </FormGroup>
              </Col> */}
              {selectPaymentType === PAYMENT_TYPE.CREDIT_CARD ? (
                <>
                  <Col lg={12}>
                    <FormGroup>
                      <InputField
                        label="Credit Card Number"
                        type="text"
                        maxLength="16"
                        name="CCNUMBER"
                        id="CCNUMBER"
                        placeholder="16-digit Card Number"
                        aria-label="16-digit Card Number"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>Expiration Date</Label>
                      <div className="d-flex">
                        <div>
                          <SelectField
                            name="CCEXPMONTH"
                            defaultoption="Month"
                            options={getMonthList}
                            aria-label="Month"
                          />
                        </div>
                        <div>
                          <SelectField
                            name="CCEXPYEAR"
                            defaultoption="Year"
                            options={getYearList()}
                            aria-label="Year"
                            className="mx-2"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <InputField
                        label="CVV2"
                        type="text"
                        maxLength="3"
                        name="CCSECURITYCODE"
                        id="CCSECURITYCODE"
                        placeholder="CVV2"
                        aria-label="CVV2"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup>
                      <InputField
                        label="Name on card"
                        type="text"
                        name="nameOnCard"
                        id="nameOnCard"
                        placeholder="Name on card"
                        aria-label="Member's name on card"
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : selectPaymentType === PAYMENT_TYPE.ONLINE_CHECK ? (
                <>
                  <Col lg={6}>
                    <FormGroup>
                      <InputField
                        label="ACH Routing Number"
                        type="text"
                        maxLength="9"
                        name="ACHROUTING"
                        id="ACHROUTING"
                        placeholder="9 digit routing number"
                        aria-label="9 digit routing number"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <InputField
                        label="ACH Account Number"
                        type="text"
                        name="ACHACCOUNT"
                        id="ACHACCOUNT"
                        placeholder="ACH account number"
                        aria-label="ACH account number"
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : null}

              <Col lg={12} className="text-end mb-0">
                <div className="mt-3">
                  <Button
                    color="info"
                    type="submit"
                    className="px-4 py-2 bg-info-green text-white"
                    title="Continue"
                  >
                    {isLoading ? (
                      <>
                        <Spinner size="sm" className="me-1">
                          Loading...
                        </Spinner>
                        Loading...
                      </>
                    ) : (
                      "PROCESS PAYMENT & CONTINUE"
                    )}
                  </Button>{" "}
                  <div className="mt-1 transaction-msg">
                    <small>All transactions are secure and encrypted.</small>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default PaymentForm;
