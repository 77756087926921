import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  let location = useLocation();
  // const [defaultPageHeight, setDefaultPageHeight] = useState(true);
  // const isApplePlatform =
  //   window?.navigator?.platform?.includes?.("Mac") ||
  //   window?.navigator?.platform?.includes?.("") ||
  //   window?.navigator?.platform?.includes?.();
  // const isFirefoxBrowser = window?.navigator?.userAgent?.includes?.("Firefox");

  useEffect(() => {
    // setDefaultPageHeight(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // setTimeout(() => {
    //   setDefaultPageHeight(false);
    //   console.log("test")
    // }, 200);
  }, [location]);

  return children || null;
};

export default ScrollToTop;
